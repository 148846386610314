import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import 'rc-slider/assets/index.css';
import formatAmount from "indian-currency-formatter";
import { Card } from "bootstrap-4-react/lib/components";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import React from 'react'
import { useParams } from "react-router-dom";

const EditHealthAddOns = ({ apiResponse, setEditAddOnsFlag, checkedAddon, setCheckedAddon, uncheckedAddon, setUncheckedAddon, setFormData, form_data, masterAddon }) => {

  console.log('checkedAddon', checkedAddon);
  const [old, setOld] = useState(form_data);

  const { paramsProductName } = useParams();

  const { handleSubmit } = useForm();

  const onSubmitData = () => {
    setOld({ ...old, customArr: 'anoj' });
    setFormData({ ...old, 'uncheckedAddon': uncheckedAddon });
    setEditAddOnsFlag(false); 
  }


  // eslint-disable-next-line
  const [maniplacignaAddonMaster, setManipalcignaAddonMaster] = useState(paramsProductName === 'ManipalCigna ProHealth Prime Protect' ? [
    { Addon: 'PC00001', ProductElementCode: 'ADDONDETAILS', CoverName: 'ManipalCigna Critical Illness Add On Cover\n(Critical Illness Benefit Rider)' },
    { Addon: 'PC0001491', ProductElementCode: 'ADDONDETAILS', CoverName: 'Infertility Treatment \n(Option available with Enhance / Enhance Plus - Optional Packages, Applicable if base SI>=7.5lacs)' }
  ] : paramsProductName === 'ManipalCigna ProHealth Prime Advantage' ? [
    { Addon: 'C0001491', ProductElementCode: 'ADDONDETAILS', CoverName: 'Infertility Treatment \n(Option available with Enhance / Enhance Plus - Optional Packages, Applicable if base SI>=7.5lacs)' },
    { Addon: 'C00001', ProductElementCode: 'ADDONDETAILS', CoverName: 'ManipalCigna Critical Illness Add On Cover\n(Critical Illness Benefit Rider)', }
  ] : [])

  // eslint-disable-next-line
  const [careAddonMaster, setCareAddonMaster] = useState([
    { Addon: 'C0001772', ProductElementCode: 'ADDONDETAILS', CoverName: 'NCB Super', prodname: 'caresupreme', IsMandatory: (form_data.self_age > 45 && form_data.self_age <= 75 ? 'N' : 'Y') },
    { Addon: 'C0001774', ProductElementCode: 'ADDONDETAILS', CoverName: 'Annual Health check - up' },
    { Addon: 'C0001775', ProductElementCode: 'ADDONDETAILS', CoverName: 'Instant Cover' },
    
    { Addon: 'C0001776', ProductElementCode: 'ADDONDETAILS', CoverName: 'Claim Shield' },
    { Addon: 'C0001777', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -1yr'  },
    
    { Addon: 'C0001778', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -2yr', prodname: 'caresupreme' },
    { Addon: 'C0001779', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -3yr', prodname: 'caresupreme' }, 


    { Addon: 'C0001408', ProductElementCode: 'ADDONDETAILS', CoverName: 'Air Ambulance Cover', IsMandatory: 'Y' },
    { Addon: 'C0001499', ProductElementCode: 'ADDONDETAILS', CoverName: 'Wellness Program', IsMandatory: 'Y' },
    { Addon: 'C0001523', ProductElementCode: 'ADDONDETAILS', CoverName: 'Outpatient Treatment' }
  ]);
  // console.log("careAddonMaster", careAddonMaster);

  const [healthAddons, setHealthAddons] = useState();

  useEffect(() => {

    const array1Filtered = apiResponse.PolicyLobList[0].RiderDetailsList.filter(item => item.BenefitCode !== "");
    if (paramsProductName === 'ManipalCigna ProHealth Prime Protect' || paramsProductName === 'ManipalCigna ProHealth Prime Advantage' || paramsProductName === 'ManipalCigna ProHealth Prime Active') {

      const filteredData = maniplacignaAddonMaster.map(item2 => {
        const match = array1Filtered.find(item1 => item1.BenefitCode === item2.Addon);
        if (match) {
          return {
            ...match,
            ...item2
          };
        }
        return item2;
      });
      setHealthAddons(filteredData);

    } else {

      const filteredData = careAddonMaster.map(item2 => {
        const match = array1Filtered.find(item1 => item1.BenefitCode === item2.Addon);
        if (match) {
          return {
            ...match,
            ...item2
          };
        }
        return item2;
      });

      console.log('filteredData', filteredData);

      setHealthAddons(filteredData);

    }


    // eslint-disable-next-line
  }, [apiResponse, careAddonMaster, maniplacignaAddonMaster])

  // eslint-disable-next-line
  const [rider_val, set_rider_val] = useState({});

  /*const SliderOnChnage = (e, code) => {
    set_rider_val({ ...rider_val, [code]: e });
  }*/
  
 /* 
// eslint-disable-next-line
  const checkAddonChange = (e) => {
    const addonId = e.target.value;

    if (checkedAddon.includes(addonId)) {
      // If the addon is already checked, uncheck it
      setCheckedAddon(checkedAddon.filter(item => item !== addonId));
      setUncheckedAddon([...uncheckedAddon, addonId]);

      // Find the addon to be removed from checkedAddon
      const addonToRemove = careAddonMaster.find(item => item.Addon === addonId);
      if (addonToRemove) {
        const addonToRemoveId = addonToRemove.AddonToRemoveId; // Assuming there's a property to identify the addon to remove
        if (addonToRemoveId && checkedAddon.includes(addonToRemoveId)) {
          setCheckedAddon(checkedAddon.filter(item => item !== addonToRemoveId));
          setUncheckedAddon([...uncheckedAddon, addonToRemoveId]);
        }
      }
    } else {
      // If the addon is not checked, check it
      setUncheckedAddon(uncheckedAddon.filter(item => item !== addonId));
      setCheckedAddon([...checkedAddon, addonId]);

      // Find the addon to be removed from checkedAddon
      const addonToAdd = careAddonMaster.find(item => item.Addon === addonId);
      if (addonToAdd) {
        const addonToAddId = addonToAdd.AddonToAddId; // Assuming there's a property to identify the addon to add
        if (addonToAddId && uncheckedAddon.includes(addonToAddId)) {
          setUncheckedAddon(uncheckedAddon.filter(item => item !== addonToAddId));
          setCheckedAddon([...checkedAddon, addonToAddId]);
        }
      }
    }
  } */

  // Assuming you have a mapping of related addons
  const relatedAddonsMap = {
 
    'C0001777': ['C0001775', 'C0001778', 'C0001779'], // Example: Addon C0001777 is related to Addon C0001775
    'C0001775': ['C0001777', 'C0001779', 'C0001778'], // Example: Addon C0001775 is related to Addon C0001777

    'C0001779': ['C0001775', 'C0001777', 'C0001778'],
    'C0001778': ['C0001775', 'C0001777', 'C0001779'],
    

    // Add more mappings as needed
  };

  const getRelatedAddons = (addonId) => {
    // Return related addons from the map or an empty array if not found
    return relatedAddonsMap[addonId] || [];
  };

  /*useEffect(() => {

    console.log('healthAddons', healthAddons);
    console.log('checkedAddon : ', checkedAddon);
    console.log('uncheckedAddon : ', uncheckedAddon);

  }, [healthAddons, checkedAddon, uncheckedAddon]);*/


  console.log(' line no 165 checkedAddon : ',checkedAddon,' | uncheckedAddon : ', uncheckedAddon);

  return (
    <div className="rightSide_menu_outer">
      <div className="rightSide_menu">
        <div className="rightSide_menu_header">
          <div>
            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => setEditAddOnsFlag(false)}> Back </button>
          </div>
          <div className="font14 fw500 color182">Update Add Ons Details</div>
          <div>&nbsp;</div>
        </div>
        <form onSubmit={handleSubmit(onSubmitData)}>
          <div className="pl20 pr20">
            {
              healthAddons &&
              healthAddons.map((Rider, index) => {


                return <>

                  {

                    Rider.ProductElementCode && Rider.ProductElementCode !== '' ?

                      (<>
                        <div className="mt20">
                          <div className="targetbar_title">
                            {Rider.Addon && Rider.Addon !== '' ? (
                              <>
                                <label className="font14 fw500 color182">
                                  <input
                                    type="checkbox"
                                    name={"rider_" + Rider.Addon}
                                    onChange={e => {
                                      const addonId = e.target.value;
                                      if (e.target.checked) {
                                        // If the checkbox is checked, remove related addons if they are checked
                                        const relatedAddons = getRelatedAddons(addonId); // Assuming you have a function to get related addons
                                        setCheckedAddon(prev => [...prev.filter(item => !relatedAddons.includes(item)), addonId]);
                                      } else {
                                        setCheckedAddon(prev => prev.filter(item => item !== addonId));
                                      }
                                    }}
                                    checked={checkedAddon.includes(Rider.Addon) ? true : false}
                                    value={Rider.Addon}
                                    readOnly={Rider.IsMandatory === "Y"}
                                    disabled={Rider.IsMandatory === "Y"}
                                  /> {Rider.Addon} - {Rider.CoverName}
                                </label>
                              </>
                            ) : (
                              <>
                                <label className="font14 fw500 color182">
                                  <input
                                    type="checkbox"
                                    name={"rider_" + Rider.Addon}
                                    onChange={e => {
                                      const addonId = e.target.value;
                                      if (e.target.checked) {
                                        // If the checkbox is checked, remove related addons if they are checked
                                        const relatedAddons = getRelatedAddons(addonId); // Assuming you have a function to get related addons
                                        setCheckedAddon(prev => [...prev.filter(item => !relatedAddons.includes(item)), addonId]);
                                      } else {
                                        setCheckedAddon(prev => prev.filter(item => item !== addonId));
                                      }
                                    }}
                                    checked={checkedAddon.includes(Rider.Addon) ? true : false}
                                    value={Rider.Addon}
                                    readOnly={Rider.IsMandatory === "Y"}
                                    disabled={Rider.IsMandatory === "Y"}
                                  /> {Rider.Addon} - {Rider.BenefitDescription}
                                </label>
                              </>
                            )}
                          </div>
                          <div className="flex_center justify_center mt8">
                          </div>
                        </div>

                        <AddOnsCard1 cover_term={1} premium={Rider.GrossPremium ? Rider.GrossPremium : (Math.round(Rider.CarrierBasePremium * 1.18))} />
                        {
                          ['C0001777', 'C0001778'].includes(Rider.Addon) && <h3 className="heading-3" style={{'text-align':'center','margin-top': '5px'}}>OR</h3>
                        }
                         {
                          ! ['C0001777', 'C0001778'].includes(Rider.Addon) &&  <hr className="border-hr" />
                        }
                       

                      </>) : <></>
                  }

                </>
              })
            }

          </div>
          <div className="saveBtn backsaveBtn">
            <button type="button" className="btn btn-primary" onClick={() => setEditAddOnsFlag(false)}> Close </button>
            <button type="submit" className="btn btn-primary"> Save </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHealthAddOns;

const AddOnsCard1 = ({ cover_term, premium }) => {

  return (
    <div className="mt10 ">
      <Card className="pt10 pb10 pl20 pr20 card-bg-gray">
        <Row>
          <Col md={6}>
            <span className="text-type-3_1">Cover Term</span><br />
            <span className="font14 fw500 color182">{cover_term} Years</span>
          </Col>
          <Col md={6} className="text-right">
            <span className="text-type-3_1">Premium</span><br />
            <span className="font14 fw500 color182 ">₹ {premium ? formatAmount(premium) : '0'}/Year</span>
          </Col>
        </Row>
      </Card>
    </div>
  )
}