import { React, Fragment, useState } from "react";  


export default function Nominee(props) {
    const register = props.register;
    const errors = props.errors; 
    //eslint-disable-next-line
    const setError = props.setError;
    
    const [nominee_address_same,  setNomineeAddressSame] = useState(false);
  
    return (
        <Fragment> 
                <div className="form-row mb10"> 
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Name of Nominee<span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="insured_full_name" name="insured_full_name" placeholder="Enter Name of Nominee"  
                        {...register("insured_full_name", { required: 'Name of Nominee field is required'} )}    />
                        <small className="form-text font11 text-danger">{errors.insured_full_name && errors.insured_full_name.message}</small>
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10">Relationship with Insurer <span className="colorfa8">*</span></div>
                        <select className="form-control" name="insured_relationship" id="insured_relationship"
                         {...register("insured_relationship", { required: 'Relationship field is required'} )} >
                            <option value="">-- Select --</option>
                            <option value="Wife">Wife</option>
                            <option value="Husband">Husband</option>  
                        </select>
                        <small className="form-text font11 text-danger">{errors.insured_relationship && errors.insured_relationship.message}</small>
                    </div>
                </div> 
                 
                <div className="form_prnt mb10">
                    <div className="custome_form">
                        <div className="form-row">
                        <div className="col-md-12 pr20">
                            <div className="font14 color212 fw600">Nominee has different residential address from that of Proposer<span className="colorfa8">*</span></div>
                            <ul className="steps_tag font14 fw500 color182 mt15">   
                                <li><label><input type="radio" onClick={()=>{setNomineeAddressSame(true) }} name="nominee_address_same" value={true} {...register("nominee_address_same", { required: 'This field is required'   } )}/><span className="rdo_text_box">Yes</span></label></li>    
                                <li><label><input type="radio" onClick={()=>{setNomineeAddressSame(false) }}  name="nominee_address_same" value={false} {...register("nominee_address_same", { required: 'This field is required'   } )}/><span className="rdo_text_box">No</span></label></li>  
                            </ul> 
                            <small className="form-text font11 text-danger">{errors.nominee_address_same && errors.nominee_address_same.message}</small>
                            </div>
                        </div> 
                    </div> 
                </div>
            { !nominee_address_same &&  // in case address is not save then this section get shown
                <div className="nominee_address_section">
                    <div className="form-row mb10"> 
                        <div className="form-group col-md-6 pr20">                                
                            <div className="font14 color212 fw600 mb10 mr30">Address Line 1<span className="colorfa8">*</span></div>
                            <input type="text" className="form-control" id="address" name="address" placeholder="Enter Your Full Name"  
                            {...register("address", { required: 'Address Line 1 field is required'} )}    />
                            <small className="form-text font11 text-danger">{errors.address && errors.address.message}</small>
                        </div>
                        <div className="form-group col-md-6 pl20">                                
                            <div className="font14 color212 fw600 mb10">Country<span className="colorfa8">*</span></div>
                            <select className="form-control" name="country" id="country"
                            {...register("country", { required: 'Country field is required'} )} >
                                <option value="">-- Select --</option>
                                <option value="india">India</option>
                                {/* {
                                    countries.map((item, key) => { 
                                        return (<option  value={item.name}>{item.name}</option>)
                                    })
                                } */}
                            </select>
                            <small className="form-text font11 text-danger">{errors.country && errors.country.message}</small>
                        </div>
                    </div> 
                    <div className="form-row mb10"> 
                        <div className="form-group col-md-6 pr20">                                
                            <div className="font14 color212 fw600 mb10 mr30">State<span className="colorfa8">*</span></div>
                            <select className="form-control" name="state" id="state"
                            {...register("state", { required: 'State field is required'} )} >
                                <option value="">-- Select --</option>
                                <option value="Maharashtra">Maharashtra</option> 
                                <option value="Delhi">Delhi</option> 
                            </select>
                            <small className="form-text font11 text-danger">{errors.address && errors.address.message}</small>
                        </div>
                        <div className="form-group col-md-6 pl20">                                
                            <div className="font14 color212 fw600 mb10">City<span className="colorfa8">*</span></div>
                            <select className="form-control" name="city" id="city" 
                                {...register("city", { required: 'City field is required'} )} >
                                <option value="">-- Select --</option>
                                <option value="Mumbai">Mumbai</option> 
                                <option value="Pune">Pune</option> 
                                <option value="Delhi">Delhi</option> 
                                <option value="New Delhi">New Delhi</option> 
                            </select>
                            <small className="form-text font11 text-danger">{errors.pincode && errors.pincode.message}</small>
                        </div>
                    </div> 
                    <div className="form-row mb10"> 
                        <div className="form-group col-md-6 pr20">                                
                            <div className="font14 color212 fw600 mb10 mr30">PIN Code<span className="colorfa8">*</span></div>
                            <input type="text" className="form-control" id="pincode" name="pincode" placeholder="Enter PIN Code"  
                            {...register("pincode", { required: 'PIN Code field is required'} )}    />
                            <small className="form-text font11 text-danger">{errors.pincode && errors.pincode.message}</small>
                        </div> 
                    </div> 
                </div> 
            }
 
        </Fragment>
    )
}