import { React, Fragment, useState, useEffect } from "react";

import Title from "../Title";
import DashboardHeader from "../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./LifeInsuranceDetails.css";
import Global from "../Global";
import Assistant from "./components/Assistant";
import CardHealthPremiumDetails1 from "./health-insurance/CardHealthPremiumDetails1";
//import CardHealthPremiumDetails2 from "./health-insurance/CardHealthPremiumDetails2";
import http from "../http";
//import Swal from "sweetalert2";
import EditHealthCoverDetails from "./components/EditHealthCoverDetails";
import EditAddress from "./components/EditAddress";
// eslint-disable-next-line
import { calculateDOB, decodeSumInsured, getCarrierCodeByName, get_gender_short } from "../components/global";
import EditHealthAddOns from "./components/EditHealthAddOns";
import EditHealthAddOnsCigna from "./components/EditHealthAddOnsCigna";
import HealthInsuranceAddonScreen from "./health-insurance/HealthInsuranceAddonScreen";
import Loader from "../components/Loader";
import { CignaRiderAndAddonList } from "./data/CignaRiderAndAddonList";



import { Modal, ModalBody } from 'react-bootstrap';

export default function HealthByProduct() {

  const [loading, setLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("Health Plan: Quote");


  //eslint-disable-next-line
  const [warningshow, setWarningshow] = useState(false);
  const [warningMessage, setWarningMessage] = useState();
  //eslint-disable-next-line
  const [warningTitle, setWarningTitle] = useState("Health Plan Error");

  /*     setWarningshow(true);
      setWarningMessage('test'); */

  const getCurrentDate = (offsetDays = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + offsetDays);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle("Health Plan: Quote");
  }, []);

  const { paramsProductName } = useParams();

  // key is CarrierCode     
  const logo = (CarrierCode) => {

    switch (CarrierCode) {
      case 'CARE':
        return Global.base_url + '/images/logo/care-helth.png';
      case 'HDFC':
        return Global.base_url + '/images/logo/hdfc-life.png';
      case 'ICICI':
        return Global.base_url + '/images/logo/iprotect-smart.png';
      case 'CIGNA':
        return Global.base_url + '/images/logo/cigna.png';
      case 'TATAAIG':
        return Global.base_url + '/images/logo/TATAAIG.png';
      default:
        return '';
    }
  }

  // eslint-disable-next-line
  const [form_data, setFormData] = useState(localStorage.getItem('form_data') ? JSON.parse(localStorage.getItem('form_data'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});

  /***Edit Basic Info start */
  // eslint-disable-next-line
  //const [editBasicInfoFlag, setEditBasicInfoFlag] = useState(false);
  const [editPlanDetailsFlag, setEditPlanDetailsFlag] = useState(false);

  // eslint-disable-next-line
  const [editAddOnsFlag, setEditAddOnsFlag] = useState(false);
  const [editEddressFlag, setEditAddressFlag] = useState(false);

  /***Edit Basic Info end */
  // eslint-disable-next-line
  const [memberCount, setMemberCount] = useState(0);
  // eslint-disable-next-line
  const [selected_cover, setSelectCover] = useState(form_data.desired_cover_amount && form_data.desired_cover_amount);

  // eslint-disable-next-line
  const [uncheckedAddon, setUncheckedAddon] = useState([]);
  // eslint-disable-next-line 
  const [checkedAddon, setCheckedAddon] = useState(paramsProductName === 'Care Supreme' ? ['C0001772', 'C0001774', 'C0001775', 'C0001776', /* 'C0001777', */ 'C0001778', 'C0001779', 'C0001523'] : paramsProductName === 'ManipalCigna ProHealth Prime Protect' ? ['PC00001', 'PC0002020', 'PC0000609', 'PC0002023', 'PC0002021'] : paramsProductName === 'ManipalCigna ProHealth Prime Advantage' ? ['C0001491', 'C00001', 'C0002019', 'PC0002020', 'C0001523', 'C0000609', 'AC0002023', 'AC0002021'] : []);


  //debugger;
  // alert(paramsProductName +  " | " +JSON.stringify(checkedAddon));

  // eslint-disable-next-line 
  const [maniplacignaAddonMaster, setManipalcignaAddonMaster] = useState(paramsProductName === 'ManipalCigna ProHealth Prime Protect' ? [
    { Addon: 'PC00001', ProductElementCode: 'ADDONDETAILS', CoverName: 'ManipalCigna Critical Illness Add On Cover\n(Critical Illness Benefit Rider)' },
    { Addon: 'PC0001491', ProductElementCode: 'ADDONDETAILS', CoverName: 'Infertility Treatment \n(Option available with Enhance / Enhance Plus - Optional Packages, Applicable if base SI>=7.5lacs)' }
  ] : paramsProductName === 'ManipalCigna ProHealth Prime Advantage' ? [
    { Addon: 'C0001491', ProductElementCode: 'ADDONDETAILS', CoverName: 'Infertility Treatment \n(Option available with Enhance / Enhance Plus - Optional Packages, Applicable if base SI>=7.5lacs)' },
    { Addon: 'C00001', ProductElementCode: 'ADDONDETAILS', CoverName: 'ManipalCigna Critical Illness Add On Cover\n(Critical Illness Benefit Rider)', }
  ] : [])





  // eslint-disable-next-line
  const [careAddonMaster, setCareAddonMaster] = useState([
    /*{ Addon: 'C0001768', ProductElementCode: 'ADDONDETAILS', CoverName: 'Unlimited E-Consultation', prodname: 'caresupreme' },*/
    { Addon: 'C0001772', ProductElementCode: 'ADDONDETAILS', CoverName: 'NCB Super', prodname: 'caresupreme', IsMandatory: (form_data.self_age >= 45 && form_data.self_age <= 75 ? 'N' : 'Y') },
    { Addon: 'C0001774', ProductElementCode: 'ADDONDETAILS', CoverName: 'Annual Health check - up', prodname: 'caresupreme' },
    { Addon: 'C0001776', ProductElementCode: 'ADDONDETAILS', CoverName: 'Claim Shield', prodname: 'caresupreme' },
    { Addon: 'C0001775', ProductElementCode: 'ADDONDETAILS', CoverName: 'Instant Cover', prodname: 'caresupreme' },
    { Addon: 'C0001777', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -1yr', prodname: 'caresupreme' },
    { Addon: 'C0001778', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -2yr', prodname: 'caresupreme' },
    { Addon: 'C0001779', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -3yr', prodname: 'caresupreme' },
    { Addon: 'C0001408', ProductElementCode: 'ADDONDETAILS', CoverName: 'Air Ambulance Cover', IsMandatory: 'Y', prodname: 'caresupreme' },
    { Addon: 'C0001499', ProductElementCode: 'ADDONDETAILS', CoverName: 'Wellness Program', IsMandatory: 'Y', prodname: 'caresupreme' },
    //{ Addon: 'C0001722', ProductElementCode: 'ADDONDETAILS', CoverName: 'Cumulative Bonus', IsMandatory: 'Y', prodname: 'caresupreme' },
    { Addon: 'C0001523', ProductElementCode: 'ADDONDETAILS', CoverName: 'Outpatient Treatment', prodname: 'caresupreme' }

  ]);


  // eslint-disable-next-line
  const [addonDetailsList, setAddonDetailsList] = useState([]);
  useEffect(() => {
    let c = 0;
    c += (form_data.self === 'true' || form_data.self ? 1 : 0);
    c += (form_data.spouse === 'true' || form_data.spouse ? 1 : 0);
    c += (form_data.son_arr ? (form_data.son_arr).length : 0);
    c += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);
    setMemberCount(c);
    localStorage.setItem('form_data', JSON.stringify(form_data));
  }, [selected_cover, form_data])

  const [api_response, set_api_response] = useState();
  const [product_name, setProductName] = useState("");
  const [logo_url, setLogoUrl] = useState();

  // eslint-disable-next-line
  const [cignaPost, setCignaPost] = useState({ riders: [], addons: [] });


  // eslint-disable-next-line
  const [filteredCignaRiderAndAddons, setFilteredCignaRiderAndAddons] = useState(CignaRiderAndAddonList.filter(item => item.ProductName === paramsProductName));



  const fetchData = () => {
    setLoading(true);
    let ChildCount = (form_data.son_arr ? (form_data.son_arr).length : 0);
    // eslint-disable-next-line
    ChildCount += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);


    let maxAge = form_data.self_age;
    for (let key in age_group) {
      if (age_group[key] !== "" && !isNaN(age_group[key])) {
        let age = parseInt(age_group[key]);
        if (age > maxAge) {
          maxAge = age;
        }
      }
    }
    /*  console.log("form_data.self_age", form_data.self_age, form_data);
     console.log('form_data.self_age ', calculateDOB(form_data.self_age), 'self age', form_data.self_age); */

    const PolicyRiskList = [];

    const formData = form_data;

    // If self is insured, add to list
    if (formData.self) {
      PolicyRiskList.push({
        ProductElementCode: "R00001",
        DateOfBirth: calculateDOB(formData.self_age), // Assuming birth on Jan 1 for simplicity
        GenderCode: formData.gender.charAt(0).toUpperCase(),
        InsuredRelation: "1"  // Increment relationId for each entry
      });
    }

    // If spouse is insured, add to list
    if (formData.spouse) {
      PolicyRiskList.push({
        ProductElementCode: "R00001",
        DateOfBirth: calculateDOB(formData.spouse_age), // Assuming birth on Jan 1 for simplicity
        GenderCode: formData.gender === "male" ? "F" : "M", // Assuming opposite gender of self
        InsuredRelation: formData.gender === "male" ? "3" : "2",  // Increment relationId for each entry
      });
    }
    console.log('formData.son_arr', formData.son_arr);
    // Adding sons if son_arr is present and is an array
    if (formData.son_arr && Array.isArray(formData.son_arr)) {
      formData.son_arr.forEach((son, index) => {

        PolicyRiskList.push({
          ProductElementCode: "R00001",
          DateOfBirth: calculateDOB(son.age), // Assuming birth on Jan 1 for simplicity
          GenderCode: "M",
          InsuredRelation: "4"  // Increment relationId for each entry
        });

      });
    }

    if (formData.daughter_arr && Array.isArray(formData.daughter_arr)) {

      formData.daughter_arr.forEach((daughter, index) => {
        PolicyRiskList.push({
          ProductElementCode: "R00001",
          DateOfBirth: calculateDOB(daughter.age), // Assuming birth on Jan 1 for simplicity
          GenderCode: "F",
          InsuredRelation: "5"  // Increment relationId for each entry
        });
      });

    }

    const floater = PolicyRiskList.some(item => parseInt(item.InsuredRelation) > 1);

    const selfCnt = form_data.self ? 1 : 0;
    const spouseCnt = form_data.spouse ? 1 : 0;
    const daughtCnt = (form_data.daughter_arr ? (form_data.daughter_arr).length : 0)
    const sonCnt = (form_data.son_arr ? (form_data.son_arr).length : 0)

    // Determine adult count
    const adultCount = selfCnt + spouseCnt;
    // Determine child count
    const childCount = sonCnt + daughtCnt;
    // Define logic table
    const logicTable = [
      { FFCategoryId: 1, AdultCount: 1, ChildCount: 0 },
      { FFCategoryId: 2, AdultCount: 1, ChildCount: 1 },
      { FFCategoryId: 3, AdultCount: 1, ChildCount: 2 },
      { FFCategoryId: 4, AdultCount: 2, ChildCount: 0 },
      { FFCategoryId: 5, AdultCount: 2, ChildCount: 1 },
      { FFCategoryId: 6, AdultCount: 2, ChildCount: 2 }
    ];

    // Find matching FFCategoryId
    let matchingFFCategoryId = null;
    for (let i = 0; i < logicTable.length; i++) {
      const condition = logicTable[i];
      if (condition.AdultCount === adultCount && condition.ChildCount === childCount) {
        matchingFFCategoryId = condition.FFCategoryId;
        break;
      }
    }

    const post_data_new = {
      ProductVersion: "1.0",
      ProductTag: "1",
      HealthPolicyType: floater ? "2" : "1",
      LOBCode: "2",
      FFCategory: matchingFFCategoryId,
      SumInsured: decodeSumInsured(selected_cover), // "2", //form_data.desired_cover_amount.toString(), // "1000000",
      IsSelfCovered: selfCnt > 0 ? "Yes" : "No",
      EffectiveDate: getCurrentDate(),
      ExpiryDate: getCurrentDate(364),
      PolicyCustomerList: [
        {
          DateOfBirth: calculateDOB(form_data.self_age), // "1990-11-26",
          PostCode: form_data.pin ? (form_data.pin).toString() : "400093", //"411033",
          City: form_data.city ? form_data.city : "", // "PUNE",
          GenderCode: get_gender_short(form_data.gender) //"M"
        }
      ],
      PolicyLobList: [
        {
          ProductCode: "FINHEALTH",
          PolicyRiskList: PolicyRiskList
        }
      ]
    }

    if (checkedAddon.length > 0 && paramsProductName === 'Care Supreme') {

      const filteredProvidedCodes = checkedAddon.filter(code =>
        !careAddonMaster.some(item => item.Addon === code && item.IsMandatory === 'Y')
      );


      const modifiedData = filteredProvidedCodes.map(Addon => {
        return {
          Rider: Addon,
          ProductElementCode: "RIDERDETAILS"
        };
      });
      console.log("301 modifiedData", modifiedData, ' | checkedAddon : ', checkedAddon, 'careAddonMaster', careAddonMaster);
      if (modifiedData.length > 0) {
        post_data_new.PolicyLobList[0].RiderDetailsList = modifiedData;
      }


    } else if (checkedAddon.length > 0 && (paramsProductName === 'ManipalCigna ProHealth Prime Advantage' || paramsProductName === 'ManipalCigna ProHealth Prime Protect')) {

      /*   console.log("checkedAddon ==>", checkedAddon);
  
        const filteredProvidedCodes = checkedAddon.filter(code =>
          !maniplacignaAddonMaster.some(item => item.Addon === code && item.IsMandatory === 'Y')
        ); 
  
        const modifiedData = filteredProvidedCodes.map(Addon => {
          return {
            Rider: Addon,
            ProductElementCode: "RIDERDETAILS"
          };
        });
  
        if (modifiedData.length > 0) { 
          //post_data_new.PolicyLobList[0].RiderDetailsList = modifiedData; // old  
        } */
    } else {
      // nothing 
    }


    // console.log("LINE 329 -  checkedAddon ==>", checkedAddon);

    // new code 
    if (paramsProductName === 'ManipalCigna ProHealth Prime Active') {

      if (checkedAddon.length > 0) { // after edit addons & rider 

        // remove dependet rider in case self & male
        if (matchingFFCategoryId === 1 && form_data.gender === 'male') {
          const RemoveAddons_Rider = [];
          let list = checkedAddon.filter(item => !RemoveAddons_Rider.includes(item))
          setCheckedAddon(list);
          /*    post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item =>  list.includes(item.Rider) ); */
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => list.includes(item.Addon));

        } else {

          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => checkedAddon.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => checkedAddon.includes(item.Addon));
        }


      } else { // default sending rider list
        post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList;
        post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList;
      }

    }

    // new code 
    if (paramsProductName === 'ManipalCigna ProHealth Prime Advantage') {

      if (checkedAddon.length > 0) { // after edit addons & rider 

        // remove dependet rider in case self & male
        if (matchingFFCategoryId === 1 && form_data.gender === 'male') {
          const RemoveAddons_Rider = ["C0001491", "C0002019"];
          let list = checkedAddon.filter(item => !RemoveAddons_Rider.includes(item))
          setCheckedAddon(list);
          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => list.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => list.includes(item.Addon));

        }else if(form_data.self_age >= "60" || form_data.spouse_age >= "60"){

          const RemoveAddons_Rider = ["C0001491"];
          let list = checkedAddon.filter(item => !RemoveAddons_Rider.includes(item))
          setCheckedAddon(list);
          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => list.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => list.includes(item.Addon));

        } else {

          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => checkedAddon.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => checkedAddon.includes(item.Addon));
        }


      } else { // default sending rider list
        post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList;
        post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList;
      }

    }


    /// new code 
    if (paramsProductName === 'ManipalCigna ProHealth Prime Protect') {

      if (checkedAddon.length > 0) { // after edit addons & rider 

        if (matchingFFCategoryId === 1 && form_data.gender === 'male') {
          const RemoveAddons_Rider = ["PC0001491", "C0002017"];
          let list = checkedAddon.filter(item => !RemoveAddons_Rider.includes(item))
          setCheckedAddon(list);
          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => list.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0] && filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => list.includes(item.Addon));

        } else {
          post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => checkedAddon.includes(item.Rider));
          post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => checkedAddon.includes(item.Addon));
        }
      } else { // default sending addons list

        post_data_new.PolicyLobList[0].RiderDetailsList = filteredCignaRiderAndAddons[0].RiderDetailsList.filter(item => item.Rider !== 'PC0001491');
        const excludedAddons = ["C0002017", "C0002018", "C0002022"];
        post_data_new.PolicyLobList[0].AddonDetailsList = filteredCignaRiderAndAddons[0].AddonDetailsList.filter(item => !excludedAddons.includes(item.Addon));
      }

    }


    console.log("line 333 filteredCignaRiderAndAddons ", filteredCignaRiderAndAddons, " post_data_new", post_data_new);



    // Remove RiderDetailsList if it's an empty array 
    if (Array.isArray(post_data_new.PolicyLobList)) {
      post_data_new.PolicyLobList.forEach(policyLob => {
        if (Array.isArray(policyLob.RiderDetailsList) && policyLob.RiderDetailsList.length === 0) {
          delete policyLob.RiderDetailsList;
        }
      });
    }

    // Remove RiderDetailsList if it's an empty array 
    if (Array.isArray(post_data_new.PolicyLobList)) {
      post_data_new.PolicyLobList.forEach(policyLob => {
        if (Array.isArray(policyLob.AddonDetailsList) && policyLob.AddonDetailsList.length === 0) {
          delete policyLob.AddonDetailsList;
        }
      });
    }


    // console.log("line 379 - post_data_new:::", post_data_new);

    let post = {
      form_data: form_data,
      api_post_data: post_data_new
    }

    if (tryAgain && localStorage.getItem('health_plan_prev_request')) { // IF ACTION IS TRY AGAIN THEN RE-CALL PREVIUS POST  
      setTryAgain(false);
    }


    try {
      http.post('/health/loadQuote', post)
        .then((response) => {

          sessionStorage.setItem('healthpostData', JSON.stringify(post));

          console.log("response.data[0]", response.data, ' | paramsProductName', paramsProductName);
          if (response.data[0] && response.data[0].ErrorResponse) {
            let msg = [];
            // eslint-disable-next-line
            response.data[0].ErrorResponse.map(function (v, i) {
              msg.push(`${i + 1}. errDescription: ${v.errDescription} ,  errProcessStatusCd: ${v.errProcessStatusCd}`);  
            });
            setWarningshow(true);
            setWarningMessage([...new Set(msg)].join('   '));

            return false;
          }

          // filter from  product which loading on current page 
          const resp = (response.data).filter((obj) => obj.ProductName === paramsProductName); // response.data;
          console.log("line 409 resp", resp);

          if (resp.length === 0) {
            setWarningshow(true);
            setWarningMessage(`Error -  Product is not available`);
            setTryAgain(true);
            setLoading(false);
          } else if (resp[0] && resp[0].DuePremium) { // case of success  

            const filteredData = resp;
            setLoading(false);
            // store success previus request post data
            localStorage.setItem('health_plan_prev_request', JSON.stringify(form_data));


            set_api_response(filteredData[0]);
            setProductName(filteredData[0].ProductName);
            setLogoUrl(logo(filteredData[0].CarrierCode));

            if (filteredData[0].CarrierCode === 'CIGNA') {
              // alert("test 1");

              if (filteredData[0].PolicyLobList && filteredData[0].PolicyLobList.length > 0 && filteredData[0].PolicyLobList[0].AddonDetailsList) {
                let newData = filteredData[0].PolicyLobList[0].AddonDetailsList
                  .filter(item => item.hasOwnProperty('CarrierBasePremium') && item.CarrierBasePremium !== "").map(item => item.BenefitCode);

                setCheckedAddon(newData);
              }

              if (filteredData[0].PolicyLobList && filteredData[0].PolicyLobList.length > 0 && filteredData[0].PolicyLobList[0].RiderDetailsList) {
                let newData = filteredData[0].PolicyLobList[0].RiderDetailsList.filter(item => item.hasOwnProperty('CarrierBasePremium') && item.CarrierBasePremium !== "").map(item => item.Rider);

                setCheckedAddon([...checkedAddon, ...newData]);

              }


            } else if (filteredData[0].CarrierCode === 'CARE') {

              if (filteredData[0].PolicyLobList && filteredData[0].PolicyLobList.length > 0 && filteredData[0].PolicyLobList[0].RiderDetailsList) {
                let newData = filteredData[0].PolicyLobList[0].RiderDetailsList
                  .filter(item => item.hasOwnProperty('CarrierBasePremium') && item.CarrierBasePremium !== "").map(item => item.BenefitCode);
                setCheckedAddon(newData);
              }

            } else {

              if (filteredData[0].PolicyLobList && filteredData[0].PolicyLobList.length > 0 && filteredData[0].PolicyLobList[0].AddonDetailsList) {
                let newData = filteredData[0].PolicyLobList[0].AddonDetailsList
                  .filter(item => item.hasOwnProperty('CarrierBasePremium') && item.CarrierBasePremium !== "").map(item => item.BenefitCode);

                setCheckedAddon(newData);

              }
            }

            // console.log('line 686 checkedAddon ====>', checkedAddon); 

            const addonDetailsList = filteredData[0].PolicyLobList[0].AddonDetailsList;
            if (addonDetailsList) {
              const modifiedData = addonDetailsList.map(item => {
                if (!item.Addon) {
                  item.Addon = item.BenefitCode;
                }
                return item;
              });
              setAddonDetailsList(modifiedData);
            }

          } else if (resp[0] && resp[0].Status && resp[0].Status === -102) { // CASE IN ERROR 

            setWarningshow(true);
            setWarningMessage(`Error ${resp[0].Message}`);
          } else if ((resp[0].ErrorDetails && resp[0].ErrorDetails.length > 0)) { // CASE IN ERROR 
            const error = resp.ErrorDetails[0];
            setWarningshow(true);
            setWarningMessage(`Error ${error.ErrorCode}: ${error.Message}`);
          } else if ((resp.ErrorDetails && resp.ErrorDetails.length > 0)) { // CASE IN ERROR 
            const error = resp.ErrorDetails[0];
            setWarningshow(true);
            setWarningMessage(`Error ${error.ErrorCode}: ${error.Message}`);

          } else if (resp[0].ErrorResponse) { // CASE IN ERROR 
            const Message = resp[0].ErrorResponse[0].errDescription;
            const ErrorCode = resp[0].ErrorResponse[0].errProcessStatusCd;
            setWarningshow(true);
            setWarningMessage(`Error ${ErrorCode}: ${Message}`);

          } else if (resp[0].Code && resp[0].Code === 'MO-PLATFORM-Rule-B4000') { // CASE IN ERROR 
            const ErrorCode = resp[0].Message && resp[0].Message;
            const Message = resp[0].Messages && resp[0].Messages[0].Message;

            setWarningshow(true);
            setWarningMessage(`Error ${ErrorCode}: ${Message}`);
          } else {
            setWarningshow(true);
            setWarningMessage(`Something went wrong with the request!`);
            // NOTHING 
          }
        })
        .catch((error) => {
          console.error('HTTP request failed:', error);
          setWarningshow(true);
          setWarningMessage('Something went wrong with the request!' + error);

          /*  Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: 'Something went wrong with the request!' + error,
           }).then(() => {
            // window.location.reload();
            setTryAgain(true);
           }) */
        });
    } catch (e) {
      console.error('An unexpected error occurred:', e);
      setWarningshow(true);
      setWarningMessage('Something went wrong with the request!' + e);
      /* Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An unexpected error occurred. Please try again.',
      }).then(() => {
        //window.location.reload();
        setTryAgain(true);
      }) */
    }
    

  }
  // eslint-disable-next-line
  const [age_group, set_age_group] = useState([]);

  useEffect(() => {

    setTimeout(function () {
      fetchData();
    }, 100);
    // eslint-disable-next-line
  }, [form_data]);


  const [tryAgain, setTryAgain] = useState(false);

  useEffect(() => {

    setWarningshow(false);
    setWarningMessage('');

    if (tryAgain) {

      let pre_post_request = localStorage.getItem('health_plan_prev_request') ? JSON.parse(localStorage.getItem('health_plan_prev_request'), (key, value) => {
        if (value === 'true' || value === true) return true;
        else if (value === 'false' || value === false) return false;
        else if (value === '') return '';
        else if (!isNaN(value)) return parseInt(value);
        return value;
      }) : false;
      if (pre_post_request) {
        setFormData(pre_post_request)
        localStorage.removeItem('health_plan_prev_request');
      } else {
        // window.location.reload();
      }
    }
  }, [tryAgain])



  //console.log('line 797 checkedAddon ====> ', checkedAddon);



  return (

    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Insurance Advise</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Health Insurance</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <span>{product_name}</span>
            <Link to={"/health-plan/quote"} className="bluebtn" style={{ position: 'absolute', right: '9px', color: 'white' }}><i className="fa fa-angle-left font13 mr5" aria-hidden="true"></i> Back</Link>
          </div>
          {loading ? (
            <Loader /> // Render the loader when loading is true
          ) :
            <div className="row mt20 life-insurance-details">
              <div className="col-md-8">
                <div className="card p20">

                  <div className="row">
                    <div className="col-md-12 life-lnsurance-logo-section">
                      <table className="">
                        <tr>
                          <td style={{ 'maxWidth': '150px' }}>{logo_url && <img src={logo_url} alt="" className="logo maxWidth100" />} </td>
                          <td style={{ 'paddingLeft': "10px" }}>
                            <h2 className="heading-1">  {product_name}  </h2>

                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="border-hr" />
                  {
                    api_response.PolicyLobList[0].FeaturesList && (
                      <>
                        <div className="card-body-text extra-benefits">
                          <h3 className="heading-2 mt10 mb10">Features</h3>
                          <div className="row">

                            {api_response.PolicyLobList[0].FeaturesList.map((item, index) => (

                              <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                                <h5 className="heading-3">{item.BenefitDescription}</h5>
                                <p className="text-type-1">
                                  {item.Notes}
                                </p>
                              </div>

                            ))}
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    api_response.PolicyLobList[0].HospitalizationBenefitList && (
                      <>
                        <div className="card-body-text extra-benefits">
                          <h3 className="heading-2 mt10 mb10">Hospitalization Benefit</h3>
                          <div className="row">

                            {api_response.PolicyLobList[0].HospitalizationBenefitList.map((item, index) => (

                              <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                                <h5 className="heading-3">{item.BenefitDescription}</h5>
                                <p className="text-type-1">
                                  {item.Notes}
                                </p>
                              </div>

                            ))}
                          </div>
                        </div>
                      </>
                    )
                  }

                </div>

                <div className="row mt20">
                  <div className="col-md-12">
                    <div className="card p20 mt20">
                      <div className="card-body-text extra-benefits">
                        <h3 className="heading-2 mt10 mb10">Additional Features</h3>
                        <div className="row">
                          {api_response.PolicyLobList[0].AdditionalFeaturesList.map((item, index) => (

                            <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                              <h5 className="heading-3">{item.BenefitDescription}</h5>
                              <p className="text-type-1">
                                {item.Notes}
                              </p>
                            </div>

                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">{/** start of right section col-md-4 */}
                {
                  api_response && api_response.ProductName && <>
                    <CardHealthPremiumDetails1 api_response={api_response} setFormData={setFormData} form_data={form_data} setEditPlanDetailsFlag={setEditPlanDetailsFlag} setSelectCover={setSelectCover} setEditAddressFlag={setEditAddressFlag} />
                    <HealthInsuranceAddonScreen api_response={api_response} setEditAddOnsFlag={setEditAddOnsFlag} checkedAddon={checkedAddon} uncheckedAddon={uncheckedAddon} form_data={form_data} />
                  </>
                }

                <Assistant className="mt30" />

              </div>
            </div>
          }
        </div>
      </div>


      {
        editPlanDetailsFlag && <EditHealthCoverDetails setEditPlanDetailsFlag={setEditPlanDetailsFlag} setFormData={setFormData} form_data={form_data} setSelectCover={setSelectCover} selected_cover={selected_cover} />
      }

      {
        editEddressFlag && <EditAddress setEditAddressFlag={setEditAddressFlag} session_name={'form_data'} />
      }

      {
        (editAddOnsFlag && !['ManipalCigna ProHealth Prime Active', 'ManipalCigna ProHealth Prime Advantage', 'ManipalCigna ProHealth Prime Protect'].includes(paramsProductName)) && <EditHealthAddOns apiResponse={api_response} setEditAddOnsFlag={setEditAddOnsFlag} checkedAddon={checkedAddon} setCheckedAddon={setCheckedAddon} uncheckedAddon={uncheckedAddon} setUncheckedAddon={setUncheckedAddon} setFormData={setFormData} form_data={form_data} />
      }

      {
        (editAddOnsFlag && ['ManipalCigna ProHealth Prime Active', 'ManipalCigna ProHealth Prime Advantage', 'ManipalCigna ProHealth Prime Protect'].includes(paramsProductName)) && <EditHealthAddOnsCigna apiResponse={api_response} setEditAddOnsFlag={setEditAddOnsFlag} checkedAddon={checkedAddon} setCheckedAddon={setCheckedAddon} uncheckedAddon={uncheckedAddon} setUncheckedAddon={setUncheckedAddon} setFormData={setFormData} form_data={form_data} cignaPost={cignaPost} setCignaPost={setCignaPost} />
      }




      <Modal show={warningshow} size="lg" dialogClassName='modal-container' keyboard centered>
        <ModalBody >
          <div className="p20">
            <div className="text-left">
              <img src='../../images/error_message.png' alt='Error' width={"80"} />
            </div>
            <div className="text-left mt40">
              <h1 className='font24 fw700 color384'>{warningTitle}</h1>
            </div>
            <div className="text-left mt40 color485 fw500 font17">
              <strong>{warningMessage}</strong>
            </div>
            <div className="text-center smallbtn popupConsultBtn mt30">
              <button type="button" className="btn btn-danger btn-xl" onClick={() => { setTryAgain(true)  /* window.location.reload() */ }}><i class="fa fa-refresh faErrorClass" aria-hidden="true"></i> Try Again</button>
              <button type="button" disabled={false} className="btn btn-success btn-xl" onClick={() => { setWarningshow(false); }}><i class="fa fa-times faErrorClass" aria-hidden="true"></i>
                Close </button>
            </div>

          </div>
        </ModalBody>
      </Modal>


    </Fragment>
  );
}