import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../components/layout/DashboardHeader";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../css/global.css';
import EditAddress from "./components/EditAddress";
import Global from "../Global";
import Title from "../Title";
import { db_date, get_gender, paymentFrequencyList } from "../components/global";
import { Modal, ModalBody } from 'react-bootstrap';
import formatAmount from 'indian-currency-formatter';
import { ageCalculate } from '../components/global'
import PlusMinusInput from "./components/PlusMinusInput";
import http from "../http";
import { params_hdfc_life_getQuoteNew } from "./data/params/hdfc";
import ViewQuotationlist from "./components/ViewQuotationlist";
import Swal from "sweetalert2";
import TermMobile from "./components/TermMobile";

export default function TermPlanQuote() {

  //eslint-disable-next-line
  const [warningshow, setWarningshow] = useState(false);
  //eslint-disable-next-line
  const [warningMessage, setWarningMessage] = useState();
  //eslint-disable-next-line
  const [warningTitle, setWarningTitle] = useState("Term Plan Error");
  //eslint-disable-next-line
  const [disableQuote, setDisableQuote] = useState(false);
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState('Term Plan: Quote');

  const [activeTab, setActiveTab] = useState("quote");
  // eslint-disable-next-line 
  const [rightTab, setRightTab] = useState("quote_r");

  const checkTab = (tab) => {
    setActiveTab(tab);

    if (tab === "family") {
      setRightTab("family_r");
    } else {
      setRightTab("quote_r");
    }
  };

  // eslint-disable-next-line
  const [form_data, setFormData] = useState(localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});

  // eslint-disable-next-line
  const [memberCount, setMemberCount] = useState(0);
  // eslint-disable-next-line
  const [selected_cover, setSelectCover] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let c = 0;
    c += (form_data.self === 'true' || form_data.self ? 1 : 0);
    c += (form_data.spouse === 'true' || form_data.spouse ? 1 : 0);
    c += (form_data.son_arr ? (form_data.son_arr).length : 0);
    c += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);
    setMemberCount(c)

  }, [form_data])

  const [editEddressFlag, setEditAddressFlag] = useState(false);
  const [frequency, setFrequency] = useState(1);
  const [coverTill, setCoverTill] = useState(form_data.cover_till ? form_data.cover_till : 75);

  /*useEffect(() => {
    setFrequency(frequency);
  }, [frequency]);*/



  useEffect(() => {
    setCoverTill(coverTill)
  }, [coverTill]);


  console.log('form_data 1 ', form_data);
  // eslint-disable-next-line
  const [riderSumInsured, setRiderSumInsured] = useState(form_data.SumInsured ? (form_data.SumInsured / 2) : 1000000);

  const [cover_required_values, set_cover_required_values] = useState(form_data.SumInsured ? form_data.SumInsured : 5000000);
  // eslint-disable-next-line
  const [minCover, setMinCover] = useState(form_data.age + 5);
  // eslint-disable-next-line
  const [maxCover, setMaxCover] = useState(99);
  useEffect(() => {
    setSelectCover(cover_required_values);
  }, [cover_required_values])

  const c = coverTill - form_data.age;
  const userTerm = c ? c : (60 - form_data.age);



  const RiderDetails = [
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "INAD",
      "Term": (userTerm + form_data.age) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
      "SumInsured": riderSumInsured > 20000000 ? 20000000 : riderSumInsured,
      "PPT": (userTerm + form_data.age) > 75 ? Math.min(40, (75 - form_data.age)) : userTerm,
      "PPTOption": "REGULAR",
      "cat": "HDFC"
    },
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "C4CI",
      "Term": userTerm > 40 ? 40 : userTerm,
      "SumInsured": form_data.SumInsured > 1000000 ? 2500000 : 1000000,
      "PPT": userTerm > 40 ? 40 : userTerm,
      "PPTOption": "REGULAR",
      "cat": "HDFC"
    },
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "PPR_PAC",
      "Term": (userTerm + form_data.age) > 75 ? (75 - form_data.age) : userTerm,
      "SumInsured": riderSumInsured > 5000000 ? 500000 : riderSumInsured,
      "PPT": (userTerm + form_data.age) > 75 ? (75 - form_data.age) : userTerm,
      "PPTOption": "REGULAR",
      "cat": "HDFC"
    },
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "PPR_CC",
      "Term": userTerm,
      "SumInsured": riderSumInsured > 5000000 ? 5000000 : riderSumInsured,
      "PPT": userTerm,
      "PPTOption": "REGULAR",
      "cat": "HDFC"
    },
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "CIBF",
      "Term": userTerm,
      "SumInsured": form_data.SumInsured > 1000000 ? 2500000 : 1000000,
      "PPT": userTerm,
      "PPTOption": "REGULAR",
      "cat": "ICICI"
    },
    {
      "ProductElementCode": "RIDERDETAILS",
      "Rider": "ADBU",
      "Term": userTerm,
      "SumInsured": riderSumInsured,
      "PPT": userTerm,
      "PPTOption": "REGULAR",
      "cat": "ICICI"
    }
  ];

  const [api_response, set_api_response] = useState([])
  const [no_response, set_no_response] = useState(false);

  // eslint-disable-next-line
  const [hdfcError, setHdfcError] = useState(false);
  // eslint-disable-next-line
  const [iciciError, setIciciError] = useState(false);
  // eslint-disable-next-line
  const [hdfcErrorMsg, setHdfcErrorMsg] = useState(false);
  // eslint-disable-next-line
  const [iciciErrorMsg, setIciciErrorMsg] = useState(false);

  const fetchData = () => {

    setLoading(true);
    set_no_response(true);
    set_api_response(false);

    const post_data = params_hdfc_life_getQuoteNew;
    post_data.SumInsured = cover_required_values;
    post_data.Term = coverTill - form_data.age;
    post_data.PPT = coverTill - form_data.age;

    post_data.PPTOption = frequency === "5" ? "SINGLE" : form_data.PPT ? (userTerm !== form_data.PPT ? "REGULAR" : "REGULAR") : "REGULAR";



    post_data.TOBstatus = form_data.smoked_status ? form_data.smoked_status : 'N';
    post_data.Education = form_data.education;
    post_data.PolicyLobList[0].PolicyRiskList[0].DateOfBirth = db_date(form_data.dob);
    post_data.PolicyLobList[0].PolicyRiskList[0].Gender = form_data.gender;
    post_data.PolicyLobList[0].PolicyRiskList[0].OccupationCode = (form_data.occupation).toString();

    post_data.PolicyLobList[0].PolicyRiskList[0].City = form_data.city;
    post_data.PolicyLobList[0].PolicyRiskList[0].PostCode = form_data.pin;

    if(form_data.full_name){
      const [first, ...restname] = form_data.full_name.split(' ');

      post_data.PolicyLobList[0].PolicyRiskList[0].FirstName = first;
      post_data.PolicyLobList[0].PolicyRiskList[0].LastName = restname.join(' ');
    }

    post_data.AnnualIncome = form_data.annual_income;
    post_data.Frequency = frequency;
    post_data.DBPayoutTerm = "0";
    post_data.LSPercentage = "0";
    console.log('frequency', frequency);
    if (frequency === "5") {
      // Update PPTOption to "SINGLE" for all elements in RiderDetails array
      const updatedRiderDetails = RiderDetails.map(detail => {
        return {
          ...detail,
          PPTOption: "SINGLE"
        };
      });
      post_data.PolicyLobList[0].RiderDetails = updatedRiderDetails;
    } else {
      post_data.PolicyLobList[0].RiderDetails = RiderDetails;
    }

    post_data.PPTOption = (coverTill === 99 ? 'REGULAR WHOLE LIFE' : post_data.PPTOption);
    post_data.DBOption = "2";
    const post = {
      form_data: form_data,
      api_post_data: post_data
    }

    console.log('form_data', form_data);
    console.log('post_data', post_data);

    http.post('/getQuote', post).then((response) => {
      const resp = response.data;
      if (resp.status === 401) {
        localStorage.removeItem('access_token');
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.message,
        }).then(() => {
          window.location.href = Global.base_url;
        })
      } else if (resp.status === 503) {
        setWarningshow(true);
        setWarningMessage(resp.message);
        setWarningTitle(resp.env);
      } else {

        resp.forEach(error => {
          if (error.error_details && error.error_details.error_code && error.CarrierCode === "HDFC") {

            setHdfcError(true);
            setHdfcErrorMsg(error.error_details.error_message);
          } else {
            setHdfcError(false);
            setHdfcErrorMsg('');
          }

          if (error.Error && error.Error.ErrorCode && error.CarrierCode === "ICICI") {
            setIciciError(true);
            setIciciErrorMsg(error.Error.ErrorMessage);
          } else {
            setIciciError(false);
            setIciciErrorMsg('');
          }
        });

        set_api_response(resp);

        sessionStorage.setItem('term_sharequote', JSON.stringify(post));
        setDisableQuote(false);
      }
      setLoading(false);
    });
  }

  const get_quote_uuid = () => {

    const params = new URLSearchParams(window.location.search);
    const uuid = params.get('id');

    if (uuid !== null) {

      http.post('/get-quote-uuid', { uuid: uuid }).then((response) => {

        const resp = response.data;
        if (resp.status === 100) {
          localStorage.setItem('form_data_term_plan', resp.data);

          setTimeout(function () {
            fetchData();
          }, 300);

        }else{
          window.location.href = 'quote';
        }


      });
    } else {
      setTimeout(function () {
        fetchData();
      }, 300);
    }


  }
  useEffect(() => {
    set_no_response(false);

    get_quote_uuid();

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    localStorage.setItem('form_data_term_plan', JSON.stringify({ ...form_data }));
  }, [form_data]);

  useEffect(() => {
    const c = coverTill - form_data.age;

    localStorage.setItem('form_data_term_plan', JSON.stringify({ ...form_data, cover_till: coverTill, frequency: frequency, SumInsured: cover_required_values, PPT: (!isNaN(parseInt(c)) ? c : (60 - form_data.age)) }));
    // eslint-disable-next-line
  }, [coverTill, frequency, cover_required_values]);

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="form_title">
            <div className="mt30 font21 fw600 color384">
              Suggested Term Plans
            </div>
            <div className="font16 color626">
              Based on the inputs provided by you, here are some plans for you.
            </div>
            <div className="mt30">
              {/*  <Link to={"/"} className="btn bluebtn wizard-btn backtowizardBtn font14 fw600">Back</Link>         */}
            </div>
          </div>

          <div className="dashboard_outer mt30">
            {/*------left tab start */}
            <div className="dashboard_left pb20">
              <div className="db_maintab_outer mt25">
                <div className="db_maintab_wpr">
                  <nav className="font13">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{ 'justifyContent': 'left' }}>
                      <a
                        className={
                          activeTab === "family"
                            ? "nav-item nav-link active"
                            : "nav-item nav-link"
                        }
                        id="nav-family-tab"
                        data-toggle="tab"
                        href="#nav-family"
                        role="tab"
                        aria-controls="nav-family"
                        aria-selected="false"
                        onClick={() => checkTab("family")}
                      >
                        Inputs
                      </a>
                      <a className={activeTab === "quote" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-quote-tab" data-toggle="tab" href="#nav-quote"
                        role="tab" aria-controls="nav-quote" aria-selected="true" onClick={() => checkTab("quote")} > Quote
                      </a>
                    </div>
                  </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">

                  <div className={activeTab === "family" ? "tab-pane fade show active" : "tab-pane fade"}
                    id="nav-family"
                    role="tabpanel"
                    aria-labelledby="nav-family-tab">
                    {activeTab === "family" &&
                      <>
                        <div className="familycard p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left">My Details</div>
                            <div className="title_viewmore"><Link to={"/term-plan"} title="Edit My Inputs Details"><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link> </div>
                          </div>

                          {/*  <div className="ml25 color182 font14 fw600 familycard_title_left"> 
                              Went Cover For
                            </div> */}
                          <div className="categoryTabs_outer mt20" style={{ padding: '0px' }}>

                            {form_data.gender && (
                              <li>
                                <div className="categoryTab font12 fw500 color353" title="Gender (Age)">
                                  <i className="fa fa-user mr5" aria-hidden="true"></i> {get_gender(form_data.gender) + ' (' + ageCalculate(form_data.dob) + ')'}
                                </div>
                              </li>

                            )}
                            {form_data.education && (
                              <li>
                                <div className="categoryTab font12 fw500 color353" title="Education">
                                  <span className="risk mr5" /> {form_data.education}
                                </div>
                              </li>
                            )}
                            {form_data.occupation && (
                              <li>
                                <div className="categoryTab font12 fw500 color353" title="Occupation">
                                  <span className="family_icon mr5" /> {form_data.occupation}
                                </div>
                              </li>
                            )}

                          </div>
                          <div className="familycard_content mt20">
                            <div className="familycard_content_left">
                              <div className="fw500 colora5b font12"><span className="familyicon mr5"></span> Members</div>
                              <div className="fw600 color162 font18">{1}</div>
                            </div>
                            <div className="familycard_content_mdl">
                              <div className="fw500 colora5b font12">Annual Income</div>
                              <div className="fw600 color162 font14"><i className="fa fa-inr" aria-hidden="true"></i> {formatAmount(form_data.annual_income)}</div>
                            </div>
                            <div className="familycard_content_right">
                              <div className="fw500 colora5b font12">Monthly Expenses</div>
                              <div className="fw600 color162 font14"> <i className="fa fa-inr" aria-hidden="true"></i> {formatAmount(form_data.monthly_expenses)}   </div>
                            </div>
                          </div>
                        </div>

                        <div className="familycard  p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left"><span className="fundicon mr5" /> Pre Exiting Disease  </div>
                            {/* <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer"> <div className="goaledit_icon ml4"></div> </span> </div> */}
                          </div>
                          <div className="familycard_content mt20">
                            <div className="categoryTabs_outer" style={{ padding: '0px' }}>
                              {
                                form_data.any_existing_illness &&
                                form_data.any_existing_illness.map((v) => {
                                  return (<li>
                                    <div className="categoryTab font12 fw500 color353"> {v === 'None of these' ? 'No illness' : v}</div>
                                  </li>)
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div className="familycard graybg p25">
                          <div className="familycard_title">
                            <div className="color182 font14 fw600 familycard_title_left"><span className="insuranceicon mr5" />Address </div>
                            <div className="title_viewmore"><span title="Edit Address" className="font12 fw600 color0d6 pointer" onClick={() => setEditAddressFlag(true)}> <div className="goaledit_icon ml4"></div> </span> </div>
                          </div>
                          <div className="familycard_content mt20">
                            {(form_data.city || form_data.pin) &&
                              <li style={{ 'margin-top': '-3px' }}>
                                <div className="categoryTab font12 fw500 color353">
                                  <span className="fa fa-map-marker mr5"></span>  {form_data.city ? form_data.city : form_data.pin}
                                </div>
                              </li>
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>

                  <div className={activeTab === "quote" ? "tab-pane fade show active" : "tab-pane fade "}
                    id="nav-quote"
                    role="tabpanel"
                    aria-labelledby="nav-quote-tab"
                  >
                    {activeTab === "quote" &&
                      <>
                        <div className="tab-pane fade show active" id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">

                          <div className="targetbar_outer_ pl25 pr25 mt30">
                            <div className="targetbar_title">
                              <div className="font14 fw500 color182">Cover till</div>
                              <div>
                                <div className="tragetvalue font13 fw700 colorf47"><span className="orangeDot mr5"></span>{coverTill} Yr</div>
                              </div>
                            </div>
                            <div className="targetbar mt10">
                              <form className="target_range_bar">
                                <div className="form-group">
                                  <Slider step={1} name={"desire"} className='mt15' min={minCover} max={maxCover} onChange={e => { setCoverTill(e); setDisableQuote(true) }} pushable={true}
                                    value={coverTill}
                                  />
                                </div>
                              </form>

                            </div>
                          </div>
                          <div className="flex_center justify_center mt8 pl20 pr25">
                            <div className="valuecount valuecount_blue font14 fw400">{minCover} Yr</div>
                            <div className="valuecount valuecount_gold font14 fw400">{maxCover} Yr</div>
                          </div>

                          <PlusMinusInput amount={cover_required_values} setAmount={set_cover_required_values} className="p10 mt30" setDisableQuote={setDisableQuote}/>

                          <div className="targetbar_outer_ pl25 pr25 pt30 mt10">
                            <div className="targetbar_title">
                              <div className="font14 fw500 color182">Payment Frequency</div>
                            </div>
                            <div className="targetbar mt10">
                              <form className="target_range_bar">
                                <div className="form-group col-md-12">
                                  <ul className="steps_tag font14 fw500 color182 mt15">
                                    {
                                      paymentFrequencyList.map(function (row, index) {
                                        return (
                                          <li><label><input defaultChecked={row.value === 1 && true} className="cover_till" type="radio" name="cover_till" value={row.value} onClick={(e) => { setFrequency(e.target.value); setDisableQuote(true) }} /><span className="rdo_text_box">{row.label}</span></label></li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/*  {
                            (hdfcError) && (
                              <span className="text-danger font12 text-center">
                                {hdfcErrorMsg}
                              </span>
                            )
                          }
                          {(hdfcError || iciciError) && (
                            <span className="text-danger font12 text-center">
                              {hdfcErrorMsg || iciciErrorMsg}
                            </span>
                          )} */}


                          <div className="targetbar_outer_ pl25 pr25 pt30 mt10">
                            <input type="button" className="btn btn-primary continue-to-buy-btn " value="Get Quotation" onClick={fetchData} />
                          </div>

                        </div>

                      </>
                    }
                  </div>



                </div>
              </div>
            </div>
            {/*------left tab end */}

            {/*------right tab start */}
            <div className="dashboard_right" style={{'opacity':'1'}}>
              <div className="graphvalue">
                <div className="graphvalue_left">
                  <div className="graphvalue_count font22 fw500">
                    <span className='font16'>{api_response ? api_response.length : 0} Plan available</span>
                  </div>
                </div>
                <div className="graphvalue_right">


                  <div className="font14 fw500 ml10">
                    {/*<Link to={"/term-plan/compare"} className="bluebtn"><i className="fa-solid fa-code-compare font13 mr5"></i> Compare Benefits</Link>*/}
                  </div>
                  <div className="font14 fw500 ml10">
                    <strong className="bluebtn pointer" onClick={() => { setWarningshow(true); }}>Share Quote</strong>
                  </div>

                </div>
              </div>
              <div className="graphtabs_outer mt25 pb30">
                <div className="graphtabs">
                  <nav className="font12">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-activeyears-tab" data-toggle="tab" href="#nav-activeyears" role="tab" aria-controls="nav-activeyears" aria-selected="true">Available plans</a>
                    </div>
                  </nav>
                </div>
                <div className={disableQuote ? "disableQuote tab-content" : "tab-content"} id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-activeyears" role="tabpanel" aria-labelledby="nav-activeyears-tab">

                    {/* {(hdfcError || iciciError) && (
                      <span className="text-danger font12 text-center">
                        {hdfcErrorMsg || iciciErrorMsg}
                      </span>
                    )} */}
                    {
                      typeof api_response === 'object' ? <ViewQuotationlist data={api_response} view_details_link="life-insurance-details/term-plan" frequency={frequency}/>
                        : <></>
                    }

                    {/*  <ProductList  product_arr={filter_packages}  view_details_link="life-insurance-details/term-plan"/>  */}

                  </div>
                </div>
              </div>
            </div>
            {/*------right tab end */}

          </div>
        </div>
      </div>
      {
        editEddressFlag && <EditAddress setEditAddressFlag={setEditAddressFlag} session_name={'form_data_term_plan'} />
      }

      {
        loading && <Loaders loader={no_response} />
      }
      <Modal show={warningshow} size="lg" dialogClassName='modal-container' keyboard centered>
        <ModalBody>
          <TermMobile setWarningshow={setWarningshow} />
        </ModalBody>
      </Modal>

    </Fragment>
  );
}



const Loaders = (props) => {
  return props.loader ? <div className="sweet-loading" style={{
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'position': 'fixed',
    'top': '0',
    'left': '0',
    'right': '0',
    'bottom': '0',
    'backgroundColor': '#fff',
    'zIndex': '9999',
    'opacity':'0.9'
  }}>
    <img
      src={Global.base_url + "/images/icons/finloader.gif"}
      alt=""
      loading={true}
      style={{
        'width': '90px',
        'height': '90px'
      }}
    />
  </div> : 'No Records !';
}