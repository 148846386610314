import { React, Fragment, useState, useEffect } from "react";

import Title from "../Title";
import DashboardHeader from "../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./LifeInsuranceDetails.css";
import TermPlanProducts from "./data/TermPlanProducts";
import Global from "../Global";
import { db_date } from "../components/global";
//import { getQuoteNew } from "./life-insurance/HdfcFunctions";
import Swal from "sweetalert2";
import http from "../http";
import { params_hdfc_life_getQuoteFinalised } from "./data/params/hdfc";

import Loader from "../components/Loader";

export default function LifeInsuranceDetailsCompare() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Term Plan: Quote");
  // eslint-disable-next-line
  const [active, setActive] = useState(null);

  // eslint-disable-next-line
  const [SIPInvestmentAmount, setSIPInvestmentAmount] = useState(350);



  const { CarrierCode } = useParams();
  const [packages, setPackages] = useState(TermPlanProducts.filter((obj) => obj.CarrierCode === CarrierCode)[0]);

  useEffect(() => {
    setPackages(TermPlanProducts.filter((obj) => obj.CarrierCode === CarrierCode)[0]);
  }, [CarrierCode])

  // eslint-disable-next-line
  const [form_data, setFormData] = useState(localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});


  const [getQuoteNewData, setQuoteNewData] = useState({});
  const [getQuoteICICI, setGetQuoteICICI] = useState({});
  // eslint-disable-next-line
  const [getRiders, setRiders] = useState({});
  // eslint-disable-next-line
  const [frequency, setFrequency] = useState(1);


  /***Edit Basic Info end */




  const getQuoteNew = () => {



    const post_data = params_hdfc_life_getQuoteFinalised; // params_hdfc_life_getQuoteNew; 

    const form_data = localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {};

    const RiderDetails = [
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "INAD",
        "Term": "10",
        "SumInsured": "400000",
        "PPT": "10",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "C4CI",
        "Term": "10",
        "SumInsured": "400000",
        "PPT": "10",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_PAC",
        "Term": "1",
        "SumInsured": "400000",
        "PPT": "1",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "PPR_CC",
        "Term": "10",
        "SumInsured": "900000",
        "PPT": "10",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADBU",
        "Term": "10",
        "SumInsured": "0",
        "PPT": "10",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "CIBF",
        "Term": "10",
        "SumInsured": "100000",
        "PPT": "10",
        "PPTOption": "Regular"
      },
      {
        "ProductElementCode": "RIDERDETAILS",
        "Rider": "ADHB",
        "Term": "10",
        "SumInsured": "1000000",
        "PPT": "10",
        "PPTOption": "Regular"
      }
    ];

    const c = form_data.cover_till - form_data.age;
    post_data.SumInsured = form_data.SumInsured;
    post_data.Term = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
    post_data.PPT = (!isNaN(parseInt(c)) ? c : (60 - form_data.age));
    post_data.PolicyLobList[0].PolicyRiskList[0].DateOfBirth = db_date(form_data.dob);
    post_data.PolicyLobList[0].PolicyRiskList[0].Gender = form_data.gender;

    post_data.PolicyLobList[0].PolicyRiskList[0].Email = (form_data.email ? form_data.email : '');
    post_data.PolicyLobList[0].PolicyRiskList[0].Mobile = (form_data.mobile ? form_data.mobile : '');

    post_data.AnnualIncome = form_data.annual_income;
    post_data.Frequency = frequency;
    post_data.PolicyLobList[0].RiderDetails = RiderDetails;
    const post = {
      form_data: form_data,
      api_post_data: post_data
    }

    // eslint-disable-next-line
    let data = [];
    http.post('/getQuote', post).then((response) => {

      const resp = response.data;
      setLoading(false);

      if (resp[0] && resp[0].TotalPremium) {
        setQuoteNewData(resp.filter((obj) => obj.CarrierCode === "HDFC")[0])
        setGetQuoteICICI(resp.filter((obj) => obj.CarrierCode === "ICICI")[0]);
      } else if (resp.status === 401) {
        localStorage.removeItem('access_token');
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.message,
        }).then(() => {
          window.location.href = Global.base_url;
        })

      } else if (resp[0].error_details) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp[0].error_details.error_message,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: JSON.stringify(resp),
        })
      }

    });
    // return data;
  }

  const logo = (CarrierCode) => {
    switch (CarrierCode) {
      case 'HDFC':
        return 'images/logo/hdfc-life.png';
      case 'ICICI':
        return 'images/logo/iprotect-smart.png';
      default:
        return '';
    }
  }

  useEffect(() => {
    getQuoteNew()
    // eslint-disable-next-line
  }, [form_data, frequency])

  useEffect(() => {
    localStorage.setItem('form_data_term_plan', JSON.stringify({ ...form_data, frequency: frequency }));
    // eslint-disable-next-line
  }, [frequency]);


  useEffect(() => {
    localStorage.setItem('form_data_term_plan', JSON.stringify(form_data));
  }, [form_data]);


  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Insurance Advise</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Term Insurance</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <span>{"Compare Term Plans"}</span>
            <Link to={"/term-plan/quote"} className="bluebtn" style={{ position: 'absolute', right: '9px', color: 'white' }}><i className="fa fa-angle-left font13 mr5" aria-hidden="true"></i> Back</Link>
          </div>
          {loading ? (
            <Loader /> // Render the loader when loading is true
          ) :
            <div className="row mt20 life-insurance-details">
              <div className="col-md-6">

                <div className="card p20">

                  <div className="row">
                    <div className="col-md-12 life-lnsurance-logo-section">

                      <table className="">
                        <tr>
                          <td style={{ 'maxWidth': '150px' }}><img src={Global.base_url + '/' + logo(getQuoteNewData.CarrierCode)} alt="" className="logo maxWidth100" /></td>
                          <td style={{ 'paddingLeft': "10px" }}>
                            <h2 className="heading-1"> {getQuoteNewData && getQuoteNewData.CarrierProductName && getQuoteNewData.CarrierProductName}  </h2>
                            {
                              packages.sub_tags &&
                              packages.sub_tags.map((tag) => {
                                return <span className="tags mr10">{tag}</span>
                              })
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="border-hr" />

                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Features</h3>
                    <div className="row">

                      {getQuoteNewData.Features.map((item, index) => (

                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>

                      ))}

                    </div>
                  </div>
                </div>

                <div className="card p20 mt20">
                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Free Benefits</h3>
                    <div className="row">
                      {getQuoteNewData.FreeBenefits.map((item, index) => (
                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="card p20 mt20">
                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Paid Benefits</h3>
                    <div className="row">
                      {getQuoteNewData.PaidBenefits.map((item, index) => (
                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>


                <div className="mb50"></div>

              </div> {/** end of left section col-md-8 */}

              <div className="col-md-6">{/** start of right section col-md-4 */}

                <div className="card p20">

                  <div className="row">
                    <div className="col-md-12 life-lnsurance-logo-section">

                      <table className="">
                        <tr>
                          <td style={{ 'maxWidth': '150px' }}><img src={Global.base_url + '/' + logo(getQuoteICICI.CarrierCode)} alt="" className="logo maxWidth100" /></td>
                          <td style={{ 'paddingLeft': "10px" }}>
                            <h2 className="heading-1"> {getQuoteICICI && getQuoteICICI.CarrierProductName && getQuoteICICI.CarrierProductName}  </h2>
                            {
                              packages.sub_tags &&
                              packages.sub_tags.map((tag) => {
                                return <span className="tags mr10">{tag}</span>
                              })
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="border-hr" />


                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Features</h3>
                    <div className="row">

                      {getQuoteICICI.Features.map((item, index) => (

                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>

                      ))}

                    </div>

                  </div>


                </div>


                <div className="card p20 mt20">
                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Free Benefits</h3>
                    <div className="row">
                      {getQuoteICICI.FreeBenefits.map((item, index) => (
                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>


                <div className="card p20 mt20">
                  <div className="card-body-text extra-benefits">
                    <h3 className="heading-2 mt10 mb10">Paid Benefits</h3>
                    <div className="row">
                      {getQuoteICICI.PaidBenefits.map((item, index) => (
                        <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                          <h5 className="heading-3">{item.BenefitDescription}</h5>
                          <p className="text-type-1">
                            {item.Notes}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          } {/* end of loader */}
        </div>
      </div>


    </Fragment>
  );
}