import React from 'react'
import { BrowserRouter,  Route, Routes } from 'react-router-dom' 
import HealthPlan from './pages/HealthPlan'
import HealthPlanQuote from './pages/HealthPlanQuote'
import TermPlan from './pages/TermPlan'
import TermPlanQuote from './pages/TermPlanQuote'
import Login from './pages/Login'
import AdminDashboard from './pages/AdminDashboard'
import InsurancePlanAdmin from './pages/InsurancePlanAdmin'
import ComparePlans from './pages/ComparePlans'
import LandingPage from './pages/LandingPage'  
import LifeInsuranceDetails from './pages/LifeInsuranceDetails'
import LifeInsuranceDetailsCompare from './pages/LifeInsuranceDetailsCompare'
import HealthInsuranceDetails from './pages/HealthInsuranceDetails'
import LifeInsuranceBuy from './pages/life-insurance/LifeInsuranceBuy'
import ViewPlanDetails from './pages/life-insurance/ViewPlanDetails'
import PreProposalStage from './pages/health-insurance/PreProposalStage'
import ProposalState from './pages/life-insurance/ProposalStage'
import ProposalStateHealth from './pages/life-insurance/ProposalStageHealth'
import ProposalStateBuyHealth from './pages/health-insurance/ProposalStateBuyHealth'
import HealthByProduct from './pages/HealthByProduct'
import ThankYouPage from './pages/ThankYouPage'
import TermThankYouPage from './pages/TermThankYouPage'

export default function RouterPage() {
 
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <LandingPage / > } ></Route>
                    <Route path="/thank-you" element={ <ThankYouPage / > } ></Route>
                    
                    <Route path="/term-thank-you" element={ <TermThankYouPage / > } ></Route>

                    <Route path="/login" element={ <Login / > } ></Route>
                    <Route path="/admin" element={ <AdminDashboard / > } ></Route>
                    <Route path="/insurance-plan-admin" element={ <InsurancePlanAdmin / > } ></Route> 
                    <Route path="/health-plan" element={<HealthPlan />}></Route> 
                    <Route path="/health-plan/quote" element={<HealthPlanQuote />}></Route> 
    {/*<Route path="/health-insurance-details/health-plan/:paramsProductName" element={<HealthInsuranceDetails />}></Route>*/}
                    
                    <Route path="/health-insurance-details/health-plan/:paramsProductName" element={<HealthByProduct />}></Route>
                    <Route path="/health-insurance-details/:paramsProductName" element={<HealthInsuranceDetails />}></Route>  
                    
                    <Route path="/pre-proposal-stage/:productId" element={<PreProposalStage />}></Route> 
                    <Route path="/health-plan/:productId/proposal-stage" element={<ProposalStateBuyHealth />}></Route> 
                    <Route path="/proposal-stage-health" element={<ProposalStateHealth />}></Route> 


                    <Route path="/term-plan/quote" element={<TermPlanQuote />}></Route> 
                    <Route path="/term-plan" element={<TermPlan />}></Route> 
                    <Route path="/compare-plans" element={<ComparePlans />}></Route> 
                    {/** planType= health-plan | term-plan && productId = 1|2|3 ...   */}
                    <Route path="/life-insurance-details/term-plan/:CarrierCode" element={<LifeInsuranceDetails />}></Route>
                    <Route path="/term-plan/compare" element={<LifeInsuranceDetailsCompare />}></Route> 
                    
                    <Route path="/life-insurance-buy" element={<LifeInsuranceBuy />}></Route>
                    <Route path="/view-plan-details" element={<ViewPlanDetails />}></Route> 
                    <Route path="/proposal-stage" element={<ProposalState />}></Route> 
                    
                </Routes>
            </BrowserRouter>
        </div>
    )
}
