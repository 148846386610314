import formatAmount from "indian-currency-formatter";

 
const CardHealthPremiumDetails3 = (props)=>{
    const packages = props.packages;


    return (
      <div className={"card p20 " + (props.className && props.className)}>
                <div className="row">
                  <div className="col-md-12">
                    <table style={{'width':'100%'}}>
                        <tr>
                          <td style={{'width':'60%'}}>
                            <span className="text-type-1">Cover</span>
                            <br />
                            <span className="heading-3">₹ 50 Lakh</span>
                          </td>
                          <td>
                            <span className="text-type-1">Premium</span>
                            <br />
                            <span className="heading-3  color-primary">₹ 21,503 /Yearly</span>
                          </td>                        
                        </tr> 
                      </table> 
                    </div>
 
                  <div className="col-md-12"> 
                    <hr className="border-hr" />
                    <div className="">
                        <div className="font14 color212 fw600">Cover includes for</div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                        <li><label><input type="checkbox" name="members" value="self"/><span className="rdo_text_box padding_5-7">Self</span></label></li>   
                        <li><label><input type="checkbox" name="members" value="Spouse"/><span className="rdo_text_box padding_5-7">Spouse</span></label></li>   
                        <li><label><input type="checkbox" name="members" value="Son(2)"/><span className="rdo_text_box padding_5-7">Son(2)</span></label></li>   
                        <li><label><input type="checkbox" name="members" value="Daughter(1)"/><span className="rdo_text_box padding_5-7">Daughter(1)</span></label></li>   
                        </ul>   
                    </div>
                    <hr className="border-hr" />

                      <div className="row">
                        <div className="col-md-7 "><label className="text-type-4"> Base Amount </label> </div>
                        <div className="col-md-5 text-right"> <span> ₹ {formatAmount(packages.base_Amount)}</span>  </div>
                      </div>
                      <div className="row">
                        <div className="col-md-7"><label className="text-type-4"> GST (18%)</label> </div>
                        <div className="col-md-5 text-right"> <span>₹ {formatAmount( (packages.base_Amount*18)/100 ) } </span> </div>
                      </div>
                      <hr className="border-hr" />

                    <div className="total-to-pay-area">
                      <span>Total premium </span> <span className="ml30 bold">₹ { formatAmount ( packages.base_Amount + (packages.base_Amount*18)/100 ) } /Yearly </span>
                    </div>
                    <div className="text-center  mt5 mb20">
                      <span className="text-tax-saving make-circle">%</span>
                      <span className="text-tax-saving ml5">80c tax saving ₹2,728.00 per year</span></div>
                  </div>
                  
                </div>
              </div>
    )
}

export default CardHealthPremiumDetails3;