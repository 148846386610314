 
import { Row, Col } from "react-bootstrap";
import { useState } from 'react'
 
function AdditionalHealthDetails(props) {
    //eslint-disable-next-line
     const register = props.register;
     //eslint-disable-next-line
     const errors = props.errors;
//eslint-disable-next-line
    const [rows, setRows] = useState( [
            {
                "member_id": 1,
                "name": "",
                "relationship": "Self",
                "name_of_the_medical_problem": null,
                "area_of_the_body_affected": null,
                "symptoms": null,
                "treatment_start_date": null,
                "treatment_end_date": null,
                "current_status_of_the_medical_problem": null,
                "answer": "yes"
            },
            {
                "member_id": 2,
                "name": "",
                "relationship": "Spouse",
                "name_of_the_medical_problem": null,
                "area_of_the_body_affected": null,
                "symptoms": null,
                "treatment_start_date": null,
                "treatment_end_date": null,
                "current_status_of_the_medical_problem": null,
                "answer": "yes"
            }
        ]);
 
    const current_status = [
        "Ongoing", "Completed", "Recovery", "Recurrent or likely to recur"
    ]; 
    return (
        <>  
        <div className="proposer-details form-section">
            <Row>
                <Col md={12}>
                    {
                        rows.map((item, index) => { 
                            let input = "input[" + index + "]";
                            return (<div className="section-loop">
                                <input type="hidden" name={input + "[member_id]"} value={item.member_id} className="form-control"></input>
                                <h1 className="heading-1 mb10 text-center">{item.name}  {item.relationship} </h1>
                                <h2 className="heading-3 font16 color212 fw600">The name of the illness or medical problem. Where applicable, state the area of the body affected.</h2>
                                <div className="form-group">
                                    <label for={input + "[name_of_the_medical_problem]"} className="font14 color212 fw600 mt10">Name of the medical problem</label>
                                    <input placeholder="Name of the medical problem" type="text" name={input + "[name_of_the_medical_problem]"} className="form-control" defaultValue={item.name_of_the_medical_problem}></input>
                                </div>
                                <div className="form-group">
                                    <label  className="font14 color212 fw600 mt10">Area of the body affected</label>
                                    <input placeholder="Area of the body affected" type="text" className="form-control" name={input + "[area_of_the_body_affected]"} defaultValue={item.area_of_the_body_affected}></input>
                                </div>

                                <h2 className="heading-3 mt30 font16 color212 fw600">When did the symptoms occur and when did you last have symptoms?</h2>
                                <div className="form-group">                                   
                                    <label className="font14 color212 fw600 mt10">Symptoms</label>
                                    <textarea placeholder="Enter Symptoms" name={input + "[symptoms]"} type="text" className="form-control" defaultValue={item.symptoms}></textarea>
                                </div>

                                <h2 className="heading-3 mt30 font16 color212 fw600">What treatment was provided? (include details ofo medication and dates of when createment started and endded?)</h2>
                                <div className="form-row mb10 mt10">
                                    <div className="form-group col-md-6 pr20">
                                        <label  className="font14 color212 fw600">Treatment start date</label>
                                        <input type="date" name={input + "[treatment_start_date]"} defaultValue={item.treatment_start_date} className="form-control"></input>
                                    </div>
                                    <div className="form-group col-md-6 pl20">
                                        <label  className="font14 color212 fw600">Treatment end date</label>
                                        <input type="date" name={input + "[treatment_end_date]"} defaultValue={item.treatment_start_date} className="form-control" ></input>
                                    </div>
                                </div>

                                <h2 className="heading-3 mt30 font16 color212 fw600">What is the current status of the illness or medical problem?</h2>
                                <div className="form-group row col-md-8 mt10 mb30">
                                    <label  className="font14 color212 fw600">Current status of the medical problem</label>
                                    <select className="form-control" name={input + "[current_status_of_the_medical_problem]"} defaultValue={item.current_status_of_the_medical_problem}>
                                        <option value="">-- Select --</option>
                                        {
                                            current_status.map((val) => {
                                                return <option value={val}>{val}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <hr className="border-hr" />
                            </div>) 
                        })
                    } 
                </Col> 
            </Row>
        </div> 
        </>
    )
}

export default AdditionalHealthDetails;