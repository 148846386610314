import { React, Fragment, useState } from "react"; 
import InputMask from 'react-input-mask';

import { isValidDate } from "../../components/global";
import { ageCalculate } from "../../components/global";


export default function InsuredBasicForm(props) {
    const register = props.register;
    const errors = props.errors; 
    const setError = props.setError;
    //eslint-disable-next-line
    const [old, setOld] = useState( props.old ? props.old : {} );
    
    return (
        <Fragment>
            <SubForm member_type="Proposer" sub_title="Please provide additional details" errors={errors} register={register} old={old} setError={setError} id={1}/> 
            <SubForm member_type="Spouse" sub_title="Please provide spouse details" errors={errors} register={register} old={old} setError={setError} id={2}/> 
            <SubForm member_type="Kids" sub_title="Please provide Kids details" errors={errors} register={register} old={old} setError={setError} id={3}/> 
             <SubForm member_type="Kids" sub_title="Please provide Kids details" errors={errors} register={register} old={old} setError={setError} id={4}/>    
        </Fragment>
    )
}


const SubForm =(props)=>{

    const register = props.register;
    const errors = props.errors;
    const setError = props.setError;
    const old = props.old;
    const member_type = props.member_type; 
    const id = props.id;
 
    const prefix_       = `members[${id}]`;
    const full_name     = prefix_+`[full_name]`;
    const dobName       = prefix_+`[dob]`;
    const height_feets  = prefix_+`[height_feets]`;
    const height_inches = prefix_+`[height_inches]`;
    const weight        = prefix_+`[weight]`;
    const relationship  = prefix_+`[relationship]`;
    const member_type_input  = prefix_+`[member_type]`;
 
    const [calAge, setCalAge] = useState(old.dob && old.dob);

    const handleInputChange = (e) => { 
    const input_dob = e.target.value;
    if(isValidDate(input_dob)){
        setError(dobName, { message: false });  
        let age_ =  ageCalculate(input_dob); 
            setCalAge(age_);
        if(age_< 18 || age_> 60 ){
            setError(dobName, { message: 'Age must be between 18 to 59', type: 'required'}); 
        }else{
            setError(dobName, { message: false }); 
        }
    }else{ 
            if(!input_dob.includes('_')){
                setError(dobName, { message: 'Invalid Date', type: 'required'});  
            } 
            setCalAge("");
        } 
    };


    return ( 
    <> 
        <input type="hidden" name={member_type_input}  value={member_type}   {...register(member_type_input, { required:false } )}   />
        <div className="form-row mb10">
            <div className="font16 color212 fw600">
                {member_type}
            <br/> <span className="text-type-4">{props.sub_title}</span>
            </div> 
        </div>
        <div className="form-row mb10"> 
            <div className="form-group col-md-6 pr20">                                
                <div className="font14 color212 fw600 mb10 mr30">Your Full Name <span className="colorfa8">*</span></div>
                <input type="text" className="form-control" name={full_name} placeholder="Enter Your Full Name"  
                {...register(full_name, { required: 'Full Name field is required'} )}    /> 
                <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['full_name'] && errors.members[id]['full_name'].message}</small>
            </div>
            <div className="form-group col-md-6 pl20">                                
                <div className="font14 color212 fw600 mb10">Date Of Birth (dd/mm/yyyy) <span className="colorfa8">*</span></div>
                <InputMask type="text" 
                    {...register(dobName, { required: 'Date of Birth is required' } )}
                    className="form-control"  name={dobName}
                    placeholder="DD/MM/YYYY"
                    maskPlaceholder="DD/MM/YYYY"
                    mask="99/99/9999"
                    defaultValue={old.member_dob ? old.member_dob : ""} onChange={handleInputChange} />
                <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['dob'] && errors.members[id]['dob'].message}</small>
                <small className="form-text font12 c-green"><b>{ Number.isInteger(calAge) && 'Age: ' + calAge + ' Years'}</b></small>  
            </div>
        </div>
        <div className="form-row mb10"> 
            <div className="form-group col-md-3 pr20">                                
                <div className="font14 color212 fw600 mb10 mr30">Height<span className="colorfa8">*</span></div>
               <select name={height_feets}  className="form-control"
               {...register(height_feets, { required: 'Height is required'} )}  >
                <option value="">Select Feet</option>
                {Array.from({ length: 8 }, (_, index) => (  
                    <option  id={index} value={index+1}>{index+1} Feet</option>
                ))}  
               </select>
               <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['height_feets'] && errors.members[id]['height_feets'].message}</small>
            </div> 
            <div className="form-group col-md-3 pr20">                                
                <div className="font14 color212 fw600 mb10 mr30">-</div>
               <select name={height_inches}   className="form-control" 
               {...register(height_inches, { required: 'This field is required'} )} >
                <option value="">Select Inches</option>
                <option value="0">0 Inches</option>
                    {Array.from({ length: 11 }, (_, index) => (  
                        <option  id={index} value={index+1}>{index+1} Inches</option>
                    ))}  
               </select>
               <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['height_inches'] && errors.members[id]['height_inches'].message}</small>
            </div> 
            <div className="form-group col-md-6 pl20">                                
                <div className="font14 color212 fw600 mb10 mr30">Weight<span className="colorfa8">*</span></div>
               <select name={weight} className="form-control"
               {...register(weight, { required: 'Weight field is required'} )} >
                <option value="">Select Weight</option>
                {Array.from({ length: 100 }, (_, index) => (  
                    <option  id={index} value={index+1}>{index+1} kg</option>
                ))} 
               </select>
               <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['weight'] && errors.members[id]['weight'].message}</small>
            </div> 
        </div>
        {
            member_type === 'Kids' &&        
            <div className="form-row">                  
                <div className="form-group col-md-12">  
                <div className="font14 color212 fw600 mb10 mr30">Relationship<span className="colorfa8">*</span></div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                        {
                        ["Son","Daughter"].map(function(v){ return(
                            <li><label><input type="radio" name={relationship} value={v}  defaultChecked={old.city ? (old.city === v ? true  : false ): false}
                            {...register(relationship, { required: 'Relationship field is required'} )}   /><span className="rdo_text_box">{v}</span></label></li>
                        ) }) 
                        } 
                    </ul>
                    <small className="form-text font11 text-danger">{errors.members  && errors.members[id] && errors.members[id]['relationship'] && errors.members[id]['relationship'].message}</small>
                </div>
            </div>
        }
        <hr className="border-hr"/>
    </>
    )
}