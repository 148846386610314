
const params_care_health_load_quote = { 
    "InputData": {
        "CarrierProductName": "Care Supreme",
        "CarrierCode": "CARE",
        "AbacusId": "3352",
        "PolicyRiskList": [
            {
                "PEDTenure": "1",
                "HealthPolicyType": "Floater",
                "TotalCount": "1",
                "ChildCount": "0",
                "MaxInsuredAge": "24",
                "AgeGroup": [
                    {
                        "Age1": "29", 
                        "Age2": "",
                        "Age3": "",
                        "Age4": "",
                        "Age5": "",
                        "Age6": ""
                    }
                ],
                "SIInLacs": "15",
                "Tenure": "1",
                /* "NomineeName": "Vinay Thekdi",
                "NomineeDOB": "1990-08-11",
                "NomineeRelToInsured": "1",
                "CustomerName": "Mr X" */
            }
        ],
        "PolicyCustomerList": [
            {
                /* "ContactEmail": "Vig@gmail.com",
                "AddressLine1": "XYZ ABC",
                "AddressLine2": "0227867692",
                "City": "",
                "State": "",
                "TelephoneNumber": "", 
                "Pincode": "110043"*/
            }
        ],
        "QuestionList": [
            {
                "ProductElementCode": "HLTHQUESTIONNAIRE",
                "QuestionId": "1",
                "Answer": "Y"
            },
            {
                "ProductElementCode": "HLTHQUESTIONNAIRE",
                "QuestionId": "1",
                "SubQuestionId": "1",
                "Answer": "122022"
            }
        ]
    } 
};
 

export { params_care_health_load_quote };
