import { useState, useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import OTPInput from "otp-input-react";
import { FileUploader } from "react-drag-drop-files";
import SignatureCanvas from 'react-signature-canvas' // https://www.npmjs.com/package/react-signature-canvas
import './SignAndConfirm.css';

const fileTypes = ["JPG", "PNG", "GIF"];



function SignAndConfirm(props) {

    const [signatureType, setSignatureType] = useState('drop_signature');
    // eslint-disable-next-line
    const [signature_image_src, setSignatureImageSrc] = useState("");
    // eslint-disable-next-line
    const [document, setDocuments] = useState({});

    const getDocumentsFun = () => {

    }

    useEffect(() => {
        getDocumentsFun();
    }, []);

    // eslint-disable-next-line
    const [lgShow, setLgShow] = useState(false);
    const [OTP, setOTP] = useState("");
    const [form_send_otp, set_form_send_otp] = useState('show');
    const [form_otp_verification, set_form_otp_verification] = useState('hide');
    const [form_declension, set_form_declension] = useState('hide');
    const { register, handleSubmit, formState: { errors } } = useForm();

    const {
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 }
    } = useForm();


    const {
        register: register3,
        handleSubmit: handleSubmit3,
        formState: { errors: errors3 }
    } = useForm();

    const {
        handleSubmit: handleSubmit4
    } = useForm();


    const onSubmitFormSendOtp = data => {
        // eslint-disable-next-line
        const payload = {
            mobile: data.mobile
        }
    }

    const onSubmitFormOtpVerification = data => {
        // eslint-disable-next-line
        const payload = {
            mobile_otp: OTP
        }
    }

    const onSubmitFormDeclaration = data => {
        setLgShow(true);
    }

    const [signature_name, setSignatureName] = useState("");
    const handleChangeSignatureName = (event) => {
        setSignatureName(event.target.value);
    };

    const onSubmitFormSign = () => {
        let payload = {}

        if (signatureType === 'drop_signature') { // in case drow singnature via mouse pointer  
            payload = {
                signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") //signature_src
            }
        }
        if (signatureType === 'upload_signature') { // in case upload image file 
            payload = { signature: file }
        }
        if (signatureType === 'signature_name') {
            // eslint-disable-next-line
            payload = { signature_name: signature_name }
        }

    }


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const [file, setFile] = useState('');
    const handleChange = async (file) => {
        const base64 = await convertBase64(file);
        setFile(base64);
    }




    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const [signature_src, setSignatureSrc] = useState();
    const save = () => {
        const alp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setSignatureSrc(alp);
    };


    return (
        <>
            {document.signature || document.signature_name ?
                <div className="form-row mb10">
                    <Col md="6" className="center">
                        <br />
                        <div ><label className="ont14 color212 fw600 mb10">Signature Name: {document.signature_name} </label></div> <br />
                        <img src={signature_image_src} className="full-width border" alt="signature_image"></img>
                        <br />
                        <br />
                        <Link to="/insurance-application-status" className="btn btn-success mr-20">Finish</Link>
                        <Link onClick={() => setLgShow(true)} className="btn btn-primary">Edit</Link>
                    </Col>
                </div> :
                <>

                    <Form id="form_send_otp" className={form_send_otp} onSubmit={handleSubmit(onSubmitFormSendOtp)} >
                        <div className="form-row mb10">
                            <div className="col-md-6">
                                <div className="font14 color212 fw600 mb10">Enter your Phone Number for verification</div>
                                <input type="text" id="mobile" name="mobile" className="form-control disabled" value={localStorage.getItem('mobile')}
                                    {...register("mobile", { required: 'Mobile No is required' })} placeholder="Enter Mobile Number"
                                ></input>
                                <span className={errors.mobile ? 'required' : ''}>
                                    {errors.mobile && errors.mobile.message}
                                </span>
                            </div>


                        </div>
                        <div className="form-row mb10">
                            <div className="col-md-6 mt20">
                                <input type="button" onClick={() => { set_form_send_otp('hide'); set_form_otp_verification('show') }} className="bluebtn font14 fw600" value="Get OTP" />
                            </div>
                        </div>
                    </Form>
                    <Form id="form_otp_verification" className={form_otp_verification} onSubmit={handleSubmit2(onSubmitFormOtpVerification)} >
                        <div className="form-row mb10" id="mobile_otp_input_section">
                            <Row className="mb-8 mb-20 otp-section">
                                <Col md="12" className="mb-1">
                                    <div className="font14 color212 fw600 mb10">Enter OTP (+91 9876-543-210) <Link className="ml30" to={"#"}>Edit</Link></div>
                                </Col>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <OTPInput
                                        id="mobile_otp"
                                        name="mobile_otp"
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={5}
                                        otpType="number"
                                        disabled={false}
                                        secure
                                    //ref={register2({ required: true })}
                                    />
                                </Form.Group>
                                <span className={errors2.mobile_otp ? 'required' : ''}>
                                    {errors2.mobile_otp && errors2.mobile_otp.message}
                                </span>
                            </Row>
                            <div className="mt20 form-group">
                                <input type="button" onClick={() => { set_form_otp_verification('hide'); set_form_declension('show') }} className="bluebtn font14 fw600" value="Verify OTP" />
                            </div>
                        </div>
                    </Form>
                </>

            }
            <div className="col-md-12">
                <Form className={form_declension} onSubmit={handleSubmit4(onSubmitFormSign)} >

                    <label className="pointer font14 color212 fw600 mb10"><input defaultChecked={true} type="radio" name="signature_upload_type" onChange={() => setSignatureType('drop_signature')} /> Drow Your Signature</label> <br />
                    {
                        (signatureType === 'drop_signature' &&
                            <div className="row">
                                <div className="col-md-8">
                                    <div style={{ border: "dashed", color: "#1159a1" }}>
                                        <h1 className="text-center mt5">Drow Your Signature</h1>
                                        <SignatureCanvas penColor='black' ref={sigCanvas}
                                            canvasProps={{ width: 440, height: 280, className: 'sigCanvas' }} />
                                    </div>
                                </div>
                                <div className="col-md-4 center">
                                    <button type="button" onClick={clear} className="btn btn-sm btn-danger mr-20">Clear</button>
                                    <button type="button" onClick={save} className="btn btn-success btn-sm">Previous</button>
                                    {signature_src &&
                                        <p className="border p-10 mt20"> <img className="full-width" src={signature_src} alt=""></img> </p>
                                    }
                                </div>

                            </div>
                        )
                    }

                    <label className="pointer font14 color212 fw600 mb10 mt10"><input type="radio" name="signature_upload_type" onChange={() => setSignatureType('upload_signature')} /> OR  Upload Your Signature</label><br />
                    {
                        (signatureType === 'upload_signature' &&
                            <div className="form-group col-md-12 sign-and-confirm">
                                <input type="hidden" defaultValue={file} />
                                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            </div>
                        )
                    }

                    <label className="pointer font14 color212 fw600 mb10 mt10"><input type="radio" name="signature_upload_type" onChange={() => setSignatureType('signature_name')} /> OR Write your name here</label>
                    {
                        (signatureType === 'signature_name' &&
                            <div className="form-group col-md-4 mt-30">
                                <input id="signature_name" type="text" name="signature_name" className="form-control"
                                    onChange={handleChangeSignatureName}
                                ></input>
                            </div>
                        )
                    }
                </Form>
            </div>

            <br />
            <Form id="form_declension" className={form_declension} onSubmit={handleSubmit3(onSubmitFormDeclaration)} >
                <div className="form-group col-md-12" >
                    <label className="font14 color212 fw600 mb10"> <input type="checkbox" value="1" name="declare"  {...register3("declare", { required: false })} /> I hereby declare that all the information that I have shared with Finnovate Pvt. Ltd. is true and authentic. I have filled it with my utmost honesty and I have shared all the relevant information without hiding any kind of facts, that could hamper or change the clause of my policy. </label>
                    <span className={errors3.declare ? 'required' : ''}>
                        {errors3.declare && errors3.declare.message}
                    </span>
                </div>
                <div className="mt-10 form-group col-md-6">
                    <input type="button" className="bluebtn font14 fw600 " value="Sign & Continue" />
                </div>
            </Form>

        </>
    )
}



export default SignAndConfirm;