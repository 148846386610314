import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";

const EditPayFor = ({ getQuoteNewData, setFormData, form_data, setEditPayForFlag, setRiderSumInsured, setAddonCheckbox }) => {


    const { register, handleSubmit, formState: { errors }, setError } = useForm();


    const [old, setOld] = useState(form_data);

    const [premiumPaymentOption, setPremiumPaymentOption] = useState('');

    useEffect(() => {
        setFormData({ ...old }); // update value of previus component data

        if (getQuoteNewData.CarrierCode === "HDFC") {
            const generatedOptions = generateOptions(old);
            setPremiumPaymentOption(generatedOptions);
        } else {
            const generatedOptions = generatePremiumPaymentOptions(old);
            setPremiumPaymentOption(generatedOptions);
        }

        // eslint-disable-next-line
    }, [old]);

    const checkAddonValidity = () => {
        //console.log("getQuoteNewData ", getQuoteNewData);
        if(!getQuoteNewData.PolicyLobList[0].RiderDetails){
            return false;
        } 
        
        if (getQuoteNewData.CarrierCode === 'HDFC') {
            //console.log("line 32");
            const hasInvalidAddon = getQuoteNewData.PolicyLobList[0].RiderDetails.some(item => {
                console.log("item.SumInsured::: ", item.SumInsured, "item.Rider : ", item.Rider);
                return item.Rider === 'PPR_PAC' && parseInt(item.SumInsured) > 500000;
            });

           // console.log('hasInvalidAddon', hasInvalidAddon);
            return hasInvalidAddon;
        } else if (getQuoteNewData.CarrierCode === 'ICICI' && getQuoteNewData.PPT === 1) {
           // console.log("line 40");
            const hasInvalidAddon = getQuoteNewData.PolicyLobList[0].RiderDetails.some(item => {
                return item.Rider === 'CIBF' && parseInt(item.SumInsured) > 0; // Assuming CIBF is the correct value to check
            });
            return hasInvalidAddon;
        }
        return false;
    };


    const onSubmitData = (data, e) => {
 
       // console.log("form_data::", form_data," | checkAddonValidity",  checkAddonValidity(), " | PPT: ", data.cover_required_values);
        
        if(data.cover_required_values === "1"){
            setRiderSumInsured( parseInt(old.SumInsured)/20);  // update RiderSumInsured  IN case PPT = 1    
            setOld({ ...old, PPT: data.cover_required_values });
            setFormData({ ...old, PPT: data.cover_required_values }); // update value of previus component data
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                confirmButtonColor: '#009356',
                text: 'Plan Details Updated successfully.',
            }).then(() => {
                setEditPayForFlag(false)
            });
        }else  if (checkAddonValidity()) {
            setError('cover_required_values', {
                type: 'manual',
                message: getQuoteNewData.CarrierCode === "ICICI" ? 'Uncheck Accelerated Critical Illness (ACI) Benefit Rider for single pay' : 'PAC Rider Sum Insured should be 5Lakh Or Uncheck the Rider for Single Pay'
            });
        } else {
            setAddonCheckbox(true);
            setOld({ ...old, PPT: data.cover_required_values });
            setFormData({ ...old, PPT: data.cover_required_values }); // update value of previus component data
            setEditPayForFlag(false)

            /*Swal.fire({
                icon: 'success',
                title: 'Success!',
                confirmButtonColor: '#009356',
                text: 'Plan Details Updated successfully.',
            }).then(() => {
                setEditPayForFlag(false)
            });*/

        }


    }


    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
       
      



    const generateOptions = (old) => {
        console.log("option_loop : ", old);
        const policy_term = parseInt(old.cover_till) - parseInt(old.age);

        let premium_payment_option = '';

        premium_payment_option += '<option value="">I want to pay premium for</option>';
        premium_payment_option += '<optgroup>';
        premium_payment_option += `<option value="${policy_term}" terms="REGULAR">Pay for Entire Policy Term</option>`;
        premium_payment_option += '</optgroup>';

        premium_payment_option += '<optgroup label="Limited Pay">';
        


        for (let i = 5; i <= (policy_term - 2); i++) {
            premium_payment_option += `<option value="${i}" terms="LIMITED">Pay for ${i} years</option>`;
        }
        premium_payment_option += '</optgroup>';

        premium_payment_option += '<optgroup>';
        premium_payment_option += '<option value="1">Single Pay</option>';
        premium_payment_option += '</optgroup>';

        return premium_payment_option;
    };

    // eslint-disable-next-line
    const generatePremiumPaymentOptions = (old) => {
        
        let limitedpayArr = {};
        //let till_age_txt = '';
        //let payterm_text = '';
        const policy_term = parseInt(old.cover_till) - parseInt(old.age);
        const IPRU_MAX_CHECK_SUMMASSURED = 10000000;
        let age_sixty_check = parseInt(60) - parseInt(old.age);
        let limited_pay_check = policy_term - 5;
        let premium_payment_option = '';

        if (old.cover_till !== 99) {
            if (old.SumInsured >= IPRU_MAX_CHECK_SUMMASSURED) {
                limitedpayArr['5'] = "Limited Pay";
            }
            limitedpayArr['7'] = "Limited Pay";
            limitedpayArr['10'] = "Limited Pay";
            if (age_sixty_check < policy_term) {
                limitedpayArr[age_sixty_check] = "Limited Pay 60";
            }
            limitedpayArr[limited_pay_check] = "Limited Pay Term";
        } else {
            limitedpayArr['10'] = "Limited Whole life 10";
            if (age_sixty_check < policy_term) {
                limitedpayArr[age_sixty_check] = "Limited Whole life";
            }
        }

        premium_payment_option += '<option value="">I want to pay premium for</option>';
        premium_payment_option += '<optgroup>';
        if (old.cover_till !== 99) {
            premium_payment_option += `<option value="${policy_term}" terms="Regular Pay">Pay for Entire Policy Term</option>`;
        } else {
            premium_payment_option += `<option value="${policy_term}" terms="Regular Pay">Pay for Entire Policy Term</option>`;
        }
        premium_payment_option += '</optgroup>';

        // ============OLD ccode ====
       /*  if (limited_pay_check >= 5) {
            premium_payment_option += '<optgroup label="Limited Pay">';
            Object.entries(limitedpayArr).forEach(([index, value]) => {
                till_age_txt = (value === "Limited Pay 60") ? "( Till age 60 )" : "";
                payterm_text = (value === "Limited Pay Term") ? "( Term - 5 Years)" : "";
                premium_payment_option += `<option value="${index}" terms="${value}">Pay for ${index} years ${till_age_txt}${payterm_text}</option>`;
            });
            premium_payment_option += '</optgroup>';
        } */

        //=====New code ===
        console.log('policy_term', policy_term);

            let option_loop = [];
            if(policy_term >= 10 && policy_term < 15){
                option_loop = [5,7,(policy_term-5)];

            }else if(policy_term >=15  && policy_term < 21){
                option_loop = [5,7,10,(policy_term-5)];

            }else if(policy_term >=21){
                option_loop = [5,7,10, 15,(policy_term-5)];

            }else{

            } 
            
            if(old.age<=50 && old.cover_till > 60){
                option_loop.push( 60 - old.age);
            }
            option_loop = option_loop.filter(onlyUnique);  
            //alert("old.age " + old.age );
            if(old.age !== 99){  
                premium_payment_option += '<optgroup label="Limited Pay">';
                // eslint-disable-next-line
                option_loop.map((v,i) =>{ 
                    if(v !== 0){
                        premium_payment_option += `<option value="${v}" terms="LIMITED">Pay for ${v} years</option>`; 
                    }  
                });
                premium_payment_option += '</optgroup>';
            }
            
        //================

        if (old.cover_till !== 99) {
            if (policy_term <= 20) {
                premium_payment_option += '<optgroup>';
                premium_payment_option += '<option value="1">Single Pay</option>';
                premium_payment_option += '</optgroup>';
            }
        }

        return premium_payment_option;
    }


    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => setEditPayForFlag(false)}> Back </button>
                    </div>
                    <div className="font14 fw500 color182">Update Plan Details</div>
                    <div>&nbsp;</div>
                </div>
                <form id="form_edit_address" onSubmit={handleSubmit(onSubmitData)}>
                    <div className="pl20 pr20">
                        <div className="mt20">



                            <div className="targetbar_outer_   mt50">
                                <div className="targetbar_title">
                                    <div className="font14 fw500 color182">Pay For <span className="colorfa8">*</span></div>
                                </div>
                                <div className="targetbar mt10">
                                    <form className="target_range_bar">
                                        <div className="form-group col-md-12">


                                            <select className="custom-select font14 color212 fw500" ref={register} name="cover_required_values"  {...register("cover_required_values", { required: 'Cover required values is required' })} dangerouslySetInnerHTML={{ __html: premiumPaymentOption }} />


                                            <small className="form-text font11 text-danger">{errors.cover_required_values && errors.cover_required_values.message}</small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="saveBtn backsaveBtn">
                            <button type="button" className="btn btn-primary" onClick={() => setEditPayForFlag(false)}> Close </button>
                            <button type="submit" className="btn btn-primary"> Save </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPayFor;