import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import { get_pin_code } from "../../components/global";


const EditAddress = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm();


  const [old, setOld] = useState(
    localStorage.getItem(props.session_name) ? JSON.parse(localStorage.getItem(props.session_name), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {}
  );

  useEffect(() => {
    localStorage.setItem(props.session_name, JSON.stringify(old));
    // eslint-disable-next-line
  }, [old]);

  const [pinCode, setPinCode] = useState(old.pin ? old.pin : '');
  const [city, setCity] = useState('');
  const [pin_required, setPinRequired] = useState(old.city ? false : "PIN Code is required OR Select City name from given below list");
  const EnterPin = (event) => {
    const { value } = event.target;
    setPinCode(value);
    if (value) {
      setPinRequired("PIN Code is required OR Select City name from given below list");
      document.querySelectorAll('.city_list').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }
  const EnterCity = (event) => {
    const { checked, value } = event.target;
    setCity(value);
    if (checked) {
      setPinCode("");
      setPinRequired(false);
      if (errors.pin) {
        errors.pin = false;
      }
    }
  }


  const onSubmitData = data => {

    setOld({ ...old, pin: pinCode, city: city });
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  useEffect(() => {
    if (city.length > 2) {
      setPinCode(get_pin_code(city));
    }
  }, [city])

  useEffect(() => {
    if (pinCode.length > 4) {
      setCity("");
    }
  }, [pinCode])


  return (
    <div className="rightSide_menu_outer">
      <div className="rightSide_menu">
        <div className="rightSide_menu_header">
          <div>
            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => props.setEditAddressFlag(false)}> Back </button>
          </div>
          <div className="font14 fw500 color182">Update Address</div>
          <div>&nbsp;</div>
        </div>
        <form id="form_edit_address" onSubmit={handleSubmit(onSubmitData)}>
          <div className="pl20 pr20">
            <div className="mt20">
              <div className="font16 fw600 color182">
                <div className="form_prnt">
                  <div className="custome_form pb30">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <div className="font14 color212 fw600 mb10">PIN Code <span className="colorfa8">*</span></div>
                        <input type="number" className="form-control" id="pin" name="pin" autoComplete='off' placeholder="PIN Code"
                          {...register("pin", {
                            required: pin_required,
                            pattern: {
                              value: /^[0-9]{6}$/,
                              message: 'PIN Code must be 6 digits numeric',
                            },
                          })}
                          defaultValue={old.pin && old.pin}
                          onChange={EnterPin} value={pinCode} />
                        <small className="form-text font11 text-danger">{errors.pin && errors.pin.message}</small>
                      </div>
                    </div>
                    <div className="text-center col-md-12"> OR </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <ul className="steps_tag font14 fw500 color182 mt15">
                          {
                            [
                              '411012',
                              '411015',
                              '411020',
                              '422006',
                              '422102',
                              '422011', '500008', "Pune", "Bengaluru", "Hyderabad", "Mumbai", "Thane", "Delhi"].map(function (v) {
                                return (
                                  <li><label><input className="city_list" type="radio" name="city" value={v} onClick={EnterCity}
                                    defaultChecked={old.city ? (old.city === v ? true : false) : false}
                                    {...register("city", { required: false })}
                                  /><span className="rdo_text_box">{v}</span></label></li>
                                )
                              })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="alert alert-info-new">
                    <i className="fa-solid fa-location-dot"></i> Some insurance companies do not provide coverage in few pin codes.
                  </div>
                </div>
              </div>
            </div>
            <div className="saveBtn backsaveBtn">
              <button type="button" className="btn btn-primary" onClick={() => props.setEditAddressFlag(false)}> Close </button>
              <button type="submit" className="btn btn-primary"> Save </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAddress;