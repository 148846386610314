import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/layout/DashboardHeader";  
import HealthInsurance from "./subpages/HealthInsurance";
import LifeInsurances from "./subpages/LifeInsurances";
import { Link } from "react-router-dom";

export default function InsurancePlanAdmin() {
  
  const [active , setActive] = useState(1);

  useEffect(() => {
    document.body.classList.add('white_bg_fp_report');

    return function cleanup() {
        document.body.classList.remove('white_bg_fp_report');
    };
}, []);

  return (
    <>
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="planSection_wpr row">     
          <div className="col-3 leftNav pt55 font14 fw500 color7B8">
            <div className="nav flex-column nav-pills" id="v-pills-tab"  role="tablist" aria-orientation="vertical">  
                <Link className={"nav-link "+ ( active === 1 && ' active ' )} onClick={()=>setActive(1)}> <span className="healthAnalysisIcon mr10" /> Life Insurances</Link>
                <Link className={"nav-link "+ ( active === 2 && ' active ' )} onClick={()=>setActive(2)}> <span className="healthAnalysisIcon mr10" /> Health Insurance </Link>  
            </div>
          </div>
            <div className="col-9 righttabContent pl90 pt25">
              <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active"> 
                  { active === 1 && <HealthInsurance /> }   
                  { active === 2 && <LifeInsurances /> }    
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </>
  );
}
