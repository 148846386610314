import { React, Fragment, useState } from "react";
import { isValidDate } from "../../components/global";
import { ageCalculate } from "../../components/global";
import { Link } from "react-router-dom";

export default function ProposerDetailsForm(props) {

    const register = props.register;
    const errors = props.errors;
    const setError = props.setError;
    //eslint-disable-next-line
    const [old, setOld] = useState({ dob: '' });
    //eslint-disable-next-line
    const [calAge, setCalAge] = useState(old.dob && old.dob);
    //eslint-disable-next-line
    const handleInputChange = (e) => {
        const input_dob = e.target.value;

        if (isValidDate(input_dob)) {
            setError('dob', { message: false });
            let age_ = ageCalculate(input_dob);
            setCalAge(age_);
            if (age_ < 18 || age_ > 60) {
                setError('dob', { message: 'Age must be between 18 to 59', type: 'required' });
            } else {
                setError('dob', { message: false });
            }
        } else {
            if (!input_dob.includes('_')) {
                setError('dob', { message: 'Invalid Date', type: 'required' });
            }
            setCalAge("");
        }
    };

    const [permanent_address, setPermanentAddress] = useState();

    const [isChecked, setIsChecked] = useState(false);
    //eslint-disable-next-line
    const sameAsAbove = () => {
        if (isChecked) {
            setIsChecked(false); // If the checkbox is already checked, uncheck it
        } else {
            setIsChecked(true); // If the checkbox is unchecked, check it
            let add = document.getElementById('correspondence_address').value;
            setPermanentAddress(add)
        }
    };
    //eslint-disable-next-line
    const handleInputChangePermanentAddress = (e) => {
        setPermanentAddress(isChecked ? permanent_address : e.target.value);
    }

    const handleInputChangeCorrespondenceAddress = (e) => {
        setPermanentAddress(isChecked ? e.target.value : permanent_address);
    }

    return (
        <Fragment>

            <div className="form-row mb10">
                <div className="form-group col-md-6 pr20">
                    <div className="font14 color212 fw600 mb10">Your Full Name <span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" id="full_name" name="full_name" placeholder="Enter Your Full Name"
                        {...register("full_name", { required: 'Full Name field is required' })} />
                    <small className="form-text font11 text-danger">{errors.full_name && errors.full_name.message}</small>
                </div>

                <div className="form-group col-md-6 pl20">
                    <div className="font14 color212 fw600 mb10">Select Gender <span className="colorfa8">*</span></div>
                    <select className="form-control" name="gender" {...register("gender", { required: 'Gender field is required' })} >
                        <option value="">-- Select --</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                    <small className="form-text font11 text-danger">{errors.gender && errors.gender.message}</small>

                </div>
            </div>

            <div className="form-row mb10">
                <div className="form-group col-md-6 pr20">
                    <div className="font14 color212 fw600 mb10">PAN number <span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" id="pan" name="pan" placeholder="Enter Father's name"
                        {...register("pan", { required: 'PAN Number field is required' })} />
                    <small className="form-text font11 text-danger">{errors.pan && errors.pan.message}</small>
                </div>
                <div className="form-group col-md-6 pl20 pt20">
                    <Link to="#" className="btn btn-primary bluebtn p25"> Verify KYC <span className="ml5"> > </span> </Link>
                </div>
            </div>

            <div className="form-row mb10">
                <div className="form-group col-md-6 pr20">
                    <div className="font14 color212 fw600 mb10">Email <span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" id="email " name="email" placeholder="Enter Email"
                        {...register("email", { required: 'Email field is required' })} defaultValue="" />
                    <small className="form-text font11 text-danger">{errors.email && errors.email.message}</small>
                </div>
                <div className="form-group col-md-6 pl20">
                    <div className="font14 color212 fw600 mb10 mr30">Mobile Number <span className="colorfa8">*</span></div>
                    <span className="mobile-prifix-code"> +91 </span>
                    <input type="text" className="form-control pl45" id="mobile" name="mobile" placeholder="Enter Mobile Number"
                        {...register("mobile", { required: 'Mobile Number field is required' })} />
                    <small className="form-text font11 text-danger">{errors.mobile && errors.mobile.message}</small>
                </div>
            </div>

            <div className="form-row mb10">
                <div className="form-group col-md-12">
                    <div className="font14 color212 fw600 mb10 mr30">Correspondence Address <span className="colorfa8">*</span><span className="ml10 text-type-3">include city, state, PIN code</span></div>
                    <input type="text" className="form-control" id="correspondence_address" name="correspondence_address" placeholder="Enter Full Correspondence Address"
                        {...register("correspondence_address", { required: 'Correspondence Address field is required' })} onChange={handleInputChangeCorrespondenceAddress} />
                    <small className="form-text font11 text-danger">{errors.correspondence_address && errors.correspondence_address.message}</small>
                </div>
            </div>
            <div className="form-row mb10">
                <div className="form-group col-md-4 pr20">
                    <input type="text" className="form-control" id="pin" name="pin" placeholder="Enter Pin Code"
                        {...register("pin", { required: 'Full Name field is required' })} />
                    <small className="form-text font11 text-danger">{errors.pin && errors.pin.message}</small>
                </div>
                <div className="form-group col-md-4 pr20">
                    <input type="text" className="form-control" id="city" name="city" placeholder="City Name"
                        {...register("city", { required: 'Full Name field is required' })} />
                    <small className="form-text font11 text-danger">{errors.city && errors.city.message}</small>
                </div>
                <div className="form-group col-md-4 pr20">
                    <input type="text" className="form-control" id="state" name="state" placeholder="State"
                        {...register("state", { required: 'Full Name field is required' })} />
                    <small className="form-text font11 text-danger">{errors.state && errors.state.message}</small>
                </div>
            </div>
        </Fragment>
    )
}