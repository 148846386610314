import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form' 


export default function LoginFirst() {

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {
        localStorage.setItem('login_details', JSON.stringify(data));
        window.location.href = '/admin';    

    } 
    return (
        <Fragment> 
            <div className="logincard"> 
                <div className="logincard_title pt30 pb30 pl35 pr35">
                    <div className="font18 color212 fw600">Login your account</div>
                    <div className="font13 fw500 color626">Join us and set your path towards Financial Freedom</div>
                </div>
                <div className="p35"> 
                    <div className="googlebtn font15 fw600 color222" style={{opacity:1}}>
                        <span className="gmailicon mr10" /> <span style={{opacity:1}} className='googleOpacity'>Continue with Google</span>
                    </div>
                    <div className="orline mt40 mb40">
                        <span className="font13 color7b8 fw600">OR</span>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=''>
                            <div className="font14 fw600 color212">Email Address <span className="colorfa8">*</span></div>
                            <div className="mt12 mobilefield">
                                <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                            </div>
                            <span className="text-danger font11"> {errors.emailAddress && errors.emailAddress.message}</span>
                        </div>

                        <div className='mt20'>
                            <div className="font14 fw600 color212">Password <span className="colorfa8">*</span></div>
                            <div className="mt12 mobilefield">
                                <input type="password" id="password" name="password" autoComplete='off' {...register("password", { required: 'Password is required'})} placeholder="Enter Password" />
                            </div>
                            <span className="text-danger font11"> {errors.password && errors.password.message}</span>
                        </div> 
                        <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary btn-lg btn-block font13 fw500 mt30 continuebtn">Login</button>
                    </form>
                    <div className="signuptext mt30 font14 color263 fw500">Don't have an account?  <span className="color06d ml5 fw600 pointer">Sign up</span></div>

                    <div className="logintnctext font12 fw500 color485 mt35">
                        By Login an account means you’re okay with our<span className="color06d">Terms of Service, Privacy Policy,</span> and our default <span className="color06d">Notification Settings.</span>
                    </div>

                </div>

            </div>
        </Fragment>
    )
}
