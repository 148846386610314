
 const CignaRiderAndAddonList =  [
    {
        "ProductName": "ManipalCigna ProHealth Prime Active",        
        "RiderDetailsList": [
            /* {
                "Rider": "",
                "ProductElementCode": "RIDERDETAILS"
                
            }  */
        ],
        "AddonDetailsList": [
            {
                "Addon": "C0001499",
                "ProductElementCode": "ADDONDETAILS"
            },  
            {
                "Addon": "C0002025",
                "ProductElementCode": "ADDONDETAILS"
            },  
            {
                "Addon": "C0002027",
                "ProductElementCode": "ADDONDETAILS"
            },  
            {
                "Addon": "C0002024",
                "ProductElementCode": "ADDONDETAILS"
            },  
            {
                "Addon": "C0002026",
                "ProductElementCode": "ADDONDETAILS"
            },   
            {
                "Addon": "C0002021",
                "ProductElementCode": "ADDONDETAILS"
            },  
        ]  
    },
    {
        "ProductName": "ManipalCigna ProHealth Prime Advantage",        
        "RiderDetailsList": [
            {
                "Rider": "C0001491",
                "ProductElementCode": "RIDERDETAILS"
                
            },  
            {
                "Rider": "C00001",
                "ProductElementCode": "RIDERDETAILS"
            }
        ],
        "AddonDetailsList": [
            {
                "Addon": "C0002019",
                "ProductElementCode": "Addon"
            }, 
            /* 
            {
                "Addon": "PC0002020",
                "ProductElementCode": "Addon"
            },  */
            {
                "Addon": "C0001523",
                "ProductElementCode": "Addon",
                "SumInsured": "3"
            }, 
            {
                "Addon": "C0000609",
                "ProductElementCode": "Addon"
            },/* upper */
            {
                "Addon": "AC0002023",
                "ProductElementCode": "Addon"
            },
            {
                "Addon": "AC0002021",
                "ProductElementCode": "Addon"
            } 
        ]  
    },
    {
        "ProductName": "ManipalCigna ProHealth Prime Protect",
        "RiderDetailsList": [
            {
                "Rider": "PC0001491",
                "ProductElementCode": "RIDERDETAILS"
            },  
            {
                "Rider": "PC00001",
                "ProductElementCode": "RIDERDETAILS"
            }
        ]
        
       , "AddonDetailsList": [
            {
                "Addon": "C0002017",
                "ProductElementCode": "Addon"
            },  
            { 
                "Addon": "C0002018",
                "ProductElementCode": "Addon"
            },  
            {
                "Addon": "PC0002020",
                "ProductElementCode": "Addon"
            }
            ,{
                "Addon": "C0002022",
                "ProductElementCode": "Addon"
            }  
            ,
            {
                "Addon": "PC0000609",
                "ProductElementCode": "Addon"
            },
            {
                "Addon": "PC0002023",
                "ProductElementCode": "Addon"
            },
            {
                "Addon": "PC0002021",
                "ProductElementCode": "Addon"
            } 
        ]
    }
]

export { CignaRiderAndAddonList }