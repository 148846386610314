import React, { Fragment, useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import "../css/header.css";
import "../css/style.css";
import "../css/dashboard.css";
import "../css/plan.css";
import "./Home.css";
import SideImg from "../img/BasicProfile.png";
import Disclaimer from "../components/Disclaimer";
import { educationMaster, occupationMaster, ageCalculate } from '../components/global'
import Header from '../components/layout/Header'
import Title from "../Title";
import formatAmount from "indian-currency-formatter";
import { isValidDate, get_pin_code } from "../components/global";
import InputMask from 'react-input-mask';

export default function TermPlan() {


  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState('Term Plan');

  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  // eslint-disable-next-line
  const [old, setOld] = useState(
    localStorage.getItem('form_data_term_plan') ? JSON.parse(localStorage.getItem('form_data_term_plan'), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {}
  );

  const [form_data, setFormData] = useState({
    gender: '',
    date_of_birth: '',
    smoked_status: '',
    pin: '',
    annual_income: 0,
    monthly_expenses: 0,
    full_name: '',
    any_existing_illness: []
  });

  const [step, setStep] = useState(1);
  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };


  const [form_title, setFormTitle] = useState("");
  const [form_subtitle, setFormSubTitle] = useState("");

  useEffect(function () {
    if (step === 1) {
      setFormTitle('Tell us about you');
      setFormSubTitle('We need to know a tittle bit about you to recommend a good term plan.');
    }
    if (step === 2) {
      setFormTitle('Tell us about you');
      setFormSubTitle('We need to know a tittle bit about you to recommend a good term plan.');
    }
    if (step === 3) {
      setFormTitle('Tell us about you');
      setFormSubTitle('We need to know a tittle bit about you to recommend a good term plan.');
    }
    if (step === 5) {
      // localStorage.setItem('member_options_data', JSON.stringify(form_data)); 
      window.location.href = '/term-plan/quote';
    }

  }, [step, form_data]);


  // Event handler to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    // setIsChecked(checked);
    // setCheckboxValue(value);
    if (checked && value === 'None of these') {

      const activeCheckboxes = document.querySelectorAll('.illness');
      // Loop through the found checkboxes and set them as checked
      activeCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

    }
    if (checked && value !== 'None of these') {
      const activeCheckboxes = document.querySelectorAll('.no_illness');
      // Loop through the found checkboxes and set them as checked
      activeCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  };

  const [pin, setPin] = useState(old.pin ? old.pin : "");
  const [pin_required, setPinRequired] = useState(old.city ? false : "PIN Code is required OR Select City name from given below list");
  const EnterPin = (event) => {
    const { value } = event.target;
    setPin(value);
    if (value) {
      setPinRequired("PIN Code is required OR Select City name from given below list");
      document.querySelectorAll('.city_list').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  let pin_ = [];
  pin_['Mumbai'] = 400069;
  pin_['Pune'] = 411045;
  pin_['Bengaluru'] = 560004;
  pin_['Thane'] = 400601;
  pin_['Hyderabad'] = 500004;
  pin_['Delhi'] = 110004;

  const EnterCity = (event) => {
    const { checked, value } = event.target;
    setCity(value);
    if (checked) {
      setPin(get_pin_code(city));
      setPinRequired(false);
      if (errors.pin) {
        errors.pin = false;
      }
    }
  }
  const [city, setCity] = useState("");

  useEffect(() => {
    if (city.length > 2) {
      setPin(get_pin_code(city));
    }
  }, [city])

  // submit form data  
  const onSubmit = data => {

    if (step >= 4) {
      const i = data.annual_income;
      const e = data.monthly_expenses;

      const sumInsuredVal = calculatePremium(i.replace(/,/g, ''))

      data = {
        ...data,
        monthly_expenses: e.replace(/,/g, ''),
        annual_income: i.replace(/,/g, ''),
        age: calAge,
        SumInsured: sumInsuredVal,
        pin: pin,
      }
      setTimeout(() => {
        localStorage.setItem('form_data_term_plan', JSON.stringify(data));
        setFormData(JSON.parse(localStorage.getItem('form_data_term_plan')));
      }, 10);
    }

    handleNext();
  }


  //const qualification_list = EducationMaster; //["10th", "12th", "Graduation", "Post-Graduation"];
  // eslint-disable-next-line
  const occupation_list = ["Salaried", "Self Employed"];

  const [calAge, setCalAge] = useState(old.dob && ageCalculate(old.dob));

  const validateAge = (dob) => {
    const age = ageCalculate(dob);
    if (isValidDate(dob)) {
      setCalAge(age);
    } else {
      setCalAge(age);
    }

    return age >= 18 && age <= 65 ? true : "Age must be between 18 and 65";
  };


  const handleDOBChange = (event) => {
    const value = event.target.value;
    validateAge(value);
  };


  /*
  const handleInputChange = (e) => {
    const input_dob = e.target.value;
    if (isValidDate(input_dob)) {
      setError('dob', { message: false });
      let age_ = ageCalculate(input_dob);
      setCalAge(age_);
      if (age_ < 18 || age_ > 65) {
        setError('dob', { message: 'Age must be between 18 to 65', type: 'required' });
        return;
      } else {
        setError('dob', { message: false });
      }
    } else {
      if (!input_dob.includes('_')) {
        setError('dob', { message: 'Invalid Date', type: 'required' });
      }
      setCalAge("");
    }
  };*/
  /* 
  useEffect(()=>{
    if(isValidDate(old.dob)){
      let age_1 =  ageCalculate(old.dob); 
      setCalAge(age_1)
    }
  }, [old]) */

  const [annual_income, setAnnualIncome] = useState(old.annual_income && formatAmount(old.annual_income));
  const [monthly_expenses, setMonthlyExpenses] = useState(old.monthly_expenses && formatAmount(old.monthly_expenses))

  const ChangeAnnualIncomeHandle = (e) => {
    setAnnualIncome(formatAmount((e.target.value).replace(/,/g, '')));

    let _AnnualIncome = parseInt((e.target.value).replace(/,/g, ''));
    if (monthly_expenses && annual_income) {
      let _MonthlyExpenses = parseInt(monthly_expenses.replace(/,/g, '')) * 12;
      if (_MonthlyExpenses > _AnnualIncome) {
        setError('monthly_expenses', { type: 'manual', /*  type: 'custom', */  message: 'Annual income CAN NOT be less than 12 * monthly  expense' });
      } else {
        setError('monthly_expenses', { message: false });
      }
    }
  }

  const calculatePremium = (income) => {
    // Assuming income is provided in annual terms
    const tenTimesIncome = parseFloat(income) * 10;
    const roundedPremium = Math.ceil(tenTimesIncome / 100000) * 100000; // Round to nearest lakh
    return roundedPremium;
  };

  const ChangeMonthlyExpensesHandle = (e) => {
    setMonthlyExpenses(formatAmount((e.target.value).replace(/,/g, '')));

    let _MonthlyExpenses = parseInt((e.target.value).replace(/,/g, '')) * 12;
    if (_MonthlyExpenses && annual_income) {
      let _AnnualIncome = parseInt(annual_income.replace(/,/g, ''));
      if (_MonthlyExpenses > _AnnualIncome) {
        setError('monthly_expenses', { type: 'manual', /*  type: 'custom', */  message: 'Annual income CAN NOT be less than 12 * monthly  expense' });
      } else {
        setError('monthly_expenses', { message: false });
      }
    }

  }

  return (
    <Fragment>
      <Title title={pageTitle} />
      <Header steps={1} />

      <div className="innercontainer mb40">
        <div className="mainwrapper">

          <div className="row formWpr mt25">
            <div className="col-lg-8 formouter">

              <ul className="form_swipe_tab font12 fw600">
                {Array.from({ length: 4 }, (_, index) => (
                  <li key={index} onClick={() => { setStep(index + 1) }} className={step === (index + 1) && 'act'}>{index + 1}</li>
                ))}
              </ul>
              <form id="form_1" onSubmit={handleSubmit(onSubmit)} >
                <div className="formtitle flex_center justify_center">
                  <div>
                    <div className="font18 fw600">{form_title}</div>
                    <div className="font15 color495 ">{form_subtitle}</div>
                  </div>
                  <div>
                    {
                      step === 4 ? <>
                        <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Save</button>
                      </> : <>
                        <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Next
                          <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                          </svg></span>
                        </button>
                      </>
                    }
                  </div>
                </div>

                <div className="div">
                  {step === 1 && (
                    <>
                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">

                        <div className="form-row mb20">
                            <div className="form-group col-md-6 pr20">
                              <div className="font14 color212 fw600 mb10">Full Name <span className="colorfa8">*</span></div>
                              <div className="input-group selectarrow__">
                                <input type="text" className="form-control" id="full_name" name="full_name" placeholder="Full Name"
                                  {...register("full_name", { required: 'Full Name field is required' })} defaultValue={old.full_name && old.full_name}
                                />
                              </div>
                              <small className="form-text font11 text-danger">{errors.full_name && errors.full_name.message}</small>
                            </div>
                          </div>
                          
                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Gender <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'M' ? true : false} name="gender" value="M" onClick={() => { setFormData({ ...form_data, gender: 'M' }) }} /><span className="rdo_text_box">Male</span></label></li>
                                <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'F' ? true : false} name="gender" value="F" onClick={() => { setFormData({ ...form_data, gender: 'F' }) }} /><span className="rdo_text_box">Female</span></label></li>
                                <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'others' ? true : false} name="gender" value="others" onClick={() => { setFormData({ ...form_data, gender: 'others' }) }} /><span className="rdo_text_box">Others</span></label></li>
                              </ul>
                              <small className="form-text font11 text-danger">{errors.gender && errors.gender.message}</small>
                            </div>
                          </div>



                          <div className="form-row mb20">
                            <div className="form-group col-md-6 pr20">
                              <div className="font14 color212 fw600 mb10">Date Of Birth (DD/MM/YYYY) <span className="colorfa8">*</span></div>
                              <InputMask type="text"
                                {...register("dob", { required: 'Date of Birth is required', validate: validateAge })}
                                onChange={handleDOBChange}
                                className="form-control" name="dob"
                                placeholder="DD/MM/YYYY"
                                maskPlaceholder="DD/MM/YYYY"
                                mask="99/99/9999"
                                defaultValue={old.dob ? old.dob : ""}
                              />
                              <small className="form-text font11 text-danger">{errors.dob && errors.dob.message}</small>
                              <small className="form-text font12 c-green"><b>{Number.isInteger(calAge) && 'Age: ' + calAge + ' Years'}</b></small>
                            </div>
                          </div>


                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Have You Smoked Or Chewed Tobacco In Last 12 Months ?  <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                <li><label><input type="radio"  {...register("smoked_status", { required: 'Smoked status is required' })} name="smoked_status" value="N" onClick={() => { setFormData({ ...form_data, smoked_status: 'N' }) }} defaultChecked={old.smoked_status && old.smoked_status === 'N' ? true : false} /><span className="rdo_text_box">No</span></label></li>
                                <li><label><input type="radio"  {...register("smoked_status", { required: 'Smoked status is required' })} name="smoked_status" value="Y" onClick={() => { setFormData({ ...form_data, smoked_status: 'Y' }) }} defaultChecked={old.smoked_status && old.smoked_status === 'Y' ? true : false} /><span className="rdo_text_box">Yes</span></label></li>
                              </ul>
                              <small className="form-text font11 text-danger">{errors.smoked_status && errors.smoked_status.message}</small>
                            </div>
                          </div>

                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 alert alert-info-new">
                                <i className="fa fa-info-circle" aria-hidden="true"></i>  Insurance premiums for smokers are typically higher than those for non-smokers. But the correct declaration is essential.
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">

                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Highest Education <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                {
                                  educationMaster && educationMaster.map(function (v, i) {

                                    return (<li key={v.value}><label><input type="radio"  {...register("education", { required: 'Education is required' })} name="education" value={v.value}
                                      defaultChecked={old.education && old.education === v.value ? true : false} /><span className="rdo_text_box">{v.label}</span></label></li>)
                                  })
                                }
                              </ul>
                              <small className="form-text font11 text-danger">{errors.education && errors.education.message}</small>
                            </div>
                          </div>

                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Occupation <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                {
                                  occupationMaster && occupationMaster.map((v, i) => {
                                    return (<li><label><input type="radio"  {...register("occupation", { required: 'Occupation is required' })} name="occupation" value={v.value}
                                      defaultChecked={old.occupation && old.occupation === v.value ? true : false} /><span className="rdo_text_box">{v.label}</span></label></li>)
                                  })
                                }
                              </ul>
                              <small className="form-text font11 text-danger">{errors.occupation && errors.occupation.message}</small>
                            </div>
                          </div>

                          <div className="form-row mb20">
                            <div className="form-group col-md-6 pr20">
                              <div className="font14 color212 fw600 mb10">Annual Income  <span className="colorfa8">*</span></div>
                              <div className="input-group selectarrow__">
                                <input type="tel" className="form-control" id="annual_income" name="annual_income" placeholder="Annual Income"
                                  {...register("annual_income", { required: 'Annual Income field is required' })}
                                  value={annual_income} onChange={ChangeAnnualIncomeHandle} />
                                <select className="custom-select font14" disabled={true}>
                                  <option value="pre-year">Per Year</option>
                                </select>
                              </div>
                              <small className="form-text font11 text-danger">{errors.annual_income && errors.annual_income.message}</small>
                            </div>
                            <div className="form-group col-md-6 pr20">
                              <div className="font14 color212 fw600 mb10">Monthly Expenses <span className="colorfa8">*</span></div>
                              <div className="input-group selectarrow__">
                                <input type="tel" className="form-control" id="monthly_expenses " name="monthly_expenses" placeholder="Monthly Expenses"
                                  {...register("monthly_expenses", { required: 'Monthly Expenses field is required' })} defaultValue={old.monthly_expenses && old.monthly_expenses}
                                  value={monthly_expenses}
                                  onChange={ChangeMonthlyExpensesHandle}
                                />
                                <select className="custom-select font14" disabled={true}>
                                  <option value="pre-year">Per Month</option>
                                </select>
                              </div>
                              <small className="form-text font11 text-danger">{errors.monthly_expenses && errors.monthly_expenses.message}</small>
                            </div>
                          </div>


                          <div className="form-row mb20">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 alert alert-info-new">
                                <i className="fa fa-info-circle" aria-hidden="true"></i> The amount of Insurance Cover required greatly depend on your income , expenses and other liabilities.
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </>
                  )}

                  {step === 3 && (
                    <>
                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">
                          <div className="form-row">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Does any member(s) suffer from any existing illness or health condition from the past? <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                {
                                  ["Diabetes", "Hypertension", "Any Surgery", "Thyroid", "Asthma", "Others","None of these"].map(function (key, value) {
                                    return (<li><label><input type="checkbox" value={key} name="any_existing_illness[]"
                                      className={key === 'None of these' ? 'no_illness' : 'illness'}
                                      onClick={handleCheckboxChange}
                                      {...register("any_existing_illness", { required: 'This field is required' })}
                                      defaultChecked={old.any_existing_illness ? old.any_existing_illness.includes(key) : false}
                                    /><span className="rdo_text_box"> {key}</span></label></li>)
                                  })
                                }
                              </ul>
                              <span className="text-danger form-text font11 ">  {errors.any_existing_illness && errors.any_existing_illness.message} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <div className="form_prnt pt25 pl35 pr35 pb35">
                      <div className="custome_form">
                        <div className="form-row">
                          <div className="form-group col-md-6 pr20">
                            <div className="font14 color212 fw600 mb10">PIN Code <span className="colorfa8">*</span></div>
                            <input type="number" className="form-control" name="pin" id="pin" placeholder="PIN Code"
                              {...register("pin", {
                                required: pin_required,
                                pattern: {
                                  value: /^[0-9]{6}$/,
                                  message: 'PIN Code must be 6 digits numeric',
                                },
                              })}
                              defaultValue={old.pin && old.pin}
                              onChange={EnterPin} value={pin} />
                            <small className="form-text font11 text-danger">{errors.pin && errors.pin.message}</small>
                          </div>
                        </div>
                        <div className="text-center col-md-6"> OR </div>
                        <div className="form-row">
                          <div className="form-group col-md-12 pr20">
                            <ul className="steps_tag font14 fw500 color182 mt15">
                              {
                                ["Mumbai", "Pune", "Bengaluru", "Thane", "Hyderabad", "Delhi"].map(function (v) {
                                  return (
                                    <li><label><input className="city_list" type="radio" name="city" value={v} onClick={EnterCity}
                                      defaultChecked={old.city && (old.city === v ? true : false)}
                                      {...register("city", { required: false })}
                                    /><span className="rdo_text_box">{v}</span></label></li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-row mb20">
                        <div className="form-group col-md-12 pr20">
                          <div className="font14 alert alert-info-new">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>  Some insurance companies do not provide coverage in few pin codes.</div>
                        </div>
                      </div>

                    </div>
                  )}
                  <div className="saveBtn d-block d-sm-none">
                    {
                      step === 4 ? <>
                        <button type='submit' className="btn btn-primary">Save <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
                      </> : <>
                        <button type='submit' className="btn btn-primary">Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
                      </>
                    }

                  </div>

                </div>

              </form>
            </div>

            <div className="col-lg-4 qouteouter d-none d-md-block">
              <div className="qoutetext font18 fw500 color212 pl45 pr45">
                <div className="qoutesign">&#8220;</div>
                Term insurance provides a financial safety net for your loved ones in case of your untimely demise. It ensures that they can maintain their standard of living, pay off debts, cover daily expenses, and achieve their financial goals even if you're not around to provide for them.
              </div>

              <div className="qouteimg">
                <img src={SideImg} alt="Completed" />
              </div>
            </div>
          </div>

        </div>

        <Disclaimer />
      </div>
    </Fragment>
  );
}
