 

 const PackageData = [
    {
      id:1,
      name: 'Care Health Insurance', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Care Health Insurance', 
      premium: 15000 , 
      cover: 500000, 
      logo: 'images/logo/care-helth.png',
      badge: 'Popular', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],

      monthly_pay: 15000,
      base_Amount: 15000, 
      claim_settlement_ratio: 98,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      uin_no: '104N118V06',
      about:'Care Health Insurance Co. Ltd. is a Joint Venture between Max Financial Services Ltd. (part of Max Group) and Axis Bank Ltd. It has a total claims paid ratio of 99.51% for the FY 23-24. Max Life offers comprehensive protection and long-term savings life insurance solutions, through its multichannel distribution.',

    },
    {
      id:2,
      name: 'Manipal Cigna', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Manipal Cigna', 
      premium: 110000 , 
      cover: 1000000, 
      //logo: 'https://www.manipalcigna.com/documents/20124/371699/ProHealth+Insurance+Optimized.png',
      logo: 'images/logo/manipalcigna.png',
      badge: 'Recommended',
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 110000,
      base_Amount: 110000, 
      claim_settlement_ratio: 99,
      policies_sold_ratio: 45,
      solvency_ratio: 182 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text .......',
    }, 
    {
      id:3,
      name: 'Manipal Cigna', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Manipal Cigna', 
      premium: 42000 , 
      cover: 500000, 
      logo: 'images/logo/manipalcigna.png',
      badge: 'Recommended', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 42000,
      base_Amount: 42000, 
      claim_settlement_ratio: 96,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - Manipal Cigna .......',
    },
    {
      id:4,
      name: 'HDFC ERGO', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'HDFC ERGO', 
      premium: 20000 , 
      cover: 1500000, 
      logo: 'images/logo/hdfc-ergo.jpeg',
      badge: '', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 20000,
      base_Amount: 20000, 
      claim_settlement_ratio: 98,
      policies_sold_ratio: 48,
      solvency_ratio: 142 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - HDFC ERGO.......',
    },
    {
      id:5,
      name: 'Care Health', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Care Health', 
      premium: 14300 , 
      cover: 2000000, 
      logo: 'images/logo/care-helth.png',
      badge: 'Popular', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 14300,
      base_Amount: 14300, 
      claim_settlement_ratio: 95,
      policies_sold_ratio: 43,
      solvency_ratio: 170 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - Care Health ......'
    }  ,
    {
      id:6,
      name: 'Care Health', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Care Health', 
      premium: 16000 , 
      cover: 2500000, 
      logo: 'images/logo/care-helth.png',
      badge: 'Popular', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 16000,
      base_Amount: 16000, 
      claim_settlement_ratio: 90,
      policies_sold_ratio: 39,
      solvency_ratio: 162 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - Care Health ......'
    },
    {
      id:7,
      name: 'Manipal Cigna', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'Manipal Cigna', 
      premium: 18000 , 
      cover: 5000000, 
      logo: 'images/logo/manipalcigna.png',
      badge: 'Recommended', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 18000,
      base_Amount: 18000, 
      claim_settlement_ratio: 97,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - Manipal Cigna ......'
    },
    {
      id:8,
      name: 'HDFC ERGO', 
      sub_tags: ['2X COVERAGE', '50K NO CLAIM BONUS'],
      about_title:'HDFC ERGO', 
      premium: 23000 , 
      cover: 3000000, 
      logo: 'images/logo/hdfc-ergo.jpeg',
      badge: '', 
      options:[
        "No co-Payment",
        "No disease wise sub-limits",
        "Can take any room",
        "Pre Existing Disease : 3 Years"
      ],
      monthly_pay: 23000,
      base_Amount: 23000, 
      claim_settlement_ratio: 98,
      policies_sold_ratio: 40,
      solvency_ratio: 192 ,
      uin_no: 'XXXXXXXXXXX',
      about:'about text - Manipal Cigna ......'
    },
    
  ];


  export default PackageData;