import Global from "../../Global";
import { Modal, ModalBody } from 'react-bootstrap';
import { PopupModal } from "react-calendly";
import { Fragment, useState } from "react";

const Assistant = (props) => {

        // eslint-disable-next-line
        const [popupIcon, setPopupIcon] = useState('deleteicon');
        // eslint-disable-next-line
        const [riskshow, setRiskshow] = useState(false);
    
        const [isOpen, setIsOpen] = useState(false);
    
        const whatsApp = () => {
    
            window.open("https://api.whatsapp.com/send?phone=919004316542&text=Hi%20I%20have%20completed%20the%20first%20cut%20of%20FinnFit%20plan", "_blank")
        }

    return (
        <Fragment>
            <div className={props.className && props.className}>
                <div className="avatar-display text-center">
                    <img src={Global.base_url + "/images/avatar-1.png"} alt="" />
                    <img src={Global.base_url + "/images/avatar-2.png"} alt="" />
                    <img src={Global.base_url + "/images/avatar-3.png"} alt="" />
                    <img src={Global.base_url + "/images/avatar-1.png"} alt="" />
                    <img src={Global.base_url + "/images/avatar-2.png"} alt="" />
                </div>
                <h3 className="heading-3  text-center mt10 mb10">Need Assistant?</h3>
                <p className="text-type-1">We are here to help you. Visit the support section to get quick answers <strong className="pointer" onClick={() => { setRiskshow(true) }}>Get Support</strong> </p>
            </div>
            <Modal show={riskshow} size="lg" onHide={() => setRiskshow(false)} dialogClassName='modal-container' centered>
                <ModalBody>
                    <div className="p20">
                        <div className="text-left">
                            <img src='../../images/uim_schedule.png' alt='shedule' width={"80"} />
                        </div>
                        <div className="text-left mt40">
                            <h1 className='font24 fw700 color384'>Book a convinient time slot now</h1>
                        </div>
                        <div className="text-left mt40 color485 fw500 font17">
                            <strong>{"Congratulations"}</strong>{" on taking the first step. Discuss with us and get assistance with shortlisting a policy, filling up the application form and its processiong."}
                        </div>
                        <div className="text-left mt40 color485 fw500 font17">
                            No Spam <br />
                            {"And Dont worry, we will never call you back if ask us not to."}
                        </div>

                        <div className="text-center smallbtn popupConsultBtn mt30">
                            <button type="button" className={"btn btnConsultation"} onClick={() => { setRiskshow(false); setIsOpen(true); }}><i class="fa-regular fa-calendar btnConsultationFa"></i> Book a Call</button>
                            <button type="button" className={"btn btnConsultationWA"} onClick={() => { whatsApp() }}><i class="fab fa-whatsapp btnConsultationFa"></i> WhatsApp Us</button>
                        </div>

                    </div>
                </ModalBody>
            </Modal>

            <PopupModal
                url="https://calendly.com/finnovate/financial-fitness-consultation?utm_campaign=website&utm_source=WebPopup"
                onModalClose={() => { setIsOpen(false); setRiskshow(true) }}
                open={isOpen}
                rootElement={document.getElementById("root")}
            />

        </Fragment>



    )
}

export default Assistant;