import { React, Fragment, useState } from "react";

import Title from "../../Title";
import DashboardHeader from "../../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./../LifeInsuranceDetails.css";
import PackageData from "../data/PackageData";
import Global from "../../Global";
import Assistant from "../components/Assistant";
import CardHealthPremiumDetails3 from "./CardHealthPremiumDetails3";


export default function PreProposalStage() {
  const { productId } = useParams();
  // eslint-disable-next-line
  const [packages, setPackages] = useState(PackageData.filter((obj) => obj.id === productId)[0]);
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Term Plan: Quote");

  const [active, setActive] = useState('accordion_1');



  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  }

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link> <i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Insurance Advise</Link> <i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Health Insurance</Link> <i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <span>{packages.name}</span>
            <Link to={"/health-insurance-details/health-plan/" + packages.id} className="bluebtn" style={{ position: 'absolute', right: '9px', color: 'white' }}><i className="fa fa-angle-left font13 mr5" aria-hidden="true"></i> Back</Link>
          </div>

          <div className="row mt20 life-insurance-details">
            <div className="col-md-8">
              <div className="card p20">
                <div className="row">
                  <div className="col-md-12 life-lnsurance-logo-section">
                    <table className="">
                      <tr>
                        <td style={{ 'maxWidth': '150px' }}><img src={Global.base_url + '/' + packages.logo} alt="" className="logo maxWidth100" /></td>
                        <td style={{ 'paddingLeft': "10px" }}>
                          <h2 className="heading-1">  {packages.name}  </h2>
                          {
                            packages.sub_tags &&
                            packages.sub_tags.map((tag) => {
                              return <span className="tags mr10">{tag}</span>
                            })
                          }
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <div className="col-md-2">
                    
                  </div>
                  <div className="col-md-10 p10">
                    
                  </div> */}
                </div>
                <hr className="border-hr" />
                <div className="card-body-text life-insourance-buy">
                  <h3 className="heading-2 mb20">You’re almost there</h3>


                  <div className="rc-accordion-card">
                    <div className="rc-accordion-header">
                      <div className={`rc-accordion-toggle p-3 ${active === 'accordion_1' ? 'active' : ''}`} onClick={() => handleToggle('accordion_1')}>
                        <h5 className="rc-accordion-title"><span className="sr_no mr10">1</span> Proposal Form</h5>
                        <i className="fa fa-chevron-down rc-accordion-icon"></i>
                      </div>
                    </div>
                    <div className={`rc-collapse  ${active === 'accordion_1' ? 'show' : ''}`}>
                      <div className="rc-accordion-body">
                        <p className='mb-0'>Answer a set of questions to fill out your online application</p>
                        <Link to={"/health-plan/" + productId + "/proposal-stage"} className="btn btn-primary finn-primary-btn mt20 ml20">Start</Link>
                      </div>
                    </div>
                  </div>

                  <div className="rc-accordion-card">
                    <div className="rc-accordion-header">
                      <div className={`rc-accordion-toggle p-3 ${active === 'accordion_2' ? 'active' : ''}`} onClick={() => handleToggle('accordion_2')}>
                        <h5 className="rc-accordion-title"><span className="sr_no mr10">2</span>Make payment</h5>
                        <i className="fa fa-chevron-down rc-accordion-icon"></i>
                      </div>
                    </div>
                    <div className={`rc-collapse  ${active === 'accordion_2' ? 'show' : ''}`}>
                      <div className="rc-accordion-body">
                        <p className='mb-0'>my text body content...</p>
                      </div>
                    </div>
                  </div>

                  <div className="rc-accordion-card">
                    <div className="rc-accordion-header">
                      <div className={`rc-accordion-toggle p-3 ${active === 'accordion_3' ? 'active' : ''}`} onClick={() => handleToggle('accordion_3')}>
                        <h5 className="rc-accordion-title"><span className="sr_no mr10">3</span>Policy Issuance</h5>
                        <i className="fa fa-chevron-down rc-accordion-icon"></i>
                      </div>
                    </div>
                    <div className={`rc-collapse  ${active === 'accordion_3' ? 'show' : ''}`}>
                      <div className="rc-accordion-body">
                        <p className='mb-0'>my text body content...</p>
                      </div>
                    </div>
                  </div>



                </div>
              </div>


              <div className="mb50"></div>

            </div> {/** end of left section col-md-8 */}


            <div className="col-md-4">{/** start of right section col-md-4 */}
              <CardHealthPremiumDetails3 packages={packages} />
              <Assistant className="mt30" />
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
}
