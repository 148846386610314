

const params_hdfc_life_getQuoteNew = {
  ProductCode: "LIFE01",
  ProductVersion: "1.0",
  EffectiveDate: "2023-03-28T00:00:00",
  QuotationDate: "2023-03-28T00:00:00",
  ExpiryDate: "2023-04-16T23:59:59",
  CarrierCode: "HDFC",
  CarrierPlanId: "LIFE_A_RP",
  CarrierPlanName: "Life",
  CarrierProductId: "P1C2PSE",
  TOBstatus: "N",
  SumInsured: 50000000, //
  Term: "10", //
  PPT: "10", //
  Frequency: "1", //
  PPTOption: "REGULAR",
  QuoteStatus: "NEW",
  GenerateFlag: "Y",
  AnnualIncome: "", // "100000"
  PolicyLobList: [
    {
      ProductCode: "LIFE01",
      PolicyRiskList: [
        {
          ProductElementCode: "R00001",
          DateOfBirth: "", // 1992-02-10
          Gender: "", // M | F
        },
      ],
    },
  ],
};

let params_hdfc_life_getQuoteFinalised = {
  ProductCode: "LIFE01",
  ProductVersion: "1.0",
  EffectiveDate: "2023-03-28T00:00:00",
  QuotationDate: "2023-03-28T00:00:00",
  ExpiryDate: "2023-04-16T23:59:59",
  CarrierCode: "HDFC",
  CarrierPlanId: "LIFE_A_RP",
  CarrierPlanName: "Life",
  CarrierProductId: "P1C2PSE",
  TOBstatus: "N",
  SumInsured: 50000000, //
  Term: "10", //
  PPT: "10", //
  Frequency: "1", //
  PPTOption: "REGULAR",
  QuoteStatus: "FINALIZED",
  GenerateFlag: "N",
  AnnualIncome: "", // "100000"
  PolicyLobList: [
    {
      ProductCode: "LIFE01",
      PolicyRiskList: [
        {
          ProductElementCode: "R00001",
          DateOfBirth: "", // 1992-02-10
          Gender: "", // M | F
          Email: "",
          Mobile: "",
        },
      ],
    },
  ],
  ROP: "No",
  WOPCI: "No",
  WOPTotDisability: "No"
};


const params_icici_life_getQuoteFinalised = {
  ProductCode: "Life and Health",
  ProductVersion: "1.0",
  EffectiveDate: "2023-03-28T00:00:00",
  QuotationDate: "2023-03-28T00:00:00",
  ExpiryDate: "2023-04-16T23:59:59",
  CarrierCode: "ICICI",
  CarrierPlanId: "Life and Health",
  CarrierPlanName: "Life and Health",
  CarrierProductId: "T51",
  TOBstatus: "N",
  SumInsured: 5000000, //
  Term: "10", //
  PPT: "10", //
  Frequency: "1", //
  PPTOption: "REGULAR",
  QuoteStatus: "FINALIZED",
  GenerateFlag: "N",
  AnnualIncome: "", // "100000"
  PolicyLobList: [
    {
      ProductCode: "LIFE01",
      PolicyRiskList: [
        {
          ProductElementCode: "R00001",
          DateOfBirth: "", // 1992-02-10
          Gender: "", // M | F
          Email: "",
          Mobile: "919930578095",
        },
      ],
    },
  ],
};

export { params_hdfc_life_getQuoteNew, params_hdfc_life_getQuoteFinalised, params_icici_life_getQuoteFinalised };
