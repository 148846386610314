import React, { Fragment, useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import "../css/header.css";
import "../css/style.css";
import "../css/dashboard.css";
import "../css/plan.css";
import "./Home.css";
import SideImg from "../img/BasicProfile.png";
import Disclaimer from "../components/Disclaimer";
import Header from '../components/layout/Header'
import Title from "../Title";
import { get_pin_code } from "../components/global";

export default function HealthPlan() {
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState('Health Insurance');

  const { register, handleSubmit, formState: { errors } } = useForm();

  // eslint-disable-next-line
  const [old, setOld] = useState(
    localStorage.getItem('form_data') ? JSON.parse(localStorage.getItem('form_data'), (key, value) => {
      if (value === 'true' || value === true) return true;
      else if (value === 'false' || value === false) return false;
      else if (value === '') return '';
      else if (!isNaN(value)) return parseInt(value);
      return value;
    }) : {}
  );

  const [member_options, setMemberOptions] = useState({
    gender: (old.gender ? old.gender : ''),
    city: (old.city ? old.city : ''),
    pin: (old.pin ? old.pin : ''),
    self: (old.self ? old.self : false),
    spouse: (old.spouse ? old.spouse : false),
    kids: (old.kids ? old.kids : false),
    son: (old.son ? old.son : false),
    daughter: (old.daughter ? old.daughter : false),
    any_existing_illness: (old.any_existing_illness ? old.any_existing_illness : []),
  });

  const [son_count, setSonCount] = useState(old.son_arr ? (old.son_arr).length : 0);
  const [daughter_count, setDaughterCount] = useState(old.daughter_arr ? (old.daughter_arr).length : 0);

  const [step, setStep] = useState(1);
  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };
  // eslint-disable-next-line
  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  // eslint-disable-next-line
  const [member_age, setMemberAge] = useState({});
  // eslint-disable-next-line
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberAge((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };


  const [form_title, setFormTitle] = useState("Step1");
  const [form_subtitle, setFormSubTitle] = useState("");

  useEffect(function () {
    if (step === 1) {
      setFormTitle('Health insurance');
      setFormSubTitle('Who would you like to insure?');
    }
    if (step === 2) {
      setFormTitle('Health insurance');
      setFormSubTitle('How old is each member ?');
    }
    if (step === 3) {
      setFormTitle('Health insurance');
      setFormSubTitle('Where do you live ?');
    }
    if (step === 4) {
      setFormTitle('Health insurance');
      setFormSubTitle('Just few last details To help us find the right plan');
    }
    if (step === 5) {
      setMemberOptions({ ...member_options, son_count: son_count, daughter_count: daughter_count, })
      localStorage.setItem('member_options_data', JSON.stringify(member_options));
      window.location.href = '/health-plan/quote';
    }
    // eslint-disable-next-line
  }, [step, errors]);


  useEffect(function () {
    if (member_options.kids === false) {
      setDaughterCount(0);
      setSonCount(0);
    }
  }, [member_options, errors]);

  let pin_ = [];
  pin_['Mumbai'] = 400069;
  pin_['Pune'] = 411045;
  pin_['Bengaluru'] = 560004;
  pin_['Thane'] = 400601;
  pin_['Hyderabad'] = 500004;
  pin_['Delhi'] = 110004;

  const [ageGap, setAgeGap] = useState(false);

  const onSubmit = data => {
    // alert(data.city);
    //data = {...data, city: member_options.city}
    if (!data.son_arr) {
      data = { ...data, son_arr: [] }
    }
    if (!data.daughter_arr) {
      data = { ...data, daughter_arr: [] }
    }

    if (step === 2 && data.son_arr.length > 0) {
      const selfAge = parseInt(data.self_age);

      const minChildAge = Math.min(...data.son_arr.map(son => parseInt(son.age)));
      if ((selfAge - minChildAge) < 18) {
        // Display an error message for age gap
        setAgeGap(true);
        return;
      }
    }else{
      setAgeGap(false);
    }

    if (step === 3) {
      if (data.pin === "") {
        data = { ...data, pin: get_pin_code(data.city) }
      }
    }

    if (step >= 4) {
      data = { ...data, pin: pin, city: city }

      localStorage.setItem('form_data', JSON.stringify(data));
    }
    handleNext();
    // window.location.href = '/dashboard';

  }

  // Event handler to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    // setIsChecked(checked);
    // setCheckboxValue(value);
    if (checked && value === 'None of these') {

      const activeCheckboxes = document.querySelectorAll('.illness');
      // Loop through the found checkboxes and set them as checked
      activeCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

    }
    if (checked && value !== 'None of these') {
      const activeCheckboxes = document.querySelectorAll('.no_illness');
      // Loop through the found checkboxes and set them as checked
      activeCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  };

  useEffect(function () {
    (member_options.any_existing_illness).map((str) => function () {
      if (str === 'None of these')
        setMemberOptions({ ...member_options, any_existing_illness: ['None of these'] })
    })
  }, [member_options]);


  const [pin, setPin] = useState(old.pin ? old.pin : "");
  const [pin_required, setPinRequired] = useState(old.city ? false : "PIN Code is required OR Select City name from given below list");
  const EnterPin = (event) => {
    const { value } = event.target;
    setPin(value);
    if (value) {
      setPinRequired("PIN Code is required OR Select City name from given below list");
      document.querySelectorAll('.city_list').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }
  const EnterCity = (event) => {
    const { checked, value } = event.target;
    setCity(value);
    if (checked) {
      setPin("");
      setPinRequired(false);
      if (errors.pin) {
        errors.pin = false;
      }
    }
  }
  // eslint-disable-next-line
  const [kids_validation_message, setKidsValidationMessage] = useState("Atleast 1 should be greater Than zero");

  const [city, setCity] = useState("");
  useEffect(() => {
    if (city.length > 2) {
      setPin(get_pin_code(city));
    }
  }, [city])

  useEffect(() => {
    if (pin.length > 4) {
      setCity("");
    }
  }, [pin])


  return (
    <Fragment>
      <Title title={pageTitle} /> {/* Set the dynamic title */}
      <Header steps={1} />

      <div className="innercontainer mb40">
        <div className="mainwrapper">

          {/*<div className="form_title">
            <div className="mt30 font20 fw600">Health Insurance</div>
            <div className="font15 color495">Find top health Insurance plans for you.</div>
        </div> */}

          <div className="row formWpr mt25">
            <div className="col-lg-8 formouter">

              <ul className="form_swipe_tab font12 fw600">
                <li className={step === 1 && 'act'}>1</li>
                <li className={step === 2 && 'act'}>2</li>
                <li className={step === 3 && 'act'}>3</li>
                <li className={step === 4 && 'act'}>4</li>
              </ul>
              <form id="form_1" onSubmit={handleSubmit(onSubmit)} >
                <div className="formtitle flex_center justify_center">
                  <div>
                    <div className="font18 fw600">{form_title}</div>
                    <div className="font15 color495 ">{form_subtitle}</div>
                  </div>
                  <div>
                    {
                      step === 5 ? <>
                        <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Save</button>
                      </> : <>
                        <button className="bluebtn font13 fw600 d-none d-md-block" type='submit'>Next
                          <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                          </svg></span>
                        </button>
                      </>
                    }
                  </div>
                </div>
                <div className="div">
                  {step === 1 && (
                    <>

                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">
                          <div className="form-row">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Gender <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} /* checked={member_options.gender === 'male' ? true : false }  */ name="gender" value="male" onClick={() => { setMemberOptions({ ...member_options, gender: 'male' }) }}
                                  defaultChecked={old.gender ? (old.gender === 'male' ? true : false) : false}
                                /><span className="rdo_text_box">Male</span></label></li>
                                <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} /* checked={member_options.gender === 'female' ? true : false } */ name="gender" value="female" onClick={() => { setMemberOptions({ ...member_options, gender: 'female' }) }}
                                  defaultChecked={old.gender ? (old.gender === 'female' ? true : false) : false}
                                /><span className="rdo_text_box">Female</span></label></li>
                              </ul>
                              <small className="form-text font11 text-danger">{errors.gender && errors.gender.message}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="formdevider"></div>

                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">
                          <div className="form-row">
                            <div className="col-md-12 pr20">
                              <div className="font14 color212 fw600">Want cover for  (Multi select) <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                <li><label><input type="checkbox"  {...register("self", { required: 'Self is required' })} name="self" /* checked={member_options.self} */ value={true} onClick={() => { setMemberOptions({ ...member_options, self: (member_options.self === true ? false : true) }) }}
                                  defaultChecked={old.self ? old.self : false}
                                /><span className="rdo_text_box">Self</span></label></li>
                                <li><label><input type="checkbox"  {...register("spouse", { required: false })} name="spouse" /* checked={member_options.spouse} */ value={true} onClick={() => { setMemberOptions({ ...member_options, spouse: (member_options.spouse === true ? false : true) }) }}
                                  defaultChecked={old.spouse ? old.spouse : false}
                                /><span className="rdo_text_box">Spouse</span></label></li>
                                <li><label><input type="checkbox"  {...register("kids", { required: false })} name="kids" /* checked={member_options.kids} */ value={true} onClick={() => { setMemberOptions({ ...member_options, kids: (member_options.kids === true ? false : true) }) }}
                                  defaultChecked={old.kids ? old.kids : false}
                                /><span className="rdo_text_box"> Kids</span></label></li>
                              </ul>
                              <small className="form-text font11 text-danger">{errors.self && errors.self.message}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        member_options.kids === true && (
                          <>
                            <div className="form_prnt pl35 pr35 pb25">
                              <div className="custome_form">
                                <div className="form-row">
                                  <div className="col-md-6 mb20">
                                    <div className="font14 color212 fw600">Son (s) ?</div>
                                    <div className="quantity_wpr mt10">
                                      <button type="button" onClick={() => { son_count >= 1 && setSonCount(son_count - 1) }}>-</button>
                                      <input type="text"  {...register("son_count", { required: false })} name="son_count" className="font14 fw500 ml15 mr15" placeholder="Qty" id="son_count" value={son_count} defaultValue={son_count} />
                                      <button type="button" className="greenBtn" onClick={() => { son_count < 2 && setSonCount(son_count + 1) }}>+</button>
                                    </div>

                                  </div>
                                  <div className="col-md-6 mb20">
                                    <div className="font14 color212 fw600">Daughter (s) ?</div>
                                    <div className="quantity_wpr mt10">
                                      <button type="button" onClick={() => { daughter_count >= 1 && setDaughterCount(daughter_count - 1); }}>-</button>
                                      <input type="text" {...register("daughter_count", { required: false })} name="daughter_count" className="font14 fw500 ml15 mr15" placeholder="Qty" id="daughter_count" value={daughter_count} defaultValue={daughter_count} />
                                      <button type="button" className="greenBtn" onClick={() => { daughter_count < 2 && setDaughterCount(daughter_count + 1); }}>+</button>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb10">
                                    <input style={{ display: 'none' }} type="number" name="kids_count" value={son_count + daughter_count}
                                      {...register("kids_count", {
                                        required: kids_validation_message,
                                        min: { value: 1, message: 'Atleast 1 should be greater than zero' },
                                        max: { value: 2, message: 'Max 2 kids Allow' }
                                      }
                                      )}
                                      onChange={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                    <small className="form-text font11 text-danger">{errors.kids_count && errors.kids_count.message}</small>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </>)
                      }

                    </>
                  )}
                  {step === 2 && (
                    <div className="form_prnt pt25 pl35 pr35 pb35">
                      <div className="custome_form">
                        <div className="form-row">

                          {member_options.self === true && (
                            <div className="form-group col-md-6 pr20">
                              <label htmlFor="self_age" className="font14 fw600"> Self Age  <span className="colorfa8">*</span></label>
                              <div className="input-group selectarrow__">
                                <input type="number" className="form-control"
                                  id="self_age" name="self_age" placeholder="Self Age"
                                  {...register("self_age",
                                    {
                                      required: 'Self age is required',
                                      min: { value: 18, message: 'Self Age must be at least 18 years old' },
                                      max: { value: 99, message: 'Self Age must be less than or equal to 99 years old' }
                                    })
                                  }
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  defaultValue={old.self_age ? old.self_age : ''}
                                />
                                <select className="custom-select font14" disabled={true}>
                                  <option value="years">Years</option>
                                  <option value="months">Months</option>
                                  <option value="days">Days</option>
                                </select>
                              </div>
                              <small className="form-text font11 text-danger">{errors.self_age && errors.self_age.message}</small>
                            </div>
                          )}

                          {member_options.spouse === true && (
                            <div className="form-group col-md-6 pr20">
                              <label htmlFor="spouse_age" className="font14 fw600"> Spouse Age  <span className="colorfa8">*</span></label>
                              <div className="input-group selectarrow__">
                                <input type="number" className="form-control"
                                  id="spouse_age" name="spouse_age"
                                  placeholder="Spouse Age"
                                  {...register("spouse_age", {
                                    required: 'Spouse age is required',
                                    min: { value: 18, message: 'Spouse Age must be at least 18 years old' },
                                    max: { value: 99, message: 'Spouse Age must be less than or equal to 99 years old' }
                                  })}
                                  defaultValue={old.spouse_age ? old.spouse_age : ''}
                                />
                                <select className="custom-select font14" disabled={true}>
                                  <option value="years">Years</option>
                                  <option value="months">Months</option>
                                  <option value="days">Days</option>
                                </select>
                              </div>
                              <small className="form-text font11 text-danger">{errors.spouse_age && errors.spouse_age.message}</small>
                            </div>
                          )}

                          {son_count > 0 && (
                            [...Array(son_count)].map((e, i) =>
                              <div className="form-group col-md-6 pr20" key={i}>
                                <label htmlFor={'son_age_' + i} className="font14 fw600"> Son Age {i + 1} <span className="colorfa8">*</span></label>
                                <div className="input-group selectarrow__">
                                  <input type="number" className="form-control"
                                    placeholder={"Son " + (i + 1) + " Age"}
                                    id={'son_age_' + i} name={`son_arr[${i}]age`} autoComplete='off' {...register(`son_arr.${i}.age`,
                                      {
                                        required: 'Son age is required',
                                        min: { value: 1, message: 'Min age is 1, If your child is less than a year old, then type age as "1" ' },
                                        max: { value: 24, message: 'Son Age must be less than or equal to 24 years old' },
                                      })}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    defaultValue={old.son_arr ? (old.son_arr[i] ? old.son_arr[i]['age'] : '') : ''}
                                  />
                                  <select disabled={true} id={'son_age_type_' + i} className="custom-select font14" name={`son_arr[${i}]age_type`} {...register(`son_arr.${i}.age_type`)}>
                                    <option value="years">Years</option>
                                    <option value="months">Months</option>
                                    <option value="days">Days</option>
                                  </select>
                                </div>
                                <span className="text-danger form-text font11 ">  {errors.son_arr?.[i]?.age?.message} </span>
                              </div>
                            )
                          )}

                          {daughter_count > 0 && (
                            [...Array(daughter_count)].map((e, i) =>
                              <div className="form-group col-md-6 pr20" key={i}>
                                <label htmlFor={'daughter_age_' + i} className="font14 fw600"> Daughter Age {i + 1} <span className="colorfa8">*</span></label>
                                <div className="input-group selectarrow__">
                                  <input type="number" className="form-control"
                                    id={'daughter_age_' + i} name={`daughter_arr[${i}]age`} autoComplete='off' {...register(`daughter_arr.${i}.age`,
                                      {
                                        required: 'Daughter age is required',
                                        min: { value: 1, message: 'Min age is 1, If your child is less than a year old, then type age as "1"  ' },
                                        max: { value: 24, message: 'Daughter Age must be less than or equal to 24 years old' }
                                      })} placeholder={"Daughter " + (i + 1) + " Age"}
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    defaultValue={old.daughter_arr ? (old.daughter_arr[i] ? old.daughter_arr[i]['age'] : '') : ''}
                                  />
                                  <select disabled={true} id={'daughter_age_type_' + i} className="custom-select font14" name={`daughter_arr[${i}]age_type`} {...register(`daughter_arr.${i}.age_type`)}>
                                    <option value="years">Years</option>
                                    <option value="months">Months</option>
                                    <option value="days">Days</option>
                                  </select>
                                </div>
                                <span className="text-danger form-text font11 ">  {errors.daughter_arr?.[i]?.age?.message} </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      {

                        ageGap && <div class="alert alert-danger">
                          <strong>Error!</strong> Age gap between self and child must be at least 18 years.
                        </div>

                      }
                      <div className="alert alert-info-new">
                        <i className="fa fa-calendar" aria-hidden="true"></i> Health Insurance premiums greatly depend on the age of all insured.
                      </div>
                    </div>
                  )}
                  {step === 3 && (
                    <div className="form_prnt pt25 pl35 pr35 pb35">
                      <div className="custome_form">
                        <div className="form-row">
                          <div className="form-group col-md-6 pr20">
                            <div className="font14 color212 fw600 mb10">PIN Code <span className="colorfa8">*</span></div>
                            <input type="number" className="form-control" id="pin" name="pin" placeholder="PIN Code"
                              {...register("pin", {
                                required: pin_required,
                                pattern: {
                                  value: /^[0-9]{6}$/,
                                  message: 'PIN Code must be 6 digits numeric',
                                },
                              })}
                              defaultValue={old.pin ? old.pin : ''}
                              value={pin}
                              onChange={EnterPin} />
                            <small className="form-text font11 text-danger">{errors.pin && errors.pin.message}</small>
                          </div>
                        </div>
                        <div className="text-center col-md-6"> OR </div>
                        <div className="form-row">
                          <div className="form-group col-md-12 pr20">
                            <ul className="steps_tag font14 fw500 color182 mt15">
                              {
                                [
                                  '411012',
                                  '411015',
                                  '411020',
                                  '422006',
                                  '422102',
                                  '422011', "Pune", "Bengaluru", "Hyderabad", "Mumbai", "Thane", "Delhi"].map(function (v) {
                                    return (
                                      <li><label><input className="city_list" type="radio" name="city" value={v} onClick={EnterCity}
                                        defaultChecked={old.city ? (old.city === v ? true : false) : false}
                                        {...register("city", { required: false })}
                                      /><span className="rdo_text_box">{v}</span></label></li>
                                    )
                                  })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="alert alert-info-new">
                        <i className="fa-solid fa-location-dot"></i> Some insurance companies do not provide coverage in few pin codes.
                      </div>
                    </div>
                  )}

                  {(step === 4 || step === 5) && (
                    <>

                      <div className="form_prnt pt25 pl35 pr35 pb25">
                        <div className="custome_form">
                          <div className="form-row">
                            <div className="form-group col-md-12 pr20">
                              <div className="font14 color212 fw600">Does any member(s) suffer from any existing illness or health condition from the past? <span className="colorfa8">*</span></div>
                              <ul className="steps_tag font14 fw500 color182 mt15">
                                {
                                  ["Diabetes", "Hypertension", "Any Surgery", "Thyroid", "Asthma", "Other Diabetes", "None of these"].map(function (key, value) {
                                    return (<li><label><input type="checkbox" value={key} name="any_existing_illness[]"
                                      className={key === 'None of these' ? 'no_illness' : 'illness'}
                                      onClick={handleCheckboxChange}
                                      {...register("any_existing_illness", { required: 'This field is required' })}
                                      defaultChecked={old.any_existing_illness ? old.any_existing_illness.includes(key) : false}
                                    /><span className="rdo_text_box"> {key}</span></label></li>)
                                  })
                                }
                              </ul>
                              <span className="text-danger form-text font11 ">  {errors.any_existing_illness && errors.any_existing_illness.message} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="saveBtn d-block d-sm-none">
                    {
                      step === 5 ? <>
                        <button type='submit' className="btn btn-primary">Save <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
                      </> : <>
                        <button type='submit' className="btn btn-primary">Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
                      </>
                    }

                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-4 qouteouter d-none d-md-block">
              <div className="qoutetext font18 fw500 color212 pl45 pr45">
                <div className="qoutesign">&#8220;</div>
                Health insurance provides peace of mind knowing that you and your family are financially protected in case of any
                unforeseen medical conditions.
              </div>

              <div className="qouteimg">
                <img src={SideImg} alt="Completed" />
              </div>
            </div>
          </div>

        </div>

        <Disclaimer />
      </div>
    </Fragment>
  );
}
