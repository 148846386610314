import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";
import "../css/style.css";
import "../css/dashboard.css";
import "../css/plan.css";
import "./Home.css";
import SideImg from "../img/BasicProfile.png";
import Disclaimer from "../components/Disclaimer";
import Header from '../components/layout/Header'
import Title from "../Title";
import http from "../http";
import Swal from "sweetalert2";

export default function LandingPage() {

  
  http.post('/tickets', []).then((response) => {
    const resp = response.data;
    if (resp.access_token) {
      localStorage.setItem('access_token', resp.access_token);
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}-${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}-${currentDateTime.getDate().toString().padStart(2, '0')} ${currentDateTime.getHours().toString().padStart(2, '0')}:${currentDateTime.getMinutes().toString().padStart(2, '0')}:${currentDateTime.getSeconds().toString().padStart(2, '0')}`;
      localStorage.setItem('access_token_created_at', formattedDateTime);
    }
  });

  const iconStyle = {
    width: '150px'
  }
  const cssBorder = {
    border: "1px solid"
  }


  const clearCache = () => {
    localStorage.clear();

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations()
      .then(function (registrations) {
         for (let registration of registrations) {
          console.log("registrations", registrations);
            registration.unregister()
         }
      })
   }
  console.log("landing page line no 50");
   
  window.location.reload(true)
 
  }

  
  // eslint-disable-next-line
  const comingSoonAlert = ()=>{
    
    Swal.fire({
      icon: 'info',
      title: 'Coming Soon...',
     // text: 'Coming Soon...',
    }).then(() => {
      
    })
  }

  return (
    <Fragment>
      <Title title={"Finnovate - Home"} /> {/* Set the dynamic title */}
      <Header steps={1} />

      <div className="innercontainer mb40">
        <div className="mainwrapper">

          <div className="row formWpr mt25">
            <div className="col-lg-8 formouter">

              <div className='col-md-12 text-center mt30'>
                <Link to="health-plan" className='btn btn-lg mr30 mt30' style={cssBorder}>
                  <img src="images/health-plan-icon.png" alt="Health Plan" style={iconStyle} /><br />
                  Health Plan
                </Link>
                
                <Link  to="term-plan" className='btn btn-lg mr30 mt30' style={cssBorder}>
                  <img src="images/term-plan-icon.png" alt="Health Plan" style={iconStyle} /> <br />
                  Term Plan
                </Link>

                
              </div>
              <div className='col-md-12 mt100 text'>
                <small>Clear all existing cache data</small> <input type="button" value="Reset" onClick={clearCache} className="btn btn-danger btn-sm" title="Clear all existing cache data" />
              </div>
            </div>

            <div className="col-lg-4 qouteouter d-none d-md-block">
              <div className="qoutetext font18 fw500 color212 pl45 pr45">
                <div className="qoutesign">&#8220;</div>
                Health insurance provides peace of mind knowing that you and your family
                are financially protected in case of any unforeseen medical conditions.
              </div>

              <div className="qouteimg">
                <img src={SideImg} alt="Completed" />
              </div>
            </div>
          </div>

        </div>

        <Disclaimer />
      </div>
    </Fragment>
  );
}
