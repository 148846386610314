import { useState } from "react";
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask';
import { ageCalculate, isValidDate } from "../../components/global";
//import Swal from "sweetalert2";


const EditABasicInfo = ({ setEditBasicInfoFlag, setFormData, form_data }) => {

  const { register, handleSubmit, setError, formState: { errors } } = useForm();

  const [old, setOld] = useState(form_data);


  const onSubmitData = data => {
    
    setOld({ ...old, gender: data.gender, dob: data.dob, smoked_status: data.smoked_status, age: calAge });
    setFormData({ ...old, gender: data.gender, dob: data.dob, smoked_status: data.smoked_status, age: calAge });
    setEditBasicInfoFlag(false)

    /*Swal.fire({
      icon: 'success',
      title: 'Success!',
      confirmButtonColor: '#009356',
      text: 'Basic Info Updated successfully.',
    }).then(() => {
      setEditBasicInfoFlag(false)
    });*/
  }



  const [calAge, setCalAge] = useState(old.dob && ageCalculate(old.dob));

  const handleInputChange = (e) => {
    const input_dob = e.target.value;
    if (isValidDate(input_dob)) {
      setError('dob', { message: false });
      let age_ = ageCalculate(input_dob);
      setCalAge(age_);
      if (age_ < 18 || age_ > 60) {
        setError('dob', { message: 'Age must be between 18 to 59', type: 'required' });
      } else {
        setError('dob', { message: false });
      }
    } else {
      if (!input_dob.includes('_')) {
        setError('dob', { message: 'Invalid Date', type: 'required' });
      }
      setCalAge("");
    }
  };

  return (
    <div className="rightSide_menu_outer" >
      <div className="rightSide_menu">
        <div className="rightSide_menu_header">
          <div>
            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => setEditBasicInfoFlag(false)}> Back </button>
          </div>
          <div className="font14 fw500 color182">Update Basic Info</div>
          <div>&nbsp;</div>
        </div>
        <form id="form_edit_address" onSubmit={handleSubmit(onSubmitData)}>
          <div className="pl20 pr20">
            <div className="mt20">
              <div className="font16 fw600 color182">


                <div className="form_prnt pb20">
                  <div className="custome_form">
                    <div className="form-row mb20">
                      <div className="form-group col-md-12 pr20">
                        <div className="font14 color212 fw600">Gender <span className="colorfa8">*</span></div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                          <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'M' ? true : false} name="gender" value="M" /><span className="rdo_text_box">Male</span></label></li>
                          <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'F' ? true : false} name="gender" value="F" /><span className="rdo_text_box">Female</span></label></li>
                          <li><label><input type="radio"  {...register("gender", { required: 'Gender is required' })} defaultChecked={old.gender && old.gender === 'O' ? true : false} name="gender" value="O" /><span className="rdo_text_box">Others</span></label></li>
                        </ul>
                        <small className="form-text font11 text-danger">{errors.gender && errors.gender.message}</small>
                      </div>
                    </div>

                    <div className="form-row mb20">
                      <div className="form-group col-md-12 pr20">
                        <div className="font14 color212 fw600 mb10">Date Of Birth (DD/MM/YYYY) <span className="colorfa8">*</span></div>
                        <InputMask type="text"
                          {...register("dob", { required: 'Date of Birth is required' })}
                          className="form-control" name="dob"
                          placeholder="DD/MM/YYYY"
                          maskPlaceholder="DD/MM/YYYY"
                          mask="99/99/9999"
                          defaultValue={old.dob ? old.dob : ""}
                          onChange={handleInputChange}
                        />
                        <small className="form-text font11 text-danger">{errors.dob && errors.dob.message}</small>
                        <small className="form-text font12 c-green"><b>{Number.isInteger(calAge) && 'Age: ' + calAge + ' Years'}</b></small>
                      </div>
                    </div>

                    <div className="form-row mb20">
                      <div className="form-group col-md-12 pr20">
                        <div className="font14 color212 fw600">Have You Smoked Or Chewed Tobacco In Last 12 Months ?  <span className="colorfa8">*</span></div>
                        <ul className="steps_tag font14 fw500 color182 mt15">
                          <li><label><input type="radio"  {...register("smoked_status", { required: 'Smoked status is required' })} name="smoked_status" value="N" defaultChecked={old.smoked_status && old.smoked_status === 'N' ? true : false} /><span className="rdo_text_box">No</span></label></li>
                          <li><label><input type="radio"  {...register("smoked_status", { required: 'Smoked status is required' })} name="smoked_status" value="Y" defaultChecked={old.smoked_status && old.smoked_status === 'Y' ? true : false} /><span className="rdo_text_box">Yes</span></label></li>
                        </ul>
                        <small className="form-text font11 text-danger">{errors.smoked_status && errors.smoked_status.message}</small>
                      </div>
                    </div>

                  </div>
                  <div className="alert alert-info-new">
                    <i className="fa-solid fa-location-dot mr10"></i>Insurance premiums for smokers are typically higher than those for non-smokers. But the correct declaration is essential.
                  </div>
                </div>
              </div>
            </div>
            <div className="saveBtn backsaveBtn">
              <button type="button" className="btn btn-primary" onClick={() => setEditBasicInfoFlag(false)}> Close </button>
              <button type="submit" className="btn btn-primary"> Save </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditABasicInfo;