import React, { Fragment, useEffect, useState } from "react";
import "../css/header.css";
import "../css/style.css";
import "../css/dashboard.css";
import "../css/plan.css";
import "./Home.css";
import SideImg from "../img/BasicProfile.png";
import Disclaimer from "../components/Disclaimer";
import Header from '../components/layout/Header'
import Title from "../Title";
import http from "../http";
import { useForm } from 'react-hook-form'
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

export default function ThankYouPage() {

  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const [disableBtn, setDisableBtn] = useState(false);

  const [checked, setChecked] = useState(true);
  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {

    // Retrieve the item from session storage
    const planData = sessionStorage.getItem('planData');

    // Check if 'planData' is null or empty
    if (!planData) {
      navigate('/'); // Redirect immediately if no planData
      return; // Exit the effect early
    }


  }, [navigate]);

  const [successMsg, setSuccessMsg] = useState(false);

  const onSubmit = data => {

    const planData = JSON.parse(sessionStorage.getItem('planData'));

    console.log(planData);

    console.log('planData.AbacusId', planData.AbacusId, 'planData.ClientRequestId', planData.ClientRequestId);

    const postData = {
      AbacusId: (planData.AbacusId ? planData.AbacusId : '' ),
      ClientRequestId: planData.ClientRequestId,
      DuePremium: planData.DuePremium,
      PartnerId: (planData.PartnerId ? planData.PartnerId : '') ,
      CarrierProductName: planData.CarrierProductName ? planData.CarrierProductName : planData.ProductName,
      CarrierCode: planData.CarrierCode,
      fullname: data.fullName,
      email: data.emailAddress,
      mobile: data.mobileNumber,
      quote_json: JSON.stringify(planData),
      request_data: sessionStorage.getItem('healthpostData')
    };

    console.log('line no 66 - postData', postData);
    setDisableBtn(true);

    http.post('/add-quote-users', postData).then((response) => {

      const resp = response.data;
      if (resp.id) {
        setSuccessMsg(true);
        const timer = setTimeout(() => {
          sessionStorage.removeItem('planData')
          navigate('/');
        }, 5000); // 5000 ms = 5 seconds

        return () => clearTimeout(timer);
      } else {

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.message,
        });

      }
      console.log(resp);

    });

  }

  return (
    <Fragment>
      <Title title={"Finnovate - Home"} /> {/* Set the dynamic title */}
      <Header steps={1} />

      <div className="innercontainer mb40">
        <div className="mainwrapper">

          <div className="row formWpr mt25">
            <div className="col-lg-8 formouter">

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formtitle flex_center justify_center">
                  <div>
                    <div className="font20 fw600">Get your Health Insurance</div>
                    <div className="font15 color495 ">You will receive the callback from team.</div>
                  </div>
                  <div className='d-none d-md-block'>
                    {
                      successMsg ? <></> : <><button className="bluebtn font13 fw600" type='submit' disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Save
                        <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                        </svg></span></button></>
                    }

                  </div>
                </div>

                {/* Form */}

                {
                  successMsg ? <><div className="form_prnt pl35 pr35 pb35"><div class="text-left mt40 color485 fw500 font17"><strong>Congratulations</strong> on taking the first step toward your health insurance needs! Thank you for submitting your request. Our team will review your information and get back to you shortly to discuss further details. We look forward to assisting you!</div></div></> :

                    (<>

                      <div className="form_prnt pt25 pl35 pr35 pb35">

                        <div className="custome_form">

                          <div className="form-row">

                            <div className="form-group col-md-6 pr20">
                              <label htmlFor="emailAddress" className="font14 fw600">Full Name</label>
                              <input type="text" className="form-control" id="fullName" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required' })} placeholder="Enter Full Name" />
                              <span className="text-danger"> {errors.fullName && errors.fullName.message}</span>
                            </div>

                          </div>

                          <div className="form-row">

                            <div className="form-group col-md-6 pr20">
                              <label htmlFor="emailAddress" className="font14 fw600">Email address</label>
                              <input type="text" className="form-control" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                              <span className="text-danger"> {errors.emailAddress && errors.emailAddress.message}</span>
                            </div>

                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-6 pr20">
                              <label htmlFor="mobileNumber" className="font14 fw600">Mobile number</label>
                              <div className="input-group mb2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text fw500 font14 color212">+91</div>
                                </div>
                                <input type="tel" className="form-control" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" maxLength={10} />

                              </div>
                              <span className="text-danger"> {errors.mobileNumber && errors.mobileNumber.message}</span>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12 pr20">
                              <label htmlFor="authorization" className="font15 color495 " >
                                <input type="checkbox" value="1" checked={checked} onClick={() => handleChange()}
                                  name="authorization" {...register("authorization", { required: false })} /> I, hereby authorize Finnovate to contact me via call, WhatsApp, Email or sms to discuss further regarding my financial fitness.
                              </label>
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-12 pr20">
                              <label className="font15 color495 " style={{ 'color': 'gray' }}>
                                Your information is safe and protected by encryption.
                              </label>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="saveBtn d-block d-sm-none">
                        <button type="submit" className="btn btn-primary" disabled={(disableBtn === true) ? true : !isDirty && !isValid}>Save and Continue <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" stroke-width="1.30435" stroke-miterlimit="10"></path></svg></span></button>
                      </div>

                    </>)
                }



              </form>

            </div>

            <div className="col-lg-4 qouteouter d-none d-md-block">


              <div className="qouteimg">
                <img src={SideImg} alt="Completed" />
              </div>
            </div>
          </div>

        </div>

        <Disclaimer />
      </div>
    </Fragment>
  );
}
