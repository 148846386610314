export default function HealthInsurance() {
  return (
    <>
      <div  className="tab-pane fade show active" id="Welcomenote"   role="tabpanel"    aria-labelledby="Welcomenote-tab"  >
        <div className="tabContent_title font12 fw500 color7B8 pb15">
            <span className="color263">Health Insurance</span>
        </div> 
        <div className="font22 fw700 color0C4 pt30">Health Insurance</div>
        <div className="font14 fw500 color182 pt20">
          We are pleased to present you with your personalized comprehensive
          financial report. Our team continuously strives in making the
          financial plan document as relevant and realistic as possible. This
          Financial plan is not only for you but for us also. It helps us
          understand the factors that affect your decision making with respect
          to various expenses, savings and investments.
        </div>
        <div className="welcomeimg">
          <img src="images/fp_report_main.png" alt="welcome" />
        </div> 

      </div>
    </>
  );
}
