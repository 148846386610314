import React, { Fragment } from "react"; 
import DashboardHeader from "../components/layout/DashboardHeader"; 
import '../css/global.css';


export default function AdminDashboard() {
 
  return (
    <Fragment>
      <DashboardHeader /> 
      
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="form_title">
            <div className="mt30 font21 fw600 color384">
            Dashboard
            </div>
            <div className="font16 color626">
                Welcome to dashboard !
            </div> 
          </div>

          <div className="dashboard_outer mt30 p20" style={{height:'400px'}}> 
                <h1>text.......</h1>
          </div>
        </div>
      </div> 
    </Fragment>
  );
}
