import React, { Fragment } from "react"; 
export default function Disclaimer() { 
  return (
    <Fragment>           
        <div className="row mt50">
          <div className="col-lg-12">
            <p className="font11">Disclaimer:</p>
            <p className="font11">
              This financial fitness score is provided for educational and
              informational purposes only and is not intended to provide
              investment, tax, or legal advice. The score is calculated based on
              the information you provide and is not a guarantee of future
              performance or success. Your personal financial situation is
              unique, and the score may not reflect your true financial health.
              It is important to seek professional advice before making any
              financial decisions. The score is not a substitute for
              professional judgment and is subject to change without notice. The
              provider of this score assumes no responsibility for any errors or
              omissions in the information provided. By using this score, you
              agree to hold harmless the provider and its affiliates for any
              losses or damages that may result from any inaccuracies or
              incompleteness of the information.
            </p>

            <p className="font11">
              {" "}
              Registration granted by SEBI, membership of BASL and certification
              from NISM in no way guarantee performance of the intermediary or
              provide any assurance of returns to investors
            </p>
            <p className="font11">
              Mutual Fund investments are subject to market risks, read all
              scheme related documents carefully.
            </p>
            <p className="font11">
              Past performance is not indicative of future returns. Please
              consider your specific investment requirements, risk tolerance,
              goal, time frame, risk and reward balance and the cost associated
              with the investment before choosing a fund, or designing a
              portfolio that suits your needs. Performance and returns of any
              investment portfolio can neither be predicted
              <br />
              nor guaranteed.
            </p>
            <p className="font11">
              SEBI RIA Registration No: INA000013518 | Type of Registration:
              Non-Individual | Validity of registration: June 19, 2019 -
              Perpetual | Address: The Summit Business Park, 7th Floor, Unit No.
              703, Andheri - Kurla Rd, Behind Guru Nanak Petrol Pump, Opp. PVR
              Cinema, Andheri East, Mumbai – 400069 | Contact No. 9819022600 |
              Principal Officer details: Nehal Mota, Email :
              nehal.mota@finnovate.in, Phone: 9152022446 | Corresponding Local
              SEBI Office : SEBI Office Address: Office of Investor Assistance
              and Education, SEBI Bhavan Plot No.C4-A, G Block, Bandra Kurla
              Complex, Bandra (E), Mumbai - 400 051
            </p>
            <p className="font11">
              AMFI Registered Distributor - ARN 52128 | Platform Partner : BSE
              (Member code 17548) | IRDAI Corporate Agency : CA0703 | CIN:
              U67190MH2007PTC172272 | GST No : 27AABCF1124C2ZC | Website –{" "}
              <a
                href="http://www.finnovate.in"
                target="_blank"
                rel="noreferrer"
              >
                www.finnovate.in
              </a>{" "}
              Email – <a href="mailto:hello@finnovate.in">hello@finnovate.in</a>
            </p>
          </div>
        </div> 
    </Fragment>
  );
}
