import { Link } from "react-router-dom";
 
const CardHealthPremiumDetails1 = (props)=>{

    const form_data = props.form_data; 
   // console.log("form_data -->", form_data)

    let son_age_arr = [] ;
    let daughter_age_arr = [];
    // eslint-disable-next-line
    form_data.son_arr.length > 0 &&  form_data.son_arr.map((son, index) => {  
        son_age_arr = [...son_age_arr, son.age ];
     })
     // eslint-disable-next-line
     form_data.daughter_arr.length > 0 &&  form_data.daughter_arr.map((daughter, index) => {  
      daughter_age_arr = [...daughter_age_arr, daughter.age];
     })

     //console.log("son_age_arr , daughter_age_arr ",son_age_arr , daughter_age_arr)


    return (
      <div className={"card p20 " + (props.className && props.className)}>
      <div className="row">
        <div className="col-md-12">
          <table>
            <tr>
              <td className="" style={{width:'90%'}}>
                <span className="text-type-1-1">Basic Info</span>
                <br />  
                <span className="heading-3">{form_data.spouse || form_data.kids_count > 0 ? 'Family Floater' : 'Individual'} | {form_data.city !== '' ? form_data.city : 'pin-' + form_data.pin }</span>
              </td>
              <td className="">
                <div className="title_viewmore">
                  <Link title="Edit My Inputs Details" to="#" onClick={()=>{props.setEditAddressFlag(true)}}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                </div>
              </td>
            </tr>

            <tr>
              <td className="pt20" colSpan={2}> 
                  <div className="font14 color212 fw600">Cover includes for</div>
                  <ul className="steps_tag font14 fw500 color182 mt15">
                    {
                      form_data.self && <li><label><input checked={true} type="checkbox" name="self" value={form_data.self_age}/><span className="rdo_text_box padding_5-7">Self ({form_data.self_age && form_data.self_age })</span></label></li>   
                    }
                    {
                      form_data.spouse && 
                      <li><label><input type="checkbox" checked={true}  name="Spouse" value={ form_data.spouse_age }/><span className="rdo_text_box padding_5-7">Spouse ({form_data.spouse_age && form_data.spouse_age })</span></label></li> 
                    }
                   {/*  {
                      form_data.son_arr.length > 0 && 
                      <li><label><input type="checkbox" checked={true}  name="son[]" value={1}/><span className="rdo_text_box padding_5-7">Son ()</span></label></li>
                     
                    }
                     {
                      form_data.daughter_arr.length > 0 && form_data.daughter_arr.map((daughter, index) => { 
                      return <li key={index}><label><input type="checkbox" checked={true}  name="daughter[]" value={daughter.age}/><span className="rdo_text_box padding_5-7">Daughter ({daughter.age})</span></label></li>
                      })
                    } */}
                    {
                      form_data.son_arr.length > 0 ?
                      <li><label><input type="checkbox" checked={true}  name="members" value="Son(2)"/><span className="rdo_text_box padding_5-7">Son ({son_age_arr.join(', ')})</span></label></li>   : ''
                    }
                    {
                      form_data.daughter_arr.length > 0 ? 
                      <li><label><input type="checkbox" checked={true}  name="members" value="Daughter(1)"/><span className="rdo_text_box padding_5-7">Daughter
                        ({daughter_age_arr.join(', ')})</span></label></li>  : '' 
                    }

                    
                      
                    
                    
                  </ul>   
              </td> 
            </tr>

            <tr>
              <td className="pt20" style={{width:'90%'}}>
                <span className="text-type-1-1">Health Cover</span>
                <br />
                <span className="heading-3"> ₹ {form_data.desired_cover_amount && form_data.desired_cover_amount/100000} Lakh </span>
              </td>
              <td className="pt20">
                <div className="title_viewmore">
                  <Link title="Edit My Inputs Details"  onClick={()=>{props.setEditPlanDetailsFlag(true)}}  to="#"><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link> 
                </div>
              </td>
            </tr>

          </table>
        </div>
      </div>
 
  </div>
    )
}

export default CardHealthPremiumDetails1;