import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import 'rc-slider/assets/index.css';
import formatAmount from "indian-currency-formatter";
import { Card } from "bootstrap-4-react/lib/components";
import { Col, Row } from "bootstrap-4-react/lib/components/layout";
import React from 'react'
import { useParams } from "react-router-dom";
import { CignaRiderAndAddonList } from "../data/CignaRiderAndAddonList";

const EditHealthAddOnsCigna = ({ apiResponse, setEditAddOnsFlag, checkedAddon, setCheckedAddon, uncheckedAddon, setUncheckedAddon, setFormData, form_data, masterAddon, cignaPost, setCignaPost }) => {

  const { paramsProductName } = useParams();
  console.log("apiResponse", apiResponse);

  // new code ===============

  let filteredCignaRiderAndAddonList = CignaRiderAndAddonList.filter(item => item.ProductName === paramsProductName);


  /* 
  remove ["C0001491", "C0002019", "PC0001491", "C0002017"] in case only self and male
  */
  if ((['ManipalCigna ProHealth Prime Advantage', 'ManipalCigna ProHealth Prime Protect'].includes(paramsProductName)) && form_data.gender === 'male' && form_data.spouse === false && form_data.kids === false) {
    filteredCignaRiderAndAddonList.forEach(product => {
      product.AddonDetailsList = product.AddonDetailsList.filter(
        addonDetail => !["C0001491", "C0002019", "PC0001491", "C0002017"].includes(addonDetail.Addon)
      );
    });
    filteredCignaRiderAndAddonList.forEach(product => {
      product.RiderDetailsList = product.RiderDetailsList.filter(
        addonDetail => !["C0001491", "C0002019", "PC0001491", "C0002017"].includes(addonDetail.Rider)
      );
    });
  }

  const removeAddons = ['C0002018', 'C0002022'];
  if (form_data.desired_cover_amount > 500000) {
    // Iterate through each product and filter AddonDetailsList to remove specified addons in case cover Amount is > 5L
    filteredCignaRiderAndAddonList.forEach(product => {
      product.AddonDetailsList = product.AddonDetailsList.filter(
        addonDetail => !removeAddons.includes(addonDetail.Addon)
      );
    });
  }

  console.log("filteredCignaRiderAndAddonList ____", filteredCignaRiderAndAddonList)



  //const filteredCignaRiderAndAddonList =  apiResponse.PolicyLobList;
  console.log("filteredCignaRiderAndAddonList", filteredCignaRiderAndAddonList);

  // eslint-disable-next-line
  const [maniplacignaAddonMaster, setManipalcignaAddonMaster] = useState(filteredCignaRiderAndAddonList[0] && filteredCignaRiderAndAddonList[0].AddonDetailsList);

  // eslint-disable-next-line
  const [maniplacignaRiderMaster, setManipalcignaRiderMaster] = useState(filteredCignaRiderAndAddonList[0] && filteredCignaRiderAndAddonList[0].RiderDetailsList);



  console.log('line25 checkedAddon', checkedAddon);
  const [old, setOld] = useState(form_data);



  const getBenefitDescription = (node_name, code) => {
    const list_desc = {
      "C0001491": "Infertility Treatment",
      "C00001": "Critical Illness Benefit",
      "C0002019": "Enhance",
      "C0001523": "Outpatient Expenses",
      "AC0002023": "Cumulative Bonus Booster",
      "AC0002021": "Non Medical Items",
      "PC0001491": "Infertility Treatment",
      "PC00001": "Critical Illness Benefit"
    }
    let code_desc = code;

    if (node_name === 'AddonDetailsList') {
      const d = apiResponse.PolicyLobList[0].AddonDetailsList.filter(obj => obj.BenefitCode === code);
      code_desc = d[0] ? d[0].BenefitDescription : code;
    }
    if (node_name === 'RiderDetailsList') {
      const d = apiResponse.PolicyLobList[0].RiderDetailsList.filter(obj => obj.Rider === code);
      code_desc = d[0] ? d[0].BenefitDescription : code;
    }

    if (code_desc === code) {
      code_desc = list_desc[code];
    }

    return code_desc;
  }


  const getCarrierBasePremium = (node_name, code) => {

    let Premium = 0;

    if (node_name === 'AddonDetailsList') {
      const d = apiResponse.PolicyLobList[0].AddonDetailsList.filter(obj => obj.BenefitCode === code);
      Premium = d[0] ? d[0].CarrierBasePremium : 0;
    }
    if (node_name === 'RiderDetailsList') {
      const d = apiResponse.PolicyLobList[0].RiderDetailsList.filter(obj => obj.Rider === code);
      Premium = d[0] ? d[0].CarrierBasePremium : 0;
    }
    return Math.round(Premium * 1.18);
  }


  const { handleSubmit } = useForm();

  const onSubmitData = () => {
    setOld({ ...old, customArr: 'anoj' });
    setFormData({ ...old, 'uncheckedAddon': uncheckedAddon });
    setEditAddOnsFlag(false);
  }


  // eslint-disable-next-line
  const [careAddonMaster, setCareAddonMaster] = useState([
    { Addon: 'C0001772', ProductElementCode: 'ADDONDETAILS', CoverName: 'NCB Super', prodname: 'caresupreme', IsMandatory: (form_data.self_age > 45 && form_data.self_age <= 75 ? 'N' : 'Y') },
    { Addon: 'C0001774', ProductElementCode: 'ADDONDETAILS', CoverName: 'Annual Health check - up' },
    { Addon: 'C0001775', ProductElementCode: 'ADDONDETAILS', CoverName: 'Instant Cover' },
    { Addon: 'C0001776', ProductElementCode: 'ADDONDETAILS', CoverName: 'Claim Shield' },
    { Addon: 'C0001777', ProductElementCode: 'ADDONDETAILS', CoverName: 'Reduction in PED -1yr' },
    { Addon: 'C0001408', ProductElementCode: 'ADDONDETAILS', CoverName: 'Air Ambulance Cover', IsMandatory: 'Y' },
    { Addon: 'C0001499', ProductElementCode: 'ADDONDETAILS', CoverName: 'Wellness Program', IsMandatory: 'Y' },
    { Addon: 'C0001523', ProductElementCode: 'ADDONDETAILS', CoverName: 'Outpatient Treatment' }
  ]);
  // console.log("careAddonMaster", careAddonMaster);
  // eslint-disable-next-line
  const [healthAddons, setHealthAddons] = useState();

  useEffect(() => {
    if (paramsProductName !== 'ManipalCigna ProHealth Prime Active') {
      const array1Filtered = apiResponse.PolicyLobList[0].RiderDetailsList.filter(item => item.BenefitCode !== "");
      if (paramsProductName === 'ManipalCigna ProHealth Prime Protect' || paramsProductName === 'ManipalCigna ProHealth Prime Advantage') {

        const filteredData = maniplacignaAddonMaster.map(item2 => {
          const match = array1Filtered.find(item1 => item1.BenefitCode === item2.Addon);
          if (match) {
            return {
              ...match,
              ...item2
            };
          }
          return item2;
        });
        setHealthAddons(filteredData);

      } /* else {
  
        const filteredData = careAddonMaster.map(item2 => {
          const match = array1Filtered.find(item1 => item1.BenefitCode === item2.Addon);
          if (match) {
            return {
              ...match,
              ...item2
            };
          }
          return item2;
        });
  
        console.log('filteredData', filteredData);
  
        setHealthAddons(filteredData);
  
      } */
    }
    // eslint-disable-next-line
  }, [apiResponse, careAddonMaster, maniplacignaAddonMaster])

  // eslint-disable-next-line
  const [rider_val, set_rider_val] = useState({});



  // Assuming you have a mapping of related addons
  const relatedAddonsMap = {
    'C0001491': ['C0002019'],
    //  'C0002019': ['C0001491'],

    'C0002017': ['PC0002020'],
    'PC0002020': ['C0002017', 'PC0001491'],

    'PC0001491': ['PC0002020'],
    // Add more mappings as needed


  };
  // eslint-disable-next-line
  const dependentCodes1 = {
    'PC0001491': ['C0002017'],
    'PC0002020': ['PC0001491'],

  }

  const dependentCodes = (code) => {

    if (code === 'PC0001491') {
      return 'C0002017'
    } else if (code === 'C0002017') {
      // return 'PC0001491'
    } else if (code === 'C0001491') {
      return 'C0002019'
    } else if (code === 'C0002019') {
      // return 'C0001491'
    } else if (code === 'C0002018') {
      return 'C0002022'
    } else if (code === 'C0002022') {
      return 'C0002018'
    } else if (code === 'C0002021' && paramsProductName === 'ManipalCigna ProHealth Prime Active') { // non mandatory
      return ''
    } else if (code === 'C0002021' && paramsProductName !== 'ManipalCigna ProHealth Prime Active') { // in case Active product
      return 'C0001499'
    } else {
      return '';
    }

  }

  const IsMandatory = [
    "C0001523",
    "C0001499",
    (checkedAddon.includes('PC0001491') ? 'C0002017' : ''),
    (checkedAddon.includes('C0001491') ? 'C0002019' : '')
  ];


  const getRelatedAddons = (addonId) => {
    // Return related addons from the map or an empty array if not found
    return relatedAddonsMap[addonId] || [];
  };

  return (
    <div className="rightSide_menu_outer">
      <div className="rightSide_menu">
        <div className="rightSide_menu_header">
          <div>
            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => setEditAddOnsFlag(false)}> Back </button>
          </div>
          <div className="font14 fw500 color182">Update Add Ons Details</div>
          <div>&nbsp;</div>
        </div>
        <form onSubmit={handleSubmit(onSubmitData)}>
          <div className="pl20 pr20">

            { /* display Rider List */
              maniplacignaRiderMaster &&
              maniplacignaRiderMaster.map((value, index) => {
                return <>
                  {
                    value.ProductElementCode && value.ProductElementCode !== '' ?

                      (<>
                        <div className="mt20">
                          <div className="targetbar_title">
                            <label className="font14 fw500 color182">
                              <input type="checkbox" name={"rider_" + value.Rider} value={value.Rider}
                                onChange={e => {
                                  const addonId = e.target.value;
                                  
                                  if (e.target.checked) {
                                    // If the checkbox is checked, remove related addons if they are checked
                                    const relatedAddons = getRelatedAddons(addonId); // Assuming you have a function to get related addons
                                    setCheckedAddon(prev => [...prev.filter(item => !relatedAddons.includes(item)), addonId, dependentCodes(addonId)]);

                                  } else {
                                    // setCheckedAddon(prev => prev.filter(item => item !== addonId));
                                    setCheckedAddon(prev => prev.filter(item => ![addonId, dependentCodes(addonId)].includes(item)));

                                  }

                                }}

                                checked={checkedAddon.includes(value.Rider) ? true : false}

                              /> {getBenefitDescription('RiderDetailsList', value.Rider)}   {value.BenefitDescription} {/* value.Rider */}
                            </label>
                          </div>
                          <div className="flex_center justify_center mt8">
                          </div>
                        </div>

                        <AddOnsCard1 cover_term={1} premium={getCarrierBasePremium('RiderDetailsList', value.Rider)} />
                        <hr className="border-hr" />
                      </>) : <></>
                  }

                </>
              })
            }

            { /* display Addon List */
              maniplacignaAddonMaster &&
              maniplacignaAddonMaster.map((value, index) => {

                return <>
                  {
                    value.ProductElementCode && value.ProductElementCode !== '' ?
                      (<>
                        <div className="mt20">
                          <div className="targetbar_title">
                            <label className="font14 fw500 color182">
                              <input type="checkbox" name={"addons_" + value.Addon} value={value.Addon}
                                onChange={e => {
                                  const addonId = e.target.value;
                                  if (e.target.checked) {
                                    // If the checkbox is checked, remove related addons if they are checked
                                    const relatedAddons = getRelatedAddons(addonId); // Assuming you have a function to get related addons
                                    setCheckedAddon(prev => [...prev.filter(item => !relatedAddons.includes(item)), addonId, dependentCodes(addonId)]);


                                  } else {
                                    // setCheckedAddon(prev => prev.filter(item => item !== addonId));
                                    if (!IsMandatory.includes(value.Addon)) {
                                      setCheckedAddon(prev => prev.filter(item => ![addonId, dependentCodes(addonId)].includes(item)));
                                    }
                                  }
                                }}
                                checked={checkedAddon.includes(value.Addon) ? true : false}

                              /> {getBenefitDescription('AddonDetailsList', value.Addon) + value.Addon} {value.BenefitDescription}  - {/* value.BenefitCode */} {(IsMandatory.includes(value.Addon) ? <span className="required">*</span> : '')}
                            </label>
                          </div>
                          <div className="flex_center justify_center mt8">
                          </div>
                        </div>
                        {/*  <AddOnsCard1 cover_term={1} premium={value.GrossPremium ? value.GrossPremium : (Math.round(value.CarrierBasePremium * 1.18))} /> */}
                        <AddOnsCard1 cover_term={1} premium={getCarrierBasePremium('AddonDetailsList', value.Addon)} />

                        <hr className="border-hr" />
                      </>) : <></>
                  }
                </>
              })
            }

          </div>
          <div className="saveBtn backsaveBtn">
            <button type="button" className="btn btn-primary" onClick={() => setEditAddOnsFlag(false)}> Close </button>
            <button type="submit" className="btn btn-primary"> Save </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHealthAddOnsCigna;

const AddOnsCard1 = ({ cover_term, premium }) => {

  return (
    <div className="mt10 ">
      <Card className="pt10 pb10 pl20 pr20 card-bg-gray">
        <Row>
          <Col md={6}>
            <span className="text-type-3_1">Cover Term</span><br />
            <span className="font14 fw500 color182">{cover_term} Years</span>
          </Col>
          <Col md={6} className="text-right">
            <span className="text-type-3_1">Premium</span><br />
            <span className="font14 fw500 color182 ">₹ {premium ? formatAmount(premium) : '0'}/Year</span>
          </Col>
        </Row>
      </Card>
    </div>
  )
}