import {  useState } from "react";
import { useForm } from 'react-hook-form'
import { inWords } from "../../components/global";
//import Swal from "sweetalert2";
import 'rc-slider/assets/index.css';
import { useParams } from "react-router-dom";


const EditHealthCoverDetails = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    // eslint-disable-next-line
    const [old, setOld] = useState(props.form_data);

    const { paramsProductName } = useParams();

    // common list 
    let cover_values = [
        500000,
        700000,
        1000000,
        1500000,
        2000000,
        2500000,
        3000000,
        4000000,
        5000000,
        10000000
    ]
// for care only 7L, 10, 15, 25, 50 
if(paramsProductName === 'Care Supreme'){
    cover_values = [
        500000,
        700000,
        1000000,
        1500000,
        2000000,
        2500000,
        3000000,
        4000000,
        5000000,
        10000000
    ];
}

// MediCare Premier
if(paramsProductName === 'MediCare Premier'){
    cover_values = [ /*  500000, 700000, */ 1000000,  1500000, 2000000, 2500000,  3000000 , 4000000, 5000000, 10000000  ];
}

// MediCare Premier
if(paramsProductName === 'ManipalCigna ProHealth Prime Advantage' || paramsProductName === 'ManipalCigna ProHealth Prime Protect'){
    cover_values = [ 
       /*  500000,
        700000, */
        1000000,
        1500000,
        2000000,
        2500000,
        3000000,
        4000000,
        5000000,
        10000000
    ];
}

// ManipalCigna ProHealth Prime Active only 10L
if(paramsProductName === 'ManipalCigna ProHealth Prime Active'){
    cover_values = [  
        1000000
    ];
}



    // eslint-disable-next-line
    const [selected_cover, setSelectCover] = useState();

   /*  useEffect(() => {
        props.setFormData({ ...old }); // update value of previus component data
        // eslint-disable-next-line
    }, [old]); */

    const onSubmitData = data => {
       // setOld({ ...old, desired_cover_amount: data.desired_cover_amount });
       props.setFormData({ ...props.form_data, desired_cover_amount: data.desired_cover_amount })
       props.setSelectCover(data.desired_cover_amount);
       props.setEditPlanDetailsFlag(false)
       /*  Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#009356',
            text: 'Health Cover Updated successfully.',
        }).then(() => {
            props.setEditPlanDetailsFlag(false)
        }); */
    }

    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => props.setEditPlanDetailsFlag(false)}> Back </button>
                    </div>
                    <div className="font14 fw500 color182">Update Health Cover</div>
                    <div>&nbsp;</div>
                </div>
                <form id="form_edit_address" onSubmit={handleSubmit(onSubmitData)}>
                    <div className="pl20 pr20">
                        <div className="mt20">


                            <div className="targetbar_outer_   mt50">
                                <div className="targetbar_title">
                                    <div className="font14 fw500 color182">Cover required values <span className="colorfa8">*</span></div>
                                </div>
                                <div className="targetbar mt10">
                                    <form className="target_range_bar">
                                        <div className="form-group col-md-12">
                                            <ul className="steps_tag font13 fw500 color182 mt15">

                                                {
                                                    cover_values.map(function (v) {
                                                        return (

                                                            <li>
                                                                <label className="font14 color212 fw600" onClick={() => setSelectCover(v)} >
                                                                    <input className="cover_till" type="radio"
                                                                        defaultChecked={old.desired_cover_amount && (old.desired_cover_amount === v ? true : false)}
                                                                        name="desired_cover_amount" value={v}
                                                                        {...register("desired_cover_amount", { required: 'Cover required values is required' })}
                                                                    /><span className="rdo_text_box">{inWords(v)}</span></label>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <small className="form-text font11 text-danger">{errors.desired_cover_amount && errors.desired_cover_amount.message}</small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="saveBtn backsaveBtn">
                            <button type="button" className="btn btn-primary" onClick={() => props.setEditPlanDetailsFlag(false)}> Close </button>
                            <button type="submit" className="btn btn-primary"> Save </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditHealthCoverDetails;