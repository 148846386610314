import { React, Fragment, useState, useEffect } from "react";

import Title from "../../Title";
import DashboardHeader from "../../components/layout/DashboardHeader";
import { Link } from "react-router-dom";
import "./../LifeInsuranceDetails.css";
import Slider from "rc-slider";
import { useForm } from "react-hook-form";
import ProposerDetailsForm from "./ProposerDetailsForm";
import InsuredBasicForm from "./InsuredBasicForm";
import { navigateTo } from "../../components/global";

import "../../css/header.css";
import "../../css/style.css";
import "./../Home.css";

export default function ProposalState() {

  const { register, handleSubmit, setError, formState: { errors }, } = useForm();
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Term Plan: Quote");

  const [SIPInvestmentAmount, setSIPInvestmentAmount] = useState(350);

  const [step, setStep] = useState(1);
  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };

  const [form_title, setFormTitle] = useState("");
  const [form_subtitle, setFormSubTitle] = useState("");
  // eslint-disable-next-line
  const [form_data, setFormData] = useState({});

  useEffect(
    function () {
      if (step === 1) {
        setFormTitle("Proposer Details");
        setFormSubTitle("Some basic detail about you.");
      }
      if (step === 2) {
        setFormTitle("Insured Basic");
        setFormSubTitle("Some basic detail about you");
      }
      if (step >= 3) {
        setFormTitle("Health Questionnaires");
        setFormSubTitle("Fill out the basic details for the people");
        navigateTo('./proposal-stage-health');
      }
    },
    [step, form_data]
  );

  // submit form data
  const onSubmit = (data) => {
    handleNext();
  };

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <Link to={"#"}>Insurance Advise</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <Link to={"#"}>Term Insurance</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <span>ICICI Prudential Life Insurance</span>
          </div>

          <div className="row mt10 life-insurance-details">
            <div className="col-md-8">
              <div className="card pt20">
                <div className="card-body-text life-insourance-buy proposer-details-form-section ">

                  <form id="form_1" onSubmit={handleSubmit(onSubmit)}>

                    <div className="life-lnsurance-logo-section">
                      <ul className="form_swipe_tab font12 fw600">
                        {Array.from({ length: 7 }, (_, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setStep(index + 1);
                            }}
                            className={step === index + 1 && "act"}
                          >
                            {index + 1}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="formtitle flex_center justify_center">
                      <div>
                        <div className="font18 fw600">{form_title}</div>
                        <div className="font15 color495 ">{form_subtitle}</div>
                      </div>
                      <div>
                        {step === 4 ? (
                          <> <button className="bluebtn font13 fw600 d-none d-md-block" type="submit" > Save  </button> </>
                        ) : (
                          <> <button className="bluebtn font13 fw600 d-none d-md-block" type="submit"  > Continue <i className="fa-solid fa-chevron-right font12"></i> </button>  </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form_prnt pt25 pl35 pr35 pb25">
                      <div className="custome_form mobile-top-margin-20">
                        {step === 1 && <ProposerDetailsForm register={register} errors={errors} setError={setError} />}
                        {step === 2 && <InsuredBasicForm register={register} errors={errors} setError={setError} />}
                      </div>
                    </div>

                    <div className="saveBtn d-block d-sm-none">
                      <button type='submit' className="btn btn-primary">Continue <i className="fa-solid fa-chevron-right font12"></i> </button>
                    </div>

                  </form>
                </div>
              </div>

              <div className="mb50"></div>
            </div>{" "}
            {/** end of left section col-md-8 */}
            <div className="col-md-4">
              {/** start of right section col-md-4 */}
              <div className="card p20 ">
                <div className="row">
                  <div className="col-md-12">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <span className="text-type-1">Cover</span>
                          <br />
                          <span className="heading-3">
                            ₹ 50 Lakh till 50 year
                          </span>
                        </td>
                        <td>
                          <span className="text-type-1">Monthly pay</span>
                          <br />
                          <span className="heading-3  color-primary">
                            ₹ 1,503 /month
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-12">
                    <hr className="border-hr" />
                  </div>
                  {step === 1 &&
                    <div className="col-md-12">
                      <h5 className="heading-3 mb10">Product Details</h5>
                      <table className="text-type-4 table-product-details">
                        <tr> <td style={{ 'width': '33.3333%' }}></td><td style={{ 'width': '33.3333%' }}></td><td style={{ 'width': '33.3333%' }}></td> </tr>
                        <tr>
                          <td><label className="text-type-4">Plan Name</label></td>
                          <td colSpan="2" className="text-right"><label className="text-type-4">ICICI Pru iProtect Smart</label></td>
                        </tr>
                        <tr>
                          <td colSpan="2"><label className="text-type-4">Policy Term (in yrs)</label></td>
                          <td className="text-right"><label className="text-type-4">40</label></td>
                        </tr>
                        <tr>
                          < td colSpan="2"><label className="text-type-4">Guaranteed Maturity Benefit</label></td>
                          <td className="text-right"><label className="text-type-4">N.A.</label></td>
                        </tr>
                        <tr> <td colSpan="2"><label className="text-type-4">Mode</label></td>
                          <td className="text-right"><label className="text-type-4">Yearly</label></td>
                        </tr>
                        <tr>
                          <td colSpan="2"><label className="text-type-4">Benefit Option</label></td>
                          <td className="text-right">Life</td>
                        </tr>
                        <tr>
                          <td colSpan="2"><label className="text-type-4">Death Benefit Option</label></td>
                          <td className="text-right"><label className="text-type-4">Lump-Sum</label></td>
                        </tr>
                      </table>
                    </div>
                  }

                  <div className="col-md-12 mt20">
                    <h5 className="heading-3 mb10">Terms and add ons</h5>
                    <div className="">
                      <label className="text-type-2"> <input type="checkbox" name="" /> Premium (in INR) </label>
                      <span className="btn-display-amount"> <i className="fa fa-inr" aria-hidden="true"></i> 2,05,230  </span>
                      <Slider
                        step={1}
                        name="cover_amount"
                        className="mt15"
                        min={5}
                        max={500}
                        onChange={(e) => {
                          setSIPInvestmentAmount(e);
                        }}
                        pushable={true}
                        value={SIPInvestmentAmount}
                      />
                      <span className="text-type-3">Cover amount ₹ 25 Lakhs</span>
                    </div>

                    <div className="col-md-12 row mt30">
                      <label className="text-type-2">
                        <input type="checkbox" name="" /> Accidental Death
                        Benefit{" "}
                      </label>
                    </div>
                    <hr className="border-hr" />
                    <div className="row">
                      <div className="col-md-7 ">
                        <label className="text-type-4"> Base Amount </label>
                      </div>
                      <div className="col-md-5 text-right">
                        {" "}
                        <span> ₹ 1,392.00</span>{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7">
                        <label className="text-type-4"> GST (18%)</label>{" "}
                      </div>
                      <div className="col-md-5 text-right">
                        {" "}
                        <span>₹ 139.50 </span>{" "}
                      </div>
                    </div>
                    <hr className="border-hr" />

                    <div className="total-to-pay-area">
                      <span>Total to pay (incl. of taxes) </span>{" "}
                      <span className="ml50 bold">₹ 1,503</span>
                    </div>
                    <div className="text-center  mt5 mb20">
                      <span className="text-tax-saving make-circle">%</span>
                      <span className="text-tax-saving ml5">
                        80c tax saving ₹2,728.00 per year
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt30">
                <div className="avatar-display text-center">
                  <img src="images/avatar-1.png" alt="" />
                  <img src="images/avatar-2.png" alt="" />
                  <img src="images/avatar-3.png" alt="" />
                  <img src="images/avatar-1.png" alt="" />
                  <img src="images/avatar-2.png" alt="" />
                </div>
                <h3 className="heading-3  text-center mt10 mb10">
                  Need Assistant?
                </h3>
                <p className="text-type-1">
                  We are here to help you. Visit the support section to get
                  quick answers <Link to={"#"}>Get Support</Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
