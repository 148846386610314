import { React, Fragment, useState, useEffect } from "react";

import Title from "../Title";
import DashboardHeader from "../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./LifeInsuranceDetails.css";
import Global from "../Global";
import Assistant from "./components/Assistant";
import CardHealthPremiumDetails1 from "./health-insurance/CardHealthPremiumDetails1";
import CardHealthPremiumDetails2 from "./health-insurance/CardHealthPremiumDetails2";
import http from "../http";
import Swal from "sweetalert2";
import EditHealthCoverDetails from "./components/EditHealthCoverDetails";
import EditAddress from "./components/EditAddress";
import { calculateDOB, decodeSumInsured, get_gender_short } from "../components/global";

import Loader from "../components/Loader";

export default function HealthInsuranceDetails() {

  const [loading, setLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("Health Plan: Quote");

  const getCurrentDate = (offsetDays = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + offsetDays);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle("Health Plan: Quote");
  }, []);

  const { paramsProductName } = useParams();

  // key is CarrierCode     
  const logo = (CarrierCode) => {

    switch (CarrierCode) {
      case 'CARE':
        return Global.base_url + '/images/logo/care-helth.png';
      case 'HDFC':
        return Global.base_url + '/images/logo/hdfc-life.png';
      case 'ICICI':
        return Global.base_url + '/images/logo/iprotect-smart.png';
      case 'CIGNA':
        return Global.base_url + '/images/logo/cigna.png';
      case 'TATAAIG':
        return Global.base_url + '/images/logo/TATAAIG.png';
      default:
        return '';
    }
  }

  // form_data is Health Insourence Plan
  const [form_data, setFormData] = useState(localStorage.getItem('form_data') ? JSON.parse(localStorage.getItem('form_data'), (key, value) => {
    if (value === 'true' || value === true) return true;
    else if (value === 'false' || value === false) return false;
    else if (value === '') return '';
    else if (!isNaN(value)) return parseInt(value);
    return value;
  }) : {});

  useEffect(() => {
    localStorage.setItem('form_data', JSON.stringify(form_data));
  }, [form_data]);



  /***Edit Basic Info start */
  // eslint-disable-next-line
  const [editBasicInfoFlag, setEditBasicInfoFlag] = useState(false);
  const [editPlanDetailsFlag, setEditPlanDetailsFlag] = useState(false);
  // eslint-disable-next-line
  const [editAddOnsFlag, setEditAddOnsFlag] = useState(false);
  const [editEddressFlag, setEditAddressFlag] = useState(false);
  const [SelectedAddonDetailsList, setSelectedAddonDetailsList] = useState([]);

  /***Edit Basic Info end */
  // eslint-disable-next-line
  const [memberCount, setMemberCount] = useState(0);
  const [selected_cover, setSelectCover] = useState(form_data.desired_cover_amount && form_data.desired_cover_amount);

  useEffect(() => {
    setFormData({ ...form_data, desired_cover_amount: selected_cover });
    // eslint-disable-next-line
  }, [selected_cover])



  useEffect(() => {
    let c = 0;
    c += (form_data.self === 'true' || form_data.self ? 1 : 0);
    c += (form_data.spouse === 'true' || form_data.spouse ? 1 : 0);
    c += (form_data.son_arr ? (form_data.son_arr).length : 0);
    c += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);
    setMemberCount(c);
    localStorage.setItem('form_data', JSON.stringify(form_data));
  }, [selected_cover, form_data])

  const [api_response, set_api_response] = useState();
  const [product_name, setProductName] = useState("");
  const [logo_url, setLogoUrl] = useState();

  const fetchData = () => {
    

    let ChildCount = (form_data.son_arr ? (form_data.son_arr).length : 0);
    // eslint-disable-next-line
    ChildCount += (form_data.daughter_arr ? (form_data.daughter_arr).length : 0);


    let maxAge = form_data.self_age;
    for (let key in age_group) {
      if (age_group[key] !== "" && !isNaN(age_group[key])) {
        let age = parseInt(age_group[key]);
        if (age > maxAge) {
          maxAge = age;
        }
      }
    }

    const post_data_new = {
      ProductVersion: "1.0",
      ProductTag: "1",
      HealthPolicyType: "1",
      LOBCode: "2",
      FFCategory: "1",
      SumInsured: decodeSumInsured(selected_cover), // "2", //form_data.desired_cover_amount.toString(), // "1000000",
      IsSelfCovered: "No",
      EffectiveDate: getCurrentDate(),
      ExpiryDate: getCurrentDate(364),
      PolicyCustomerList: [
        {
          DateOfBirth: calculateDOB(30), // "1990-11-26",
          PostCode: form_data.pin ? (form_data.pin).toString() : "400093", //"411033",
          City: form_data.city ? form_data.city : "", // "PUNE",
          GenderCode: get_gender_short(form_data.gender) //"M"
        }
      ],
      PolicyLobList: [
        {
          ProductCode: "FINHEALTH",
          PolicyRiskList: [
            {
              ProductElementCode: "R00001",
              DateOfBirth: calculateDOB(30), // "1990-11-26",
              GenderCode: get_gender_short(form_data.gender), //"M",
              InsuredRelation: "1"
            }
          ]
        }
      ]
    }

    /*if(addonArr.length > 0){
     post_data_new['PolicyLobList'][0]['AddonDetailsList'] = addonArr;
    }*/

    
    const post = {
      form_data: form_data,
      api_post_data: post_data_new
    }
    console.log('post params post_data_new ', post_data_new);


    http.post('/health/loadQuote', post).then((response) => {

      sessionStorage.setItem('healthpostData', JSON.stringify(post));

      const resp = response.data;

      const filteredData = resp.filter((obj) => obj.ProductName === paramsProductName);
      setLoading(false);
      if (filteredData && filteredData[0] && filteredData[0].ProductName) {
        //alert("resp.AbacusId" + resp.AbacusId);
        set_api_response(filteredData[0]);
        setProductName(filteredData[0].ProductName);
        setLogoUrl(logo(filteredData[0].CarrierCode));

      } else if (resp.Status && resp.Status === 0) {

      } else if (resp.status && resp.status === 401) {
        localStorage.removeItem('access_token');
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.Message,
        }).then(() => {
          window.location.href = Global.base_url;
        })
      } else if (resp.CarrierCode && resp.ClientRequestId) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.Message,
        });
      } else if (resp.ResponseCode && resp.ResponseCode === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.Message,
        });
      } else if (resp[0] && resp[0].error_details) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp[0].error_details.error_message,
        })
      } else if (resp.Message) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: resp.Message,
        });
      } else {
        /* Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: JSON.stringify(resp),
        }) */
      }

    });
  }
  // eslint-disable-next-line
  const [age_group, set_age_group] = useState([]);

  useEffect(() => {

    setTimeout(function () {
      fetchData();
    }, 300);
    // eslint-disable-next-line
  }, [form_data]);


  return (

    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Insurance Advise</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <Link to={"#"}>Health Insurance</Link><i className="fa fa-angle-right font13" aria-hidden="true"></i>
            <span>{product_name}</span>
            <Link to={"/health-plan/quote"} className="bluebtn" style={{ position: 'absolute', right: '9px', color: 'white' }}><i className="fa fa-angle-left font13 mr5" aria-hidden="true"></i> Back</Link>
          </div>
          {loading ? (
            <Loader /> // Render the loader when loading is true
          ) :
            <div className="row mt20 life-insurance-details">
              <div className="col-md-8">
                <div className="card p20">

                  <div className="row">
                    <div className="col-md-12 life-lnsurance-logo-section">
                      <table className="">
                        <tr>
                          <td style={{ 'maxWidth': '150px' }}>{logo_url && <img src={logo_url} alt="" className="logo maxWidth100" />} </td>
                          <td style={{ 'paddingLeft': "10px" }}>
                            <h2 className="heading-1">  {product_name}  </h2>

                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr className="border-hr" />
                  {
                    api_response.PolicyLobList[0].FeaturesList && (
                      <>
                        <div className="card-body-text extra-benefits">
                          <h3 className="heading-2 mt10 mb10">Features</h3>
                          <div className="row">

                            {api_response.PolicyLobList[0].FeaturesList.map((item, index) => (

                              <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                                <h5 className="heading-3">{item.BenefitDescription}</h5>
                                <p className="text-type-1">
                                  {item.Notes}
                                </p>
                              </div>

                            ))}
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    api_response.PolicyLobList[0].HospitalizationBenefitList && (
                      <>
                        <div className="card-body-text extra-benefits">
                          <h3 className="heading-2 mt10 mb10">Hospitalization Benefit</h3>
                          <div className="row">

                            {api_response.PolicyLobList[0].HospitalizationBenefitList.map((item, index) => (

                              <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                                <h5 className="heading-3">{item.BenefitDescription}</h5>
                                <p className="text-type-1">
                                  {item.Notes}
                                </p>
                              </div>

                            ))}
                          </div>
                        </div>
                      </>
                    )
                  }

                </div>

                <div className="row mt20">
                  <div className="col-md-12">
                    <div className="card p20 mt20">
                      <div className="card-body-text extra-benefits">
                        <h3 className="heading-2 mt10 mb10">Additional Features</h3>
                        <div className="row">
                          {api_response.PolicyLobList[0].AdditionalFeaturesList.map((item, index) => (

                            <div className="col-md-6 item mobile-lp0 mobile-rp0-i" key={index}>
                              <h5 className="heading-3">{item.BenefitDescription}</h5>
                              <p className="text-type-1">
                                {item.Notes}
                              </p>
                            </div>

                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> {/** end of left section col-md-8 */}


              <div className="col-md-4">{/** start of right section col-md-4 */}
                {
                  api_response && api_response.ProductName && <>
                    <CardHealthPremiumDetails1 api_response={api_response} setFormData={setFormData} form_data={form_data} setEditPlanDetailsFlag={setEditPlanDetailsFlag} setSelectCover={setSelectCover} setEditAddressFlag={setEditAddressFlag} />
                    <CardHealthPremiumDetails2 api_response={api_response} className="mt20" setSelectedAddonDetailsList={setSelectedAddonDetailsList} SelectedAddonDetailsList={SelectedAddonDetailsList}/>
                  </>
                }

                <Assistant className="mt30" />

              </div>
            </div>
          } {/** loader else end  */}

        </div>
      </div>

      {
        editPlanDetailsFlag && <EditHealthCoverDetails setEditPlanDetailsFlag={setEditPlanDetailsFlag} setFormData={setFormData} form_data={form_data} setSelectCover={setSelectCover} selected_cover={selected_cover} />
      }

      {
        editEddressFlag && <EditAddress setEditAddressFlag={setEditAddressFlag} session_name={'form_data'} />
      }


    </Fragment>
  );
}