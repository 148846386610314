const HealthQuestionaire = [
  {
    id: 1,
    question: "Has an ailment or disability or deformity including due to accident or congenital disease?.",
    text: "Select member(s) and disease which is diagnosed",
    sub_question: [
      {
        id: 101,
        question: "Select member(s) and disease which is diagnosed",
      },
      {
        id: 102,
        question: "Hypertension/ High blood pressure",
      },
      {
        id: 103,
        question: "Diabetes/ High blood sugar/Sugar in urine",
      },
      {
        id: 104,
        question: "Cancer, Tumour, Growth or Cyst of any kind",
      },
      {
        id: 105,
        question: "Chest Pain/ Heart Attack or any other Heart Disease/ Problem ",
      },
      {
        id: 106,
        question: "Liver or Gall Bladder ailment/Jaundice/Hepatitis B or C",
      },
      {
        id: 107,
        question: "Kidney ailment or Diseases of Reproductive organs",
      },
      {
        id: 108,
        question:
          "Ulcer (Stomach/ Duodenal), or any ailment of Digestive System",
      },
      {
        id: 109,
        question:
          "Any Blood disorder (example Anaemia, Haemophilia, Thalassaemia) or any genetic disorder",
      },
      {
        id: 110,
        question: "HIV Infection/AIDS or Positive test for HIV",
      },
      {
        id: 111,
        question: "Nervous, Psychiatric or Mental or Sleep disorder",
      },
      {
        id: 112,
        question: "Stroke/ Paralysis/ Epilepsy (Fits) or any other Nervous disorder (Brain/ Spinal Cord etc.)",
      },
      {
        id: 113,
        question: "Abnormal Thyroid Function/ Goiter or any Endocrine organ disorders",
      },
      {
        id: 114,
        question: "Eye or vision disorders/ Ear/ Nose or Throat diseases",
      },
      {
        id: 115,
        question:  "Arthritis, Spondylitis, Fracture or any other disorder of Muscle Bone/ Joint/ Ligament/ Cartilage",
      },
      {
        id: 116,
        question: "Any other disease/condition not mentioned above",
      },
    ],
  },
  {
    id: 2,
    question: "Has planned a surgery?",
    text: "Select if applicable",
    sub_question: []

  },
  {
    id: 3,
    question: "Takes medicines regularly?",
    text: "Select if applicable",
    sub_question: []
  },
  {
    id: 4,
    question: "Has been advised investigation or further tests",
    text: "Select if applicable",
    sub_question: []
  },
  {
    id: 5,
    question: "Was hospitalized in past?",
    text: "Select if applicable",
    sub_question: []
  },
  {
    id: 6,
    question:"Is pregnant? If yes, please mention the expected date of delivery",
    text: "Select if applicable",
    sub_question: []
  },
];

export default HealthQuestionaire;
