import formatAmount from "indian-currency-formatter";
import { Fragment, useEffect, useState } from "react";
import EditHealthAddOns from "../components/EditHealthAddOns";
import { Link } from "react-router-dom";

const CardHealthPremiumDetails2 = ({ api_response  }) => {
  const data = api_response;


  const ContinueToBuy = (id) => {
    window.location = '/pre-proposal-stage/' + id;
  }

  // eslint-disable-next-line
  const [uncheckedAddon, setUncheckedAddon] = useState([]);
  // eslint-disable-next-line
  const [checkedAddon, setCheckedAddon] = useState(['C0001408', 'C0001499', 'C0001422', 'ADDONDETAILS']);

  const [DuePremium, setDuePremium] = useState(parseFloat(data.DuePremium));
  const [base_amount, setBaseAmount] = useState(DuePremium);
  const [AddonDetailsList, setAddonDetailsList] = useState([]);
  const [editAddOnsFlag, setEditAddOnsFlag] = useState(false);
  const [addonAmount, setAddonAmount] = useState(0);
  useEffect(() => {
    setDuePremium(parseFloat(data.DuePremium));

    if(data.PolicyLobList[0].AddonDetailsList){
      const AddonSum = data.PolicyLobList[0].AddonDetailsList.reduce((acc, current) => {
        if (current.ProductElementCode) {
          return acc + parseInt(current.GrossPremium ? current.GrossPremium : current.CarrierBasePremium);
        } else {
          return acc;
        }
      }, 0);
      setAddonAmount(AddonSum);
    }


  }, [data])

  // eslint-disable-next-line
  const handleBaseAmount = (e) => {
    const select_amount = parseFloat(e.target.value);
    const index_arr = e.target.name.split("_");
    const selected_addons_index = parseInt(index_arr[1]);


    if (e.target.checked) {
      setBaseAmount(base_amount + select_amount);
      setAddonDetailsList([...AddonDetailsList, selected_addons_index]);
    } else {
      setBaseAmount(base_amount - select_amount);
      const newArray = AddonDetailsList.filter(item => item !== selected_addons_index);
      setAddonDetailsList(newArray);
    }
  }

  useEffect(() => {

    console.log('uncheckedAddon before', uncheckedAddon);

  }, [uncheckedAddon])


  return (
    <Fragment>
      <div className={"card p20 "}>
        <div className="row">
          <div className="col-md-12">

            <table style={{ 'width': '100%' }}>
              <tr>
                <td className="height40">
                  <span className="heading-3">Premium Amount</span>
                </td>
                <td className="text-right ">
                  <span className="btn-display-amount-2"> ₹  {DuePremium && formatAmount(Math.round(DuePremium))}</span>
                </td>
              </tr>
            </table>
            <hr className="border-hr" />
            <table style={{ 'width': '100%' }}>



              {
                data.PolicyLobList &&
                data.PolicyLobList[0].AddonDetailsList &&
                <>
                  {
                    data.PolicyLobList[0].AddonDetailsList.some(item => item.hasOwnProperty('ProductElementCode')) &&
                    <>
                      <tr>
                        <td style={{ width: '65%' }}>
                          <span className="heading-3">Add ons</span>
                        </td>
                        <td className="text-right" style={{ width: '40%' }}>
                          <div className="title_viewmore">
                            <Link title="Edit My Inputs Details" to="#" onClick={() => { setEditAddOnsFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                          </div>
                        </td>
                      </tr>
                    </>
                  }

                </>
              }

              {
                data.PolicyLobList &&
                data.PolicyLobList[0].AddonDetailsList &&
                data.PolicyLobList[0].AddonDetailsList.map((value, index) => {
                  return (<>
                    {
                      value.ProductElementCode && <>
                        <tr>
                          <td className="height50">
                            <label className="text-type-2"><input type="checkbox" name={"index_" + index} defaultValue={value.CarrierBasePremium ? value.CarrierBasePremium : value.GrossPremium} checked={checkedAddon.includes(value.ProductElementCode) ? true : false} readOnly={true} disabled={true} /> {value.Description ? value.Description : value.CarrierAddonProductId} </label>
                            <div className="text-type-3 ml20 p"></div>
                          </td>
                          <td className="text-right">
                            <span className="btn-display-amount-2"> ₹ {value.CarrierBasePremium ? formatAmount(value.CarrierBasePremium) : formatAmount(value.GrossPremium)}
                            </span>
                          </td>
                        </tr></>
                    }
                  </>
                  )

                })
              }
            </table>
          </div>

          <div className="col-md-12">
            {data.PolicyLobList && data.PolicyLobList[0].AddonDetailsList && (data.PolicyLobList[0].AddonDetailsList.some(item => item.hasOwnProperty('ProductElementCode')) && <hr className="border-hr" />)}

            <div className="total-to-pay-area" style={{ 'lineHeight': '1.5' }}>
              <span>Total Premium (incl. of taxes) </span> <span className="ml30 bold">₹ {base_amount && formatAmount(Math.round(base_amount + ((base_amount * 18) / 100) + addonAmount))}</span>
            </div>


            <div className="text-center  mt5 mb20">

            </div>
            <input type="button" className="btn btn-primary continue-to-buy-btn " value="Continue to buy" onClick={() => { (data.AbacusId || data.DuePremium) && ContinueToBuy(data.AbacusId) }} />

          </div>
        </div>
      </div>



      {
        editAddOnsFlag && <EditHealthAddOns apiResponse={api_response} setEditAddOnsFlag={setEditAddOnsFlag} checkedAddon={checkedAddon} setCheckedAddon={setCheckedAddon} uncheckedAddon={uncheckedAddon} setUncheckedAddon={setUncheckedAddon} />
      }
    </Fragment>
  )
}

export default CardHealthPremiumDetails2;