import { useState } from "react";
import { useForm } from 'react-hook-form'
import { inWords } from "../../components/global";
//import Swal from "sweetalert2";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const EditPlanDetails = ({ setEditPlanDetailsFlag, setFormData, form_data, setCheckedIciciAddon, checkedIciciAddon, setAddonCheckbox }) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    //const [old, setOld] = useState(form_data);

    console.log('form data 2', form_data);

    const cover_values = [
        5000000,
        7500000,
        10000000,
        15000000,
        20000000,
        22500000,
        30000000,
    ]
    // eslint-disable-next-line
    const [minCover, setMinCover] = useState(form_data.age + 5);
    // eslint-disable-next-line
    const [maxCover, setMaxCover] = useState(99);

    const [coverTill, setCoverTill] = useState(form_data.cover_till ? form_data.cover_till : 60);
    // eslint-disable-next-line
    const [selected_cover, setSelectCover] = useState(0);

    const onSubmitData = data => {

        localStorage.setItem('form_data_term_plan', JSON.stringify({ ...form_data, cover_till: coverTill, cover_required_values: data.cover_required_values, SumInsured: data.cover_required_values }));
    
        //setOld({ ...old, cover_till: coverTill, cover_required_values: data.cover_required_values, SumInsured: parseInt(data.cover_required_values) });
        setFormData({ ...form_data, cover_till: coverTill, PPT:"" , cover_required_values: data.cover_required_values, SumInsured: data.cover_required_values })
        setAddonCheckbox(true);
        setEditPlanDetailsFlag(false);

        setCheckedIciciAddon([]);

        /*Swal.fire({
            icon: 'success',
            title: 'Success!',
            confirmButtonColor: '#009356',
            text: 'Plan Details Updated successfully.',
        }).then(() => {
            setEditPlanDetailsFlag(false);

            setCheckedIciciAddon([]);
        });*/
    }

    return (
        <div className="rightSide_menu_outer">
            <div className="rightSide_menu">
                <div className="rightSide_menu_header">
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => setEditPlanDetailsFlag(false)}> Back </button>
                    </div>
                    <div className="font14 fw500 color182">Update Plan Details</div>
                    <div>&nbsp;</div>
                </div>
                <form id="form_edit_address" onSubmit={handleSubmit(onSubmitData)}>
                    <div className="pl20 pr20">
                        <div className="mt20">
                            <div className="targetbar_outer_  mt30">
                                <div className="targetbar_title">
                                    <div className="font14 fw500 color182">Cover till <span className="colorfa8">*</span></div>
                                    <div>
                                        <div className="tragetvalue font13 fw700 colorf47"><span className="orangeDot mr5"></span>{coverTill} Yr</div>
                                    </div>
                                </div>
                                <div className="targetbar mt10">
                                    <form className="target_range_bar">
                                        <div className="form-group">
                                            <Slider step={1} name={"desire"} className='mt15' min={minCover} max={90} onChange={e => { setCoverTill(e) }} pushable={true}
                                                value={coverTill}
                                            />
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="flex_center justify_center mt8 pl20 pr25">
                                <div className="valuecount valuecount_blue font14 fw400">{minCover} Yr</div>
                                <div className="valuecount valuecount_gold font14 fw400">{maxCover} Yr</div>
                            </div>

                            <div className="targetbar_outer_   mt50">
                                <div className="targetbar_title">
                                    <div className="font14 fw500 color182">Cover required values <span className="colorfa8">*</span></div>
                                </div>
                                <div className="targetbar mt10">
                                    <form className="target_range_bar">
                                        <div className="form-group col-md-12">
                                            <ul className="steps_tag font13 fw500 color182 mt15">

                                                {
                                                    cover_values.map(function (v) {
                                                        return (

                                                            <li>

                                                                <label className="font14 color212 fw600" onClick={() => setSelectCover(v)} >
                                                                    <input className="cover_till" type="radio"
                                                                        defaultChecked={form_data.cover_required_values && (parseInt(form_data.cover_required_values) === parseInt(v) ? true : false)}
                                                                        name="cover_required_values" value={v}
                                                                        {...register("cover_required_values", { required: 'Cover required values is required' })}
                                                                    /><span className="rdo_text_box">{inWords(v)}</span></label>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <small className="form-text font11 text-danger">{errors.cover_required_values && errors.cover_required_values.message}</small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="saveBtn backsaveBtn">
                            <button type="button" className="btn btn-primary" onClick={() => setEditPlanDetailsFlag(false)}> Close </button>
                            <button type="submit" className="btn btn-primary"> Save </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPlanDetails;