import React, { Fragment, useState } from "react";

import DashboardHeader from "../components/layout/DashboardHeader";
import 'rc-slider/assets/index.css';
import '../css/global.css';
import './ComparePlans.css';
import Title from "../Title";
import { inWords } from "../components/global";

import Global from "../Global";
import { Link } from "react-router-dom";

export default function ComparePlans() {
    // eslint-disable-next-line
    const [pageTitle, setPageTitle] = useState('compare-plans');


    const planObj = [
        {
            id: 1,
            name: 'ICICI Pru Life',
            premium: 15000,
            cover: 5000000,
            logo: 'images/logo/icici-pru-life.png',
            badge: 'Popular',
            attributes: {
                Coverage: '2 Cr',
                CoverUpto: '65 Years',
                ClaimSettlementRatio: 99.51,
                ClaimComplaintsRatio: 3,
                SolvencyRatio: 2.08,
                GrievancesSolved: 100,
                Inception: 2000,
                NumberofBranches: 239,
                SpecialExit: true,
                TerminalIllness: true,
                NomineeChoicepayout: true,
                VoluntaryTopUp: true,
                TaxBenefits: true,
                AccidentalDeath: true,
                CriticalIllnessAndDisability: true,
                WaiverOfPremiumOnDisability: true,
                WaiverOfPremiumOnCI: false,
                AccidentalDisabilityBenefit: false,
                CriticalIllness: false,
                CancerCover: false,
                DeathCoverage: true,
                SuicidalCover: false
            }
        },
        {
            id: 2,
            name: 'HDFC Life',
            premium: 20000,
            cover: 7000000,
            logo: 'images/logo/hdfc-life.png',
            badge: 'Recommended',
            attributes: {
                Coverage: '2.1 Cr',
                CoverUpto: '65 Years',
                ClaimSettlementRatio: 99.51,
                ClaimComplaintsRatio: 3,
                SolvencyRatio: 2.08,
                GrievancesSolved: 100,
                Inception: 2000,
                NumberofBranches: 239,
                SpecialExit: true,
                TerminalIllness: true,
                NomineeChoicepayout: true,
                VoluntaryTopUp: true,
                TaxBenefits: true,
                AccidentalDeath: true,
                CriticalIllnessAndDisability: true,
                WaiverOfPremiumOnDisability: true,
                WaiverOfPremiumOnCI: false,
                AccidentalDisabilityBenefit: false,
                CriticalIllness: false,
                CancerCover: false,
                DeathCoverage: true,
                SuicidalCover: true
            }
        },
        {
            id: 3,
            name: 'TATA AIA Life',
            premium: 25000,
            cover: 10000000,
            logo: 'images/logo/tata-aia-life.png',
            badge: '',
            attributes: {
                Coverage: '2 Cr',
                CoverUpto: '65 Years',
                ClaimSettlementRatio: 99.51,
                ClaimComplaintsRatio: 3,
                SolvencyRatio: 2.08,
                GrievancesSolved: 100,
                Inception: 2000,
                NumberofBranches: 239,
                SpecialExit: true,
                TerminalIllness: true,
                NomineeChoicepayout: true,
                VoluntaryTopUp: true,
                TaxBenefits: true,
                AccidentalDeath: true,
                CriticalIllnessAndDisability: true,
                WaiverOfPremiumOnDisability: true,
                WaiverOfPremiumOnCI: false,
                AccidentalDisabilityBenefit: false,
                CriticalIllness: false,
                CancerCover: false,
                DeathCoverage: true,
                SuicidalCover: true
            }
        }

    ]

    const DATA = [planObj[0].attributes, planObj[1].attributes, planObj[2].attributes];

    const table_header = {
        'background': 'lightgray',
        'font-size': '20px'
    }
    
    const YesNoIcon = (flag) => {
        return flag === true ? <i className="fa fa-check-circle fa-2x c-blue" aria-hidden="true"></i> : <i className="fa fa-times-circle fa-2x c-red"></i>
    }

    return (
        <Fragment>
            <Title title={pageTitle} />
            <DashboardHeader />
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className="form_title">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mt30 font21 fw600 color384">Compare Plan</div>
                            </div>
                            <div className="col-md-6 text-right pt30 pr30">
                                <Link className="btnBlue" to={'/term-plan/quote'}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link>
                            </div>
                        </div>

                        {/*   <div className="font16 color626">
                Compare Plan title....
            </div>  */}
                    </div>

                    <div className="dashboard_outer mt30" style={{ display: 'block' }}>

                        <div className="mainwrap">
                            <div className="detailtabl">
                                <div className="tbaltitle fw500"></div>
                                <div className="table-responsive">
                                    <table className="table table-compare-plans">
                                        <thead className="sticky-row">
                                            <tr >
                                                <th style={{ width: '40%' }}></th>
                                                <th style={{ width: '20%' }}><img className="logo" src={DATA[0] && planObj[0].logo} alt={DATA[0] && planObj[0].name} /></th>
                                                <th style={{ width: '20%' }}><img className="logo" src={DATA[1] && planObj[1].logo} alt={DATA[0] && planObj[1].name} /></th>
                                                <th style={{ width: '20%' }}><img className="logo" src={DATA[2] && planObj[2].logo} alt={DATA[0] && planObj[2].name} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td className="align-left">
                                                    <label>Coverage</label>
                                                    <select className="form-control">
                                                        {
                                                            ['25L', '35L', '50L', '70L', '1 Cr', '2 Cr', '3 Cr'].map((v) => {
                                                                return (<option value={v}>{v}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Coverage</label>
                                                    <select className="form-control">
                                                        {
                                                            ['25L', '35L', '50L', '70L', '1 Cr', '2 Cr', '3 Cr'].map((v) => {
                                                                return (<option value={v}>{v}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Coverage</label>
                                                    <select className="form-control">
                                                        {
                                                            ['25L', '35L', '50L', '70L', '1 Cr', '2 Cr', '3 Cr'].map((v) => {
                                                                return (<option value={v}>{v}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td className="align-left">
                                                    <label>Cover Upto</label>
                                                    <select className="form-control">
                                                        {
                                                            Array.from({ length: 33 }, (_, index) => 53 + index).map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Cover Upto</label>
                                                    <select className="form-control">
                                                        {
                                                            Array.from({ length: 33 }, (_, index) => 53 + index).map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Cover Upto</label>
                                                    <select className="form-control">
                                                        {
                                                            Array.from({ length: 33 }, (_, index) => 53 + index).map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td></td>
                                                <td className="align-left">
                                                    <label>Payment Term</label>
                                                    <select className="form-control">
                                                        {
                                                            [5, 10, 12, 15, 17, 22].map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Payment Term</label>
                                                    <select className="form-control">
                                                        {
                                                            [5, 10, 12, 15, 17, 22].map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                                <td className="align-left"><label>Payment Term</label>
                                                    <select className="form-control">
                                                        {
                                                            [5, 10, 12, 15, 17, 22].map((v) => {
                                                                return (<option value={v}>{v} Years</option>)
                                                            })
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><button type="button" className="btn bluebtn "><i className="fa fa-inr mr-10" aria-hidden="true"></i>{" "} 5,976 / Monthly</button></td>
                                                <td><button type="button" className="btn bluebtn"><i className="fa fa-inr mr-10" aria-hidden="true"></i> 5,999 / Monthly</button></td>
                                                <td><button type="button" className="btn bluebtn"><i className="fa fa-inr mr-10" aria-hidden="true"></i> 6,101 / Monthly</button></td>
                                            </tr>

                                            <tr>
                                                <th style={table_header} colSpan={4}>Basic Details</th>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Coverage</td>
                                                <td>{DATA[0] && inWords(planObj[0].cover)}</td>
                                                <td>{DATA[1] && inWords(planObj[2].cover)}</td>
                                                <td>{DATA[2] && inWords(planObj[2].cover)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Cover Upto</td>
                                                <td>{DATA[0] && DATA[0].CoverUpto}</td>
                                                <td>{DATA[1] && DATA[1].CoverUpto}</td>
                                                <td>{DATA[2] && DATA[2].CoverUpto}</td>
                                            </tr>
                                            <tr>
                                                <th style={table_header} colSpan={4}>Insurer Details</th>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Claim Settlement Ratio <i className="fa fa-info-circle c-info" aria-hidden="true" title="Percentage of death claims settled in a financial year"></i>
                                                </td>
                                                <td>{DATA[0] && DATA[0].ClaimSettlementRatio}</td>
                                                <td>{DATA[1] && DATA[1].ClaimSettlementRatio}</td>
                                                <td>{DATA[2] && DATA[2].ClaimSettlementRatio}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Claim Complaints Ratio <i className="fa fa-info-circle c-info" aria-hidden="true" title=" No. of claim complaints per 10,000 claims raised"></i>
                                                </td>
                                                <td>3<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" /></td>
                                                <td>3<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" /></td>
                                                <td>3<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" /></td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Solvency Ratio <i className="fa fa-info-circle c-info" aria-hidden="true" title="The solvency ratio identifies if a company has enough buffer to settle all claims raised in extreme conditions"></i>
                                                </td>
                                                <td>2.08<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" />
                                                </td>
                                                <td>1.78</td>
                                                <td>2.08<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Grievances Solved <i className="fa fa-info-circle c-info" aria-hidden="true" title="Percentage of solved customer grievances"></i>
                                                </td>
                                                <td>100%<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" />  </td>
                                                <td>99.85%</td>
                                                <td>100%<img className="thumbgreen" src={Global.thumbsUpGreen} alt="" /> </td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Inception <i className="fa fa-info-circle c-info" aria-hidden="true" title="Year of establishment of organization"></i>
                                                </td>
                                                <td>2000</td>
                                                <td>2000</td>
                                                <td>2000</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Number of Branches  <i className="fa fa-info-circle c-info" aria-hidden="true" title="Number of branches across India"></i>   </td>
                                                <td>239</td>
                                                <td>419</td>
                                                <td>239</td>
                                            </tr>
                                            <tr>
                                                <th style={table_header} colspan={4}>Free Benefits</th>
                                            </tr>

                                            <tr>
                                                <td className="fw500">Special Exit <i className="fa fa-info-circle c-info" aria-hidden="true" title="You have an option to get all your premiums back at years of Age and exit the  plan"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Terminal Illness <i className="fa fa-info-circle c-info" aria-hidden="true" title="You get 100% of SA (upto 1cr) Payout on diagnosis of a Terminal illness"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Nominee's Choice payout <i className="fa fa-info-circle c-info" aria-hidden="true" title="On Insured's demise, the nominee can opt for   Guaranteed Benefit (payable as Lumpsum), Monthly Income or Lumpsum + Monthly  Income"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Voluntary Top Up <i className="fa fa-info-circle c-info" aria-hidden="true" title="This option allows the policyholder to increase the sum assured at a later stage in the policy term."></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Tax Benefits <i className="fa fa-info-circle c-info" aria-hidden="true" title="The premiums paid and benefits received are eligible for tax benefits under section 80C and 10 (10D)"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr><th style={table_header} colspan="4">Paid Benefits</th></tr>
                                            <tr>
                                                <td className="fw500">Accidental Death <i className="fa fa-info-circle c-info" aria-hidden="true" title=" Accidental Death"></i>

                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Critical Illness and Disability <i className="fa fa-info-circle c-info" aria-hidden="true" title="Critical Illness and Disability"></i>

                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Waiver of Premium on Disability <i className="fa fa-info-circle c-info" aria-hidden="true" title="In case of permanent disability due to an accident, no future premiums have to  be paid and the life cover stays intact"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Waiver of Premium on CI <i className="fa fa-info-circle c-info" aria-hidden="true" title="In case of permanent disability due to an accident, no future premiums have to be paid and the life cover stays intact"></i>

                                                </td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Accidental Disability Benefit <i className="fa fa-info-circle c-info" aria-hidden="true" title="Accidental Disability Benefit"></i>

                                                </td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}<br />
                                                    <span className="minmax">For Policy  Term<br /> (5 Yrs - 40Yrs) </span></td>
                                                <td>{YesNoIcon(false)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Critical Illness <i className="fa fa-info-circle c-info" aria-hidden="true" title="You get 100% of SA (upto 1cr) Payout on diagnosis of a Critical illness"></i>

                                                </td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}<br /> <span className="minmax">For Policy Term<br /> (5 Yrs - 40Yrs) </span></td>
                                                <td>{YesNoIcon(false)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Cancer Cover <i className="fa fa-info-circle c-info" aria-hidden="true" title="Cancer Cover"></i>

                                                </td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}<br /><span className="minmax">For Policy  Term<br /> (5 Yrs - 85Yrs) </span></td>
                                                <td>{YesNoIcon(false)}</td>
                                            </tr>
                                            <tr><th style={table_header} colspan={4}>Features</th></tr>
                                            <tr>
                                                <td className="fw500">Death Coverage <i className="fa fa-info-circle c-info" aria-hidden="true" title="Death due to any cause (e.g. natural, accidental, murder, illnesses, calamities)"></i>

                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw500">Suicidal Cover <i className="fa fa-info-circle c-info" aria-hidden="true" title="Suicide during first year of the policy"></i>
                                                </td>
                                                <td>{YesNoIcon(true)}</td>
                                                <td>{YesNoIcon(false)}</td>
                                                <td>{YesNoIcon(true)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
