// Title.js
import  { useEffect } from 'react';

const Title = ({ title }) => {
  useEffect(() => {
    document.title = title; // Set the page title
  }, [title]);

  return null; // This component doesn't render anything
};

export default Title;
