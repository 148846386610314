import React from 'react';
import './css/common.css';
import http from "./http";
import RouterPage from './RouterPage';
import useCheckVersion from './useCheckVersion'; // Adjust the import path as needed
import RefreshSidebar from './RefreshSidebar';
import axios from 'axios';

function App() {

  if (!localStorage.getItem("access_token")) {
    http.post('/tickets', []).then((response) => {
      const resp = response.data;
      if (resp.access_token) {
        localStorage.setItem('access_token', resp.access_token);
        const currentDateTime = new Date();
        const formattedDateTime = `${currentDateTime.getFullYear()}-${(currentDateTime.getMonth() + 1).toString().padStart(2, '0')}-${currentDateTime.getDate().toString().padStart(2, '0')} ${currentDateTime.getHours().toString().padStart(2, '0')}:${currentDateTime.getMinutes().toString().padStart(2, '0')}:${currentDateTime.getSeconds().toString().padStart(2, '0')}`;
        localStorage.setItem('access_token_created_at', formattedDateTime);

      }
    });
  }

  const isNewVersion = useCheckVersion();

  const handleRefresh = () => {
    // Clear local storage
    localStorage.clear();
    sessionStorage.clear();
    // Clear cache
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    
    axios.get('/version.json')
      .then(response => {

        if(response.data.version){
          localStorage.setItem('appVersion', response.data.version);
          window.location.href = '/';
        }

      })
      .catch(error => {
        console.error(error);
      });


    // Reload the page
    //window.location.href = '/';
  };

  return (
    <React.Fragment>
      <div className="App">
        <RefreshSidebar isNewVersion={isNewVersion} onRefresh={handleRefresh} />
        <RouterPage />
      </div>
    </React.Fragment>
  );
}

export default App;
