import { React, Fragment, useState } from "react"; 
import InputMask from 'react-input-mask';

import { isValidDate } from "../../components/global";
import { ageCalculate } from "../../components/global";


export default function InsuredBasicForm(props) {
    const register = props.register;
    const errors = props.errors; 
    const setError = props.setError;
    //eslint-disable-next-line
    const [old, setOld] = useState({dob:''});
    const [calAge, setCalAge] = useState(old.dob && old.dob);
    
    const handleInputChange = (e) => {
    const input_dob = e.target.value;
    
    if(isValidDate(input_dob)){
            setError('dob', { message: false });  
        let age_ =  ageCalculate(input_dob); 
            setCalAge(age_);
        if(age_< 18 || age_> 60 ){
            setError('dob', { message: 'Age must be between 18 to 59', type: 'required'}); 
        }else{
            setError('dob', { message: false }); 
        }
    }else{ 
        if(!input_dob.includes('_')){
            setError('dob', { message: 'Invalid Date', type: 'required'});  
        } 
        setCalAge("");
    } 
    };
    return (
        <Fragment>

           
                <div className="form-row mb10"> 
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10 mr30">Your Full Name <span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="insured_full_name" name="insured_full_name" placeholder="Enter Your Full Name"  
                        {...register("insured_full_name", { required: 'Full Name field is required'} )}    />
                        <small className="form-text font11 text-danger">{errors.insured_full_name && errors.insured_full_name.message}</small>
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10">Date Of Birth (dd/mm/yyyy) <span className="colorfa8">*</span></div>
                        <InputMask type="text" 
                            {...register("insured_dob", { required: 'Date of Birth is required' } )}
                            className="form-control"  name="insured_dob"
                            placeholder="DD/MM/YYYY"
                            maskPlaceholder="DD/MM/YYYY"
                            mask="99/99/9999"
                            defaultValue={old.insured_dob ? old.insured_dob : ""}
                            onChange={handleInputChange}
                            />
                        <small className="form-text font11 text-danger">{errors.insured_dob && errors.insured_dob.message}</small>
                        <small className="form-text font12 c-green"><b>{ Number.isInteger(calAge) && 'Age: ' + calAge + ' Years'}</b></small>  
                    </div>
                </div> 
                
             
                <div className="form-row mb10">  
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Email <span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="insured_email " name="insured_email" placeholder="Enter Email"  
                        {...register("insured_email", { required: 'Email field is required'   } )} defaultValue=""  />
                        <small className="form-text font11 text-danger">{errors.insured_email && errors.insured_email.message}</small>
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10 mr30">Mobile Number <span className="colorfa8">*</span></div>
                        <span  className="mobile-prifix-code">+91</span>
                        <input type="text" className="form-control pl45" id="insured_mobile" name="insured_mobile" placeholder="Enter Mobile Number"  
                        {...register("insured_mobile", { required: 'Mobile Number field is required'} )}    /> 
                        <small className="form-text font11 text-danger">{errors.insured_mobile && errors.insured_mobile.message}</small>
                    </div> 
                </div> 
 
                <div className="form_prnt mb10">
                    <div className="custome_form">
                        <div className="form-row">
                        <div className="col-md-12 pr20">
                            <div className="font14 color212 fw600">Select your life stage among these <span className="colorfa8">*</span></div>
                            <ul className="steps_tag font14 fw500 color182 mt15">   
                                <li><label><input type="radio"  name="insured_life_stage" value="Single" {...register("insured_life_stage", { required: 'This field is required'   } )}/><span className="rdo_text_box">Single</span></label></li>    
                                <li><label><input type="radio" name="insured_life_stage" value="Married without kids" {...register("insured_life_stage", { required: 'This field is required'   } )}/><span className="rdo_text_box">Married without kids</span></label></li>   
                                <li><label><input type="radio" name="insured_life_stage" value="Married with kids" {...register("insured_life_stage", { required: 'This field is required'   } )}/><span className="rdo_text_box">Married with kids </span></label></li>   
                                <li><label><input type="radio" name="insured_life_stage" value="Single parent with kids" {...register("insured_life_stage", { required: 'This field is required'   } )} /><span className="rdo_text_box">Single parent with kids </span></label></li>     
                            </ul> 
                            <small className="form-text font11 text-danger">{errors.insured_life_stage && errors.insured_life_stage.message}</small>
                            </div>
                        </div> 
                    </div> 
                </div>

                <div className="form_prnt mb10">
                    <div className="custome_form">
                        <div className="form-row">
                        <div className="col-md-12 pr20">
                            <div className="font14 color212 fw600">Do you smoke or chew tobacco? <span className="colorfa8">*</span></div>
                            <ul className="steps_tag font14 fw500 color182 mt15">   
                                <li><label><input type="radio"  name="insured_smoke" value="Yes" {...register("insured_smoke", { required: 'This field is required'   } )}/><span className="rdo_text_box">Yes</span></label></li>    
                                <li><label><input type="radio" name="insured_smoke" value="No" {...register("insured_smoke", { required: 'This field is required'   } )} /><span className="rdo_text_box">No</span></label></li>      
                            </ul> 
                            <small className="form-text font11 text-danger">{errors.insured_smoke && errors.insured_smoke.message}</small>
                            </div>
                        </div> 
                    </div> 
                </div>
  
        </Fragment>
    )
}