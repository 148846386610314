import React, { Fragment, useState } from 'react';
import http from '../../http';
import './OtpModal.css'
import Swal from "sweetalert2";

const TermMobile = ({setWarningshow}) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [resendCount, setResendCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [vdisable, setVdisable] = useState(false);
    const sendOTP = async () => {
        if (!validateMobileNumber(mobileNumber)) {
            setErrorMessage('Please enter a valid Indian mobile number.');
            return;
        }

        try {
            // Send OTP request to your backend API
            http.post('/send-otp', { otp_send_on: 'mobile', mobile: mobileNumber, otp_for: 'verify' }).then((response) => {
                const resp = response.data;

                console.log('response', resp)
                // Handle response accordingly
                setOtpSent(true);

            });




        } catch (error) {
            console.error('Error sending OTP:', error);
            setErrorMessage('Error sending OTP. Please try again.');
        }
    };

    const validateMobileNumber = (number) => {
        // Indian mobile number validation regex
        const indianNumberRegex = /^[6-9]\d{9}$/;
        return indianNumberRegex.test(number);
    };

    const validateOTP = async () => {
        try {
            setVdisable(true);
            // Validate OTP request to your backend API
            const response = await http.post('validate-otp', { otp_send_on: 'mobile', mobile: mobileNumber, mobileOtp: otp });
            console.log('response', response)

            if (response.data.mobileVerify === 100) {
                console.log('success');

                const term_sharequote = sessionStorage.getItem('term_sharequote');
                http.post('/shareQuote', term_sharequote).then((response) => {
                    const resp = response.data;

                    if (resp.id) {
                        http.post('/share-quote-uuid', { uuid: resp.id, phone: mobileNumber, otp_type: 'mobile' }).then((response) => {
                            const resp = response.data;
                            if(resp){
                                setVdisable(true);
                                setWarningshow(false);

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success!',
                                    confirmButtonColor: '#009356',
                                    text: 'Quote Share successfully.',
                                }).then(() => {
                                    window.location.reload();
                                });

                            }

                        });
                    }


                });

            }else{
                setVdisable(false);
            }
            // Handle response accordingly
        } catch (error) {
            console.error('Error validating OTP:', error);
            setErrorMessage('Invalid OTP. Please try again.');
            setVdisable(false);
        }
    };

    const handleResend = () => {
        if (resendCount < 3) {
            sendOTP();
            setResendCount(resendCount + 1);
            setTimeout(() => {
                setResendCount(resendCount - 1);
            }, 120000); // 2 minutes interval
        } else {
            setErrorMessage('You have reached the maximum number of resend attempts.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateOTP();
    };

    return (
        <Fragment>
            <h2>Enter Your Mobile Number</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="form-control"
                            placeholder="Enter Mobile Number (e.g., 91XXXXXXXXXX)"
                            maxLength={10}
                            required
                            readOnly={otpSent}
                        />
                    </div>
                    {!otpSent && (
                        <div className="col-md-6 mb-3">
                            <button type="button" onClick={sendOTP} className="btn btn-primary">
                                Send OTP
                            </button>
                        </div>
                    )}
                </div>
                {otpSent && (
                    <>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="form-control"
                                    placeholder="Enter OTP"
                                    maxLength={6}
                                    required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <button type="submit" className="btn btn-success" disabled={vdisable}>Verify OTP</button>
                            </div>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className="col-md-12">
                        {resendCount < 3 && <button type="button" onClick={handleResend} className="btn btn-secondary">Resend OTP</button>}
                        {errorMessage && <p className="error">{errorMessage}</p>}
                    </div>
                </div>
            </form>
        </Fragment>

    );
};

export default TermMobile;
