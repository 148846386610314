 
 const TermPlanProducts = [
    {
      id:1,
      CarrierCode: 'ICICI',
      name: 'CICI Prudential Life Insurance',    
      sub_tags: ['iPROTECT SMART', 'CLAIM 99.5%'],
      about_title:'CICI Prudential Life Insurance ', 
      premium: 15000, 
      cover: 5000000, 
      logo: 'images/logo/icici-pru-life.png',
      badge: 'Popular', 
      options:[ 
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],

      monthly_pay: 1503,
      base_Amount: 1392,

      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,

      uin_no: '105N173V03',
      about:'CICI Prudential Life Insurance ......',

    },
    {
      id:2,
      CarrierCode: 'HDFC',
      name: 'HDFC Life Insurance', 
     
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title:'HDFC Life Click 2 Protect Super',

      premium: 20000 , 
      cover: 7000000,  
      logo: 'images/logo/hdfc-life.png',
      badge: 'Recommended',
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 2503,
      base_Amount: 2392,
      uin_no: '101N145V01',
      
      claim_settlement_ratio: 98.69,
      policies_sold_ratio: 50,
      solvency_ratio: 202 ,

      about:'HDFC Life Insurance Company Limited is a long-term life insurance provider in India. It is a joint venture between Housing Development Finance Corporation (HDFC) and Standard Life Aberdeen. HDFC Life offers a range of individual and group insurance plans, including term plans, savings plans, pension plans, and retirement plans. It has a claims paid ratio of 98.69% for the FY 22-23.',
    }, 
    {
      id:3,
      name: 'TATA AIA Life', 
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title: 'TATA AIA Life', 
      premium: 25000 , 
      cover: 10000000, 
      logo: 'images/logo/tata-aia-life.png',
      badge: '', 
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 2503,
      base_Amount: 2392,
      uin_no: '104N118V06',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'TATA AIA Life .....',
    },
    {
      id:4,
      name: 'Max New York Life', 
      sub_tags: ['iPROTECT SMART', 'CLAIM 99.5%'],
      about_title:'Max New York Life',
      premium: 30000, 
      cover: 15000000, 
      logo: 'images/logo/max-new-york-life.png',
      badge: '', 
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 1503,
      base_Amount: 1392,
      uin_no: '104N118V06',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'Max Life Insurance Co. Ltd. is a Joint Venture between Max Financial Services Ltd. (part of Max Group) and Axis Bank Ltd. It has a total claims paid ratio of 99.51% for the FY 23-24. Max Life offers comprehensive protection and long-term savings life insurance solutions, through its multichannel distribution.',
    },
    
    {
      id:5, 
      name: 'HDFC Life Insurance', 
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title:'HDFC Life Click 2 Protect Super',
      premium: 15000, 
      cover: 5000000, 
      logo: 'images/logo/hdfc-life.png',
      
      badge: 'Popular', 
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],

      monthly_pay: 1503,
      base_Amount: 1392,
      uin_no: '104N118V06',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'HDFC Life Insurance Company Limited is a long-term life insurance provider in India. It is a joint venture between Housing Development Finance Corporation (HDFC) and Standard Life Aberdeen. HDFC Life offers a range of individual and group insurance plans, including term plans, savings plans, pension plans, and retirement plans. It has a claims paid ratio of 98.69% for the FY 22-23.',
    },
    {
      id:6,
      name: 'ICICI Pru Life', 
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title: 'ICICI Pru Life', 
      premium: 20000 , 
      cover: 7000000,  
      logo: 'images/logo/icici-pru-life.png',
      badge: '',
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 1503,
      base_Amount: 1392,
      uin_no: '105N173V03',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'ICICI Pru Life .....',
    }, 
    {
      id:7,
      name: 'TATA AIA Life', 
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title: 'TATA AIA Life', 
      premium: 75000 , 
      cover: 30000000, 
      logo: 'images/logo/tata-aia-life.png',
      badge: '', 
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 1503,
      base_Amount: 1392,
      uin_no: '104N118V06',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'TATA AIA Life .....',
    },
    {
      id:8,
      name: 'Max New York Life', 
      sub_tags: ['CLICK2PROTECT SUPER', 'CLAIM 99.5%'],
      about_title: 'Max New York Life', 
      premium: 50000, 
      cover: 20000000, 
      logo: 'images/logo/max-new-york-life.png',
      badge: '', 
      options:[
        "Terminal Illness",
        "Waiver of Premium on Disability",
        "Multiple payout options",
        "Add Ons Available"
      ],
      monthly_pay: 1503,
      base_Amount: 1392,
      uin_no: '104N118V06',
      claim_settlement_ratio: 99,
      policies_sold_ratio: 40,
      solvency_ratio: 172 ,
      about:'Max Life Insurance Co. Ltd. is a Joint Venture between Max Financial Services Ltd. (part of Max Group) and Axis Bank Ltd. It has a total claims paid ratio of 99.51% for the FY 23-24. Max Life offers comprehensive protection and long-term savings life insurance solutions, through its multichannel distribution.',
    }
    
  ]; 
  export default TermPlanProducts;