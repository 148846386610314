const nextStep = (step) => {
  if (step === 6) return;
  return step + 1;
};

const prevStep = (step) => {
  if (step === 1) return;
  return step - 1;
};

const nextsubStep = (step, count) => {
  if (step === count) {
    return;
  } else {
    return step + 1;
  }
};

const prevsubStep = (step) => {
  if (step === 1) return;
  return step - 1;
};

const logOut = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("profile_id");
  sessionStorage.removeItem("full_name");

  window.location.href = "/sign-in";
};

const ageCalculate = (dob, format = "dd/mm/yyyy") => {
  if (!isValidDate(dob)) {
    return false;
  } else {
    var today = new Date();
    var birthDate = new Date(dob); // in case mm/dd/yyyy working

    //=== overwrite birthDate obj if date format "dd/mm/yyyy" which default define in fun
    if (format === "dd/mm/yyyy") {
      const [day, month, year] = dob.split("/").map(Number); // "dd/mm/yyyy" - "15/09/2023";
      // Note: Months are zero-based in JavaScript, so we subtract 1 from the month
      birthDate = new Date(year, month - 1, day);
    }
    console.log("-->format:", format);
    if (format === "yyyy-mm-dd") {
      const [year, month, day] = dob.split("/").map(Number); // "dd/mm/yyyy" - "15/09/2023";
      // Note: Months are zero-based in JavaScript, so we subtract 1 from the month
      birthDate = new Date(year, month - 1, day);
    }

    //====

    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  }
};

const investmentCategories = [
  {
    id: 1,
    name: "Mutual Fund",
    type: 4,
    sub_type: 9,
    icon: "mutualfunds_tab",
    checked: false,
  },
  {
    id: 2,
    name: "Fix Deposits",
    type: 4,
    sub_type: 9,
    icon: "pig_tab",
    checked: false,
  },
  {
    id: 3,
    name: "Stocks",
    type: 4,
    sub_type: 9,
    icon: "chartbar_tab",
    checked: false,
  },
  {
    id: 4,
    name: "Gold",
    type: 4,
    sub_type: 9,
    icon: "gold_tab",
    checked: false,
  },
  {
    id: 5,
    name: "Real Estate",
    type: 4,
    sub_type: 9,
    icon: "office_tab",
    checked: false,
  },
  {
    id: 6,
    name: "PPF/EPF",
    type: 4,
    sub_type: 9,
    icon: "coins_tab",
    checked: false,
  },
  {
    id: 7,
    name: "NPS Fund",
    type: 4,
    sub_type: 9,
    icon: "moneytime_tab",
    checked: false,
  },
  {
    id: 8,
    name: "Other Invest.",
    type: 4,
    sub_type: 9,
    icon: "plant_tab",
    checked: false,
  },
];

const stepData = [
  {
    step: 1,
    sub_steps: 2,
    name: "Basic Details",
    icon: "basicIcon",
    status: true,
  },
  {
    step: 2,
    sub_steps: 2,
    name: "Protection",
    icon: "protection",
    status: false,
  },
  {
    step: 3,
    sub_steps: 3,
    name: "Goals",
    icon: "goal",
    status: false,
  },
  {
    step: 4,
    sub_steps: 4,
    name: "Cashflow",
    icon: "cashflow",
    status: false,
  },
  {
    step: 5,
    sub_steps: 2,
    name: "Investment",
    icon: "rupee",
    status: false,
  },
];

const apiUrl =
  window.location.hostname === "localhost"
    ? "http://192.168.1.24/plan/public/api/"
    : "https://planapi.finnovate.in/api/";

const inWords = (num) => {
  let amount = Math.round(num);
  let data = 0;
  let v = "";
  let digits_count = amount.toString().length;

  if (digits_count >= 8) {
    data = amount / 10000000;
    v = "Cr";
  } else if (digits_count === 7 || digits_count === 6) {
    data = amount / 100000;
    v = "L";
  } else if (digits_count === 5 || digits_count === 4) {
    data = amount / 1000;
    v = "K";
  }

  return data.toFixed(2) + " " + v;
};

const inFrequency = (num) => {
  let number = parseInt(num);
  let freq = "";
  switch (number) {
    case 1:
      freq = "Yearly";
      break;
    case 12:
      freq = "Monthly";
      break;
    case 6:
      freq = "Half Yearly";
      break;
    case 4:
      freq = "quarterly";
      break;
    default:
      break;
  }

  return freq;
};

const stepMaster = (num) => {
  let number = parseInt(num);
  let stepName = "";
  switch (number) {
    case 1:
      stepName = "BasicDetails";
      break;
    case 2:
      stepName = "Protection";
      break;
    case 3:
      stepName = "Goals";
      break;
    case 4:
      stepName = "Cashflow";
      break;
    case 5:
      stepName = "Investments";
      break;
    case 6:
      stepName = "Compeleted";
      break;
    default:
      break;
  }

  return stepName;
};

const registerVia = (num) => {
  let number = parseInt(num);
  let Name = "";
  switch (number) {
    case 1:
      Name = "Admin";
      break;
    case 2:
      Name = "Web";
      break;
    default:
      break;
  }

  return Name;
};

const sanitizeNumber = (num) => {
  let Number = "";
  if (num) {
    Number = num.replace(/,/g, "");
  } else {
    Number = num;
  }

  return Number;
};

const responsePopup = ({ text, open, handleConfirm }) => {
  return (
    <>
      <div className={open ? "confirm show" : "confirm"}>
        <div className="confirm-content">
          <h4>CONFIRM</h4>
          <div>
            <h2>{text}</h2>
            <p>This action is final...</p>
          </div>
        </div>
        <div className="confirm-btns">
          <button onClick={() => handleConfirm(true)}>YES</button>
          <button onClick={() => handleConfirm(false)}>NO</button>
        </div>
      </div>
      <div className="overlay" onClick={() => handleConfirm(false)} />
    </>
  );
};


const isValidDate2 = (dateString) => {
  // Attempt to create a Date object from the input string
  const date = new Date(dateString);
  // Check if the created date is valid and not equal to "Invalid Date"
  return !isNaN(date) && date.toString() !== "Invalid Date";
};


const isValidDate = (dateString) => {
  // Define a regular expression pattern for the "dd/mm/yyyy" format
  const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;

  // Check if the input string matches the pattern
  if (!datePattern.test(dateString)) {
    return false;
  }

  // Extract day, month, and year values from the matched groups
  const [, day, month, year] = dateString.match(datePattern);

  // Convert the extracted strings to numbers
  const dayNum = parseInt(day, 10);
  const monthNum = parseInt(month, 10);
  const yearNum = parseInt(year, 10);

  // Check if the year is within a reasonable range (adjust this range as needed)
  const currentYear = new Date().getFullYear();
  if (yearNum < 1900 || yearNum > currentYear) {
    return false;
  }

  // Check if the month is valid (between 1 and 12)
  if (monthNum < 1 || monthNum > 12) {
    return false;
  }

  // Check if the day is valid for the given month
  const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
  if (dayNum < 1 || dayNum > daysInMonth) {
    return false;
  }

  // If all checks pass, the date is valid
  return true;
};

const req_params = new URLSearchParams(window.location.search);

const navigateTo = (url) => {
  window.history.pushState({}, null, url);
  const navEvent = new PopStateEvent("popstate");
  window.dispatchEvent(navEvent);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const db_date = (inputDate) => {
  // dd/mm/yyyy
  // Split the input date into day, month, and year
  var parts = inputDate.split("/");
  // Rearrange the parts into the desired format
  var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
  return formattedDate;
};

const get_gender = (gender) => {
  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  } else if (gender === "O") {
    return "Other";
  } else {
    return "";
  }
};

const get_gender_short = (gender) => {
  if (gender === "Male" || gender === "male") {
    return "M";
  } else if (gender === "Female" || gender === "female") {
    return "F";
  } else if (gender === "Other" || gender === "other") {
    return "O";
  } else {
    return "";
  }
};

const makeInt = (string_data) => {
  const str = parseInt(string_data.replace(/,/g, ""), 10); // val?.replace(',', '');
  return parseInt(str);
};

const paymentFrequencyList = [
  {
    value: 1,
    label: "Annual",
  },
  {
    value: 2,
    label: "Half Yearly",
  },
  {
    value: 3,
    label: "Quarterly",
  },
  {
    value: 4,
    label: "Monthly",
  },
  /*{
    value: 5,
    label: "Single",
  },*/
];

const calculateDOB = (ageInYears) => {
  console.log("ageInYears -> ", ageInYears)
  // Get the current date
  var currentDate = new Date();

  // Calculate the birth year by subtracting age from the current year
  var birthYear = currentDate.getFullYear() - ageInYears;

  // Set the DOB using the calculated birth year
  var dob = new Date(birthYear, 0, 1); // Assuming January 1st for simplicity

  // Format the DOB as 'yyyy-mm-dd'
  var formattedDOB = dob.toISOString().split("T");

  return formattedDOB[0];
};


//500000, 700000, 1000000,  1500000, 2000000, 2500000, 3000000, 4000000, 5000000, 10000000
const decodeSumInsured = (amount) => {
  amount = parseInt(amount);

  switch (amount) {
    case 500000:
      return "7";

    case 700000:
      return "1";

    case 1000000:
      return "2";

    case 1500000:
      return "8";

    case 2000000:
      return "3";

    case 2500000:
      return "10";

    case 3000000:
      return "4";

    case 4000000:
      return "5";

    case 5000000:
      return "6";
      
    case 10000000:
      return "11";

    default:
      return "2";
  }
};


const get_pin_code = (city) => {
  switch (city) {

    case "Mumbai":
      return 400069;

    case "Pune":
      return 411033;

    case "Bengaluru":
      return 560004;

    case "Thane":
      return 400601;

    case "Hyderabad":
      return 500004;

    case "Delhi":
      return 110004;

    case '411012':
      return 411012;

    case '411015':
      return 411015;

    case '411020':
      return 411020;

    case '422006':
      return 422006;

    case '422102':
      return 422102;

    case '422011':
      return 422011;

    default:
      return city;
  }
}

const messageDecode = (str) => {

  if (str === "Invalid SI value for entered pincode") {
    str = "Invalid SI value for entered pincode";
  } else if (str === "") {
    str = "";
  }
  return str;
}

const DBOptionList = [
  {
    value: 1,
    label: "Income",
  },
  {
    value: 2,
    label: "Lump-Sum",
  },
  {
    value: 3,
    label: "Increasing Income",
  },
  {
    value: 4,
    label: "Lump-Sum Income",
  } 
];

const educationMaster = [
  { value: 1, label: '10th' },
  { value: 2, label: '12th' },
  { value: 3, label: 'Graduate' },
  { value: 4, label: 'Post-Graduate' },
  { value: 5, label: 'Diploma' }
];


const occupationMaster = [
  {
    value:1,
    label:'Salaried'
  },
  {
    value:2,
    label:'Self Employed/Business'
  }
];

const getCarrierCodeByName=(str)=>{

  switch (str) {
        case 'Care Supreme':
        str = 'CARE';
        break;

        case 'ManipalCigna ProHealth Prime Advantage':
        str = 'CIGNA';
        break;

        case 'ManipalCigna ProHealth Prime Protect':
        str = 'CIGNA';
        break;

        case 'MediCare Premier':
        str = 'TATAAIG';
        break; 
    default:
      break;

  }
  return str;
}


export {
  nextStep,
  prevStep,
  nextsubStep,
  prevsubStep,
  ageCalculate,
  calculateDOB,
  apiUrl,
  logOut,
  stepData,
  investmentCategories,
  inWords,
  inFrequency,
  stepMaster,
  registerVia,
  sanitizeNumber,
  responsePopup,
  req_params,
  isValidDate,
  isValidDate2,
  navigateTo,
  capitalizeFirstLetter,
  db_date,
  get_gender,
  makeInt,
  paymentFrequencyList,
  get_gender_short,
  decodeSumInsured,
  get_pin_code,
  messageDecode,
  DBOptionList,
  educationMaster,
  occupationMaster,
  getCarrierCodeByName
};
