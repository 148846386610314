import { React, Fragment, useState, useEffect } from "react";

import Title from "../../Title";
import DashboardHeader from "../../components/layout/DashboardHeader";
import { Link, useParams } from "react-router-dom";
import "./../LifeInsuranceDetails.css";
import { useForm } from "react-hook-form";
import ProposerDetailsForm from "./ProposerDetailsForm";
import InsuredBasicForm from "./InsuredBasicForm";
import HealthQuestionnaire from "./HealthQuestionnaire";
import "../../css/header.css";
import "../../css/style.css";
import "./../Home.css";
import CardHealthPremiumDetails4 from "./CardHealthPremiumDetails4";
import PackageData from "../data/PackageData";
import Assistant from "../components/Assistant";

export default function ProposalStateBuyHealth() {

  const { register, handleSubmit, setError, formState: { errors }, } = useForm();


  const { productId } = useParams()
  // eslint-disable-next-line
  const [packages, setPackages] = useState(PackageData.filter((obj) => obj.id == productId)[0]);
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Health Plan : Proposer Stages");



  const [step, setStep] = useState(1);
  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };

  const [form_title, setFormTitle] = useState("");
  const [form_subtitle, setFormSubTitle] = useState("");

  const [form_data, setFormData] = useState({});

  useEffect(
    function () {
      if (step === 1) {
        setFormTitle("Proposer Details");
        setFormSubTitle("Some basic detail about you.");
      }
      if (step === 2) {
        setFormTitle("Member Details");
        setFormSubTitle("Some basic detail about your family members");
      }
      if (step === 3) {
        setFormTitle("Health Questionaire");
        setFormSubTitle("Some basic detail about your family members");
      }
      if (step === 4) {
        setFormTitle("Health Questionnaires");
        setFormSubTitle("Some basic detail about your family members");
      }
      if (step === 5) {
        setFormTitle("Health Questionnaires");
        setFormSubTitle("....");
      }
    },
    [step, form_data]
  );

  // submit form data
  const onSubmit = data => {
    setFormData({ ...form_data, data })
    handleNext();
  };

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <Link to={"#"}>Insurance Advise</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <Link to={"#"}>Term Insurance</Link> <i className="fa-solid fa-chevron-right font12"></i>
            <span>ICICI Prudential Life Insurance</span>
          </div>
          {/* <pre>{ form_data && JSON.stringify(form_data) }</pre> */}
          <div className="row mt10 life-insurance-details">
            <div className="col-md-8">
              <div className="card pt20">
                <div className="card-body-text life-insourance-buy proposer-details-form-section ">
                  <form id="form_1" onSubmit={handleSubmit(onSubmit)}>
                    <div className="life-lnsurance-logo-section">
                      <ul className="form_swipe_tab font12 fw600">
                        {Array.from({ length: 5 }, (_, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setStep(index + 1);
                            }}
                            className={step === index + 1 && "act"}
                          >
                            {index + 1}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="formtitle flex_center justify_center">
                      <div>
                        <div className="font18 fw600">{form_title}</div>
                        <div className="font15 color495 ">{form_subtitle}</div>
                      </div>
                      <div>
                        {step === 5 ? (
                          <> <button className="bluebtn font13 fw600 d-none d-md-block" type="submit" > Save  </button> </>
                        ) : (
                          <> <button className="bluebtn font13 fw600 d-none d-md-block" type="submit"  > Continue <i className="fa-solid fa-chevron-right font12"></i> </button>  </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form_prnt pt25 pl35 pr35 pb25">
                      <div className="custome_form mobile-top-margin-20">
                        {step === 1 && <ProposerDetailsForm register={register} errors={errors} setError={setError} />}
                        {step === 2 && <InsuredBasicForm register={register} errors={errors} setError={setError} />}
                        {/* { step === 2 && <h1>Step 2</h1>  }  */}
                        {step === 3 && <HealthQuestionnaire register={register} errors={errors} setError={setError} />}
                      </div>
                    </div>

                    <div className="saveBtn d-block d-sm-none">
                      <button type='submit' className="btn btn-primary">Continue <i className="fa-solid fa-chevron-right font12"></i> </button>
                    </div>

                  </form>
                </div>
              </div>

              <div className="mb50"></div>
            </div>{" "}
            {/** end of left section col-md-8 */}
            <div className="col-md-4">
              {/** start of right section col-md-4 */}
              <CardHealthPremiumDetails4 packages={packages} />
              <Assistant className="mt20" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
