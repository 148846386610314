import { Col } from "react-bootstrap"; 
import HealthQuestionaire from "../data/HealthQuestionaire";
import {  useState } from "react"; 
 
const  HealthQuestionnaire = (props) => {

    const register = props.register;
    const errors = props.errors; 

    const boolean = (str)=>{   return <span className="rdo_text_box">{str}</span>;  }
        // eslint-disable-next-line
    const [QuestionaireList, setHealthQuestionaire] = useState(HealthQuestionaire);
    const [selected_member, set_selected_member] = useState(1);
     

    const [sub_question_form, set_sub_question_form ]= useState([]);

    const handleQuestionClick = (question_id, value) => {   
        
            const sub_question_id_array = [];
            const radioButtons = document.getElementsByClassName(question_id+"_sub_ans_no");    
            for (const radioButton of radioButtons) {
                radioButton.checked = (value === 'none'? true : false); 
               let sub_question_id =   radioButton.getAttribute('sub_question_id');
              
               if(value === 'none'){ 
                sub_question_id_array.push(sub_question_id);
                } 
                
            }

            if(value === 'yes'){
                set_sub_question_form([...sub_question_form,question_id ]) // add sub_question_list ids
            }else{
                let arr = [...sub_question_form];
                let newArray = arr.filter(item => item !== question_id); 
                let result = newArray.filter(item => !sub_question_id_array.includes(item));
                set_sub_question_form([ result]); 
            } 
    }

    

    const handleSubQuestionClick = (question_id, value) => {   
        if(value === 'yes'){
            set_sub_question_form([...sub_question_form,question_id ]) // add sub_question_list ids
        }else{
            const newArray = sub_question_form.filter(item => item !== question_id);
            set_sub_question_form([...newArray ]); 
        }        
    }
  
const member_arr = [1,2,3,4,5];
    return (
        <>  
        <div className="row">
            <div className="col-md-12"> 
                <ul className="steps_tag font14 fw500 color182 mt15">
                    <li><label><input type="radio" name="members" value="1" defaultChecked={true} checked={selected_member === 1 && true } onClick={()=>{ set_selected_member(1) }} {...register('members', { required: "This Is  Required" })} /><span className="rdo_text_box padding_5-7">Person 1</span></label></li>    
                    <li><label><input type="radio" name="members" value="2" checked={selected_member === 2 && true } onClick={()=>{ set_selected_member(2) }} {...register('members', { required: "This Is  Required" })} /><span className="rdo_text_box padding_5-7">Person 2</span></label></li>    
                    <li><label><input type="radio" name="members" value="3" checked={selected_member === 3 && true } onClick={()=>{ set_selected_member(3) }} {...register('members', { required: "This Is  Required" })} /><span className="rdo_text_box padding_5-7">Person 3</span></label></li>    
                    <li><label><input type="radio" name="members" value="4" checked={selected_member === 4 && true } onClick={()=>{ set_selected_member(4) }} {...register('members', { required: "This Is  Required" })} /><span className="rdo_text_box padding_5-7">Person 4</span></label></li>    
                    <li><label><input type="radio" name="members" value="5" checked={selected_member === 5 && true } onClick={()=>{ set_selected_member(5) }} {...register('members', { required: "This Is  Required" })} /><span className="rdo_text_box padding_5-7">Person 5</span></label></li>    
                </ul>   
            </div> 
            {
            member_arr.map((member_id, index)=>{
                
               return ( 
                    member_id === selected_member && 
                    <div className={"member_list_"+member_id}>
                    { 
                    QuestionaireList.map((list,index)=>{
                    let name1 = `person[${member_id}][question][${list.id}]`;
                    
                        return ( 
                            <>  
                                <div className="col-md-12"><hr className="border-hr" /> </div> 
                                <Col md={12} className="mt-30 mb-1">
                                    <div className="font14 color212 fw600"><b>{list.id}. </b> {list.question} <span className="colorfa8">*</span> </div>
                                    <div className="font14 color212 ml15">{list.text}</div>
                                    <ul className="steps_tag font14 fw500 color182 mt15 ml10">
                                        <li><label><input type="radio" name={ name1 } onClick={()=>{handleQuestionClick(list.id, 'yes')}} value="yes" {...register(name1, { required: "This Is  Required" })}  />{boolean('Yes')} </label></li>
                                        <li><label><input type="radio" name={ name1 } onClick={()=>{handleQuestionClick(list.id, 'none')}} value="none" {...register(name1, { required: "This Is  Required" })} />{boolean('None')}  </label></li>
                                    </ul>  
                                    <small className="form-text font11 text-danger">{errors && errors.name1 && errors.name1.message }</small> 
                                    { 
                                    list.sub_question.map((v, i)=>{
                                        let sub_name = `person[${member_id}][question][${list.id}][${v.id}]`;
                                        return (
                                        <Col md={12} className="mt10">   
                                            <hr className="border-hr"/>  
                                            <div className="font14 color212 fw600">{/* {list.id}.{v.id}- */} {v.question} <span className="colorfa8">*</span></div>
                                            <ul className="steps_tag font14 fw500 color182 mt15">
                                                <li><label><input className={list.id + "_sub_ans_yes"} type="radio" name={ sub_name } value="yes" {...register(sub_name, { required: "This Is  Required" })}  
                                                onClick={()=>{handleSubQuestionClick(v.id, 'yes')}} sub_question_id={v.id} />{boolean('Yes')} </label></li>
                                                <li><label><input className={list.id+"_sub_ans_no"} type="radio" name={ sub_name } value="no"   {...register(sub_name, { required: "This Is  Required" })}
                                                onClick={()=>{handleSubQuestionClick(v.id, 'no')}} sub_question_id={v.id} />{boolean('No')} </label></li>
                                            </ul>  
                                            { 
                                            list.id === 1 && // THIS IS FOR ONLY QUESTION IS ID =  1 AND ALL SUB QUESTIONS
                                                sub_question_form.includes(v.id) &&  <DrowSubQuestionsForm1 register={register} errors={errors} member_id={member_id} question_id={list.id} sub_question_id={v.id} sub_name={sub_name} />                           
                                                
                                            }                                             
                                        </Col>  
                                        ) 
                                    })
                                    } 

                                    { 
                                        list.id === 2 && // THIS IS FOR ONLY QUESTION IS ID = 2
                                        sub_question_form.includes(list.id) &&  <DrowSubQuestionsForm2 register={register} errors={errors} member_id={member_id} question_id={list.id} sub_name={name1} />   
                                    } 
                                    { 
                                        list.id === 6 && // THIS IS FOR ONLY QUESTION IS ID = 2
                                        sub_question_form.includes(list.id) &&  <DrowSubQuestionsForm6 register={register} errors={errors} member_id={member_id} question_id={list.id} sub_name={name1} />   
                                    } 
                                </Col>  
                            </>)  
                        }) 
                    } 
                    <Col md={12} className="text-right"> <button className="bluebtn font13 fw600" type="button"  onClick={ ()=>{set_selected_member(selected_member + 1) } } > Proceed to Next Person </button> </Col> 
                    </div>
                )  // end of return 
            })
            } 
           
        </div> 
       
        </>
    )
} 
export default HealthQuestionnaire;



const DrowSubQuestionsForm1 = (props) =>{
    const register = props.register;
    const errors = props.errors; 
    const member_id = props.member_id;
    const question_id = props.question_id;
    const sub_question_id = props.sub_question_id;
    const sub_name = props.sub_name;

    let disease_name = sub_name+"[disease_name]"; // person[1]['question'][1][101]['disease_name']
    let disease_date = sub_name+"[disease_date]";
    let last_consultation_date = sub_name+"[last_consultation_date]";    
    let details_of_your_past_surgery = sub_name+"[details_of_your_past_surgery]";
    let current_status = sub_name+"[current_status]";
    let line_of_management = sub_name+"[line_of_management]";

 
    return ( 
        <>  
            <div className="form-row"> 
                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Disease Name<span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" name={disease_name} placeholder="Enter Disease Name"  
                    {...register(disease_name, { required: 'Disease Name field is required'} )} />  
                    <small className="form-text font11 text-danger">
                    {   
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['disease_name'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['disease_name'].message
                    }  
                    </small> 
                </div> 
                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Date of Diagnosis<span className="colorfa8">*</span></div>
                    <input type="date" className="form-control"  name={disease_date}
                    {...register(disease_date, { required: 'Date of Diagnosis field is required'} )}    />
                    <small className="form-text font11 text-danger">
                    {   
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['disease_date'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['disease_date'].message
                    }  
                    </small>
                </div> 

                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Last Consultation Date<span className="colorfa8">*</span></div>
                    <input type="date" className="form-control" name={last_consultation_date} 
                    {...register(last_consultation_date, { required: 'Full Name field is required'} )}    />
                     <small className="form-text font11 text-danger">
                    { 
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['last_consultation_date'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['last_consultation_date'].message
                    }  
                    </small>
                </div>  
            </div> 
            <div className="form-row mb10">  
                <div className="form-group col-md-12">                                
                    <div className="font14 color212 fw600 mb10">Details of Treatment given<span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" name={details_of_your_past_surgery} placeholder="Enter Details of Treatment given"  
                    {...register(details_of_your_past_surgery, { required: 'Details of Treatment given field is required'} )}    />
                     <small className="form-text font11 text-danger">
                    { 
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['details_of_your_past_surgery'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['details_of_your_past_surgery'].message
                    }  
                    </small>
                </div>
            </div> 
            <div className="form-row mb10"> 
                
                <div className="form-group col-md-6 pr20">                                
                <div className="font14 color212 fw600 mb10">Current Status<span className="colorfa8">*</span></div>
                    <select className="form-control" name={current_status} {...register(current_status, { required: 'Current Status field is required'   } )} >
                        <option value="">-- Select --</option>
                        <option value="1">val 1</option> 
                        <option value="2">val 2</option> 
                        <option value="3">val 3</option> 
                    </select> 
                    <small className="form-text font11 text-danger">
                    { 
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['current_status'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['current_status'].message
                    }  
                    </small>
                </div> 
                <div className="form-group col-md-6 pl20">                                
                    <div className="font14 color212 fw600 mb10">Line of Management<span className="colorfa8">*</span></div>
                    <select className="form-control" name={line_of_management} {...register(line_of_management, { required: 'Line of Management field is required'   } )} >
                        <option value="">-- Select --</option>
                        <option value="1">val 1</option> 
                        <option value="2">val 2</option> 
                        <option value="3">val 3</option> 
                    </select> 
                    <small className="form-text font11 text-danger">
                    { 
                        errors.person &&
                        errors.person[member_id] && 
                        errors.person[member_id]['question'] &&
                        errors.person[member_id]['question'][question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['line_of_management'] &&
                        errors.person[member_id]['question'][question_id][sub_question_id]['line_of_management'].message
                    }  
                    </small>
                </div>

            </div> 

        </>
    );
}



const DrowSubQuestionsForm2 = (props) =>{
    const register = props.register;
    const errors = props.errors;
    const sub_name = props.sub_name;

    let disease_name = sub_name+"[disease_name]";
    let disease_date = sub_name+"[disease_date]";
    let last_consultation_date = sub_name+"[last_consultation_date]";
    let current_status = sub_name+"[current_status]";
    let details_of_your_past_surgery = sub_name+"[details_of_your_past_surgery]";
    return (
        
        <>
            <div className="form-row"> 
                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Disease Name<span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" name={disease_name} placeholder="Enter Disease Name"  
                    {...register("full_name", { required: 'Disease Name field is required'} )}    />
                    <small className="form-text font11 text-danger">{errors.disease_name && errors.disease_name.message}</small>
                </div> 
                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Date of Diagnosis<span className="colorfa8">*</span></div>
                    <input type="date" className="form-control"  name={disease_date} 
                    {...register(disease_date, { required: 'Date of Diagnosis field is required'} )}    />
                    <small className="form-text font11 text-danger">{errors.disease_date && errors.disease_date.message}</small>
                </div> 

                <div className="form-group col-md-4">                                
                    <div className="font14 color212 fw600 mb10">Last Consultation Date<span className="colorfa8">*</span></div>
                    <input type="date" className="form-control" name={last_consultation_date}
                    {...register(last_consultation_date, { required: 'Last Consultation Date field is required'} )}    />
                    <small className="form-text font11 text-danger">{errors.last_consultation_date && errors.last_consultation_date.message}</small>
                </div>  
            </div> 
            <div className="form-row mb10"> 
                
                <div className="form-group col-md-6 pr20">                                
                <div className="font14 color212 fw600 mb10">Current Status<span className="colorfa8">*</span></div>
                    <select className="form-control" name={current_status} {...register("current_status", { required: 'Current Date field is required'   } )} >
                        <option value="">-- Select --</option>
                        <option value="1">val 1</option> 
                        <option value="2">val 2</option> 
                        <option value="3">val 3</option> 
                    </select> 
                    <small className="form-text font11 text-danger">{errors.current_status && errors.current_status.message}</small> 
                </div> 
                <div className="form-group col-md-6 pl20">                                
                    <div className="font14 color212 fw600 mb10">Details of your past surgery<span className="colorfa8">*</span></div>
                    <input type="text" className="form-control" name={details_of_your_past_surgery} placeholder="Enter Details of your past surgery"  
                    {...register(details_of_your_past_surgery, { required: 'Details of your past surgery is required'} )}    />
                    <small className="form-text font11 text-danger">{errors.details_of_your_past_surgery && errors.details_of_your_past_surgery.message}</small>
                </div>

            </div> 

        </>
    );
}



const DrowSubQuestionsForm6 = (props) =>{
    const register = props.register;
    const errors = props.errors;
    const sub_name = props.sub_name;

    let date_of_expected_delivery = sub_name+"[date_of_expected_delivery]"; 
    return (
        <div className="form-row">  
            <div className="form-group col-md-4">                                
                <div className="font14 color212 fw600 mb10">Date of Expected Delivery<span className="colorfa8">*</span></div>
                <input type="date" className="form-control"  name={date_of_expected_delivery} 
                {...register(date_of_expected_delivery, { required: 'Date of Diagnosis field is required'} )}    />
                <small className="form-text font11 text-danger">{errors.date_of_expected_delivery && errors.date_of_expected_delivery.message}</small>
            </div>  
        </div> 
    )
}