import { Row, Col } from "react-bootstrap"; 
 
function HealthQuestionnaire(props) {

    const register = props.register;
    const errors = props.errors;

    const questions = [
        {
            id: 1,
            question: 'Diabetes and other endocrine (glandular) disorders.',
        },
        {
            id: 2,
            question: 'Heart or circulatory disorders ',
        },
        {
            id: 3,
            question: 'cancer, tumors or growths ',
        },
        {
            id: 4,
            question: 'Muscle or skeletal problems ',
        },
        {
            id: 5,
            question: 'Gall bladder, stomach, Intestinal, gastric or liver ',
        },
        {
            id: 6,
            question: 'Brain or neurological disorders',
        },
        {
            id: 7,
            question: 'Skin problems',
        },
        {
            id: 8,
            question: 'Blood, infective or immune disorder',
        },
        {
            id: 9,
            question: 'Urinary or repoductive disorder',
        },
        {
            id: 10,
            question: 'Anxiety, depression, psychiatric or mental health',
        },
        {
            id: 11,
            question: 'Ear, nose, throat, eye or dental problems',
        },

    ]
 
    const boolean = (str)=>{
        return <span className="rdo_text_box">{str}</span>;
    }

    return (
        <> 
        <div className="proposer-details form-section">
            <Row>
                <Col md={12}> 
                {
                    questions.map((item) => {

                        let id        = "data[" + item.id + "][id]";
                        let question  = "data[" + item.id + "][question]";
                        let myself    = "data[" + item.id + "][myself]";
                        let wife      = "data[" + item.id + "][wife]";
                        let son       = "data[" + item.id + "][son]";
                        let daughter  = "data[" + item.id + "][daughter]";


                        return (
                            <>




                            <Row className="mb20">
                                <input type="hidden" value={item.id} name={id}{...register(id, { required: true })} ></input>

                                <input type="hidden" value={item.question} name={question}
                                    {...register(question, { required: true })} ></input>


                                <Col md={12} className="mt-30 mb-1">
                                    <div className="font14 color212 fw600"> {item.id +'. '+item.question}  ? <span className="colorfa8">*</span> </div>
                                </Col> 
                                <Col md={3}>    
                                    <div className="font14 color212">Self <span className="colorfa8">*</span></div>
                                    <ul className="steps_tag font14 fw500 color182 mt15">
                                        <li><label><input type="radio" name={myself} value="yes" {...register(myself, { required: "This Is  Required" })}  />{boolean('Yes')} </label></li>
                                        <li><label><input type="radio" name={myself} value="no"  {...register(myself, { required: "This Is  Required" })} />{boolean('No')}  </label></li>
                                    </ul> 
                                    <small className="form-text font11 text-danger">{errors && errors.data && errors.data[item.id] && errors.data[item.id]['myself'] && errors.data[item.id]['myself'].message }</small>
                                </Col>

                                <Col md={3}>    
                                    <div className="font14 color212">Wife <span className="colorfa8">*</span></div>
                                    <ul className="steps_tag font14 fw500 color182 mt15">
                                        <li> <label><input type="radio" name={wife} value="yes" {...register(wife, { required: "This Is  Required" })}  />{boolean('Yes')}  </label></li>
                                        <li> <label><input type="radio" name={wife} value="no"  {...register(wife, { required: "This Is  Required" })} />{boolean('No')}  </label></li>
                                    </ul> 
                                    <small className="form-text font11 text-danger">{errors && errors.data && errors.data[item.id] && errors.data[item.id]['wife'] && errors.data[item.id]['wife'].message }</small>
                                </Col>

                                <Col md={3}>    
                                    <div className="font14 color212">Daughter <span className="colorfa8">*</span></div>
                                    <ul className="steps_tag font14 fw500 color182 mt15">
                                        <li> <label><input type="radio" name={daughter} value="yes" {...register(daughter, { required: "This Is  Required" })}  />{boolean('Yes')}  </label></li>
                                        <li> <label><input type="radio" name={daughter} value="no"  {...register(daughter, { required: "This Is  Required" })} />{boolean('No')}  </label></li>
                                    </ul> 
                                    <small className="form-text font11 text-danger">{errors && errors.data && errors.data[item.id] && errors.data[item.id]['daughter'] && errors.data[item.id]['daughter'].message }</small>
                                </Col>

                                <Col md={3}>    
                                    <div className="font14 color212">Son <span className="colorfa8">*</span></div>
                                    <ul className="steps_tag font14 fw500 color182 mt15">
                                        <li> <label><input type="radio" name={son} value="yes" {...register(son, { required: "This Is  Required" })}  />{boolean('Yes')}  </label></li>
                                        <li> <label><input type="radio" name={son} value="no"  {...register(son, { required: "This Is  Required" })} />{boolean('No')}  </label></li>
                                    </ul> 
                                    <small className="form-text font11 text-danger">{errors && errors.data && errors.data[item.id] && errors.data[item.id]['son'] && errors.data[item.id]['son'].message }</small>
                                </Col> 
                            </Row>
                        </>)
                    })
                } 
                </Col> 
            </Row>
        </div> 
        </>
    )
} 
export default HealthQuestionnaire;