 
import Global from "../../Global"; 
import formatAmount from 'indian-currency-formatter';
import { Link } from "react-router-dom";



const ViewHealthQuotationlist = (props)  => { 
    const data = (typeof props.data === 'object' ? props.data : []); 
    const form_data = (props.form_data ? props.form_data : []);  

const logo = (CarrierCode) => {
  switch(CarrierCode) {
    case 'CARE':
      return Global.base_url +'/images/logo/care-helth.png';
    case 'HDFC':
      return Global.base_url +'/images/logo/hdfc-life.png';
    case 'ICICI':
      return Global.base_url +'/images/logo/iprotect-smart.png';
    case 'CIGNA':
        return Global.base_url +'/images/logo/cigna.png';  
    case 'TATAAIG':
      return Global.base_url +'/images/logo/TATAAIG.png';      
    default:
      return '';
  } 
}
 
    
    return (
          <> 
          {
          data.map((v, i) => 
                v.DuePremium ?
                <div className="mt20" key={v.id}>
                {/* {
                  v.TagRelation === 'Recommended' && ( <div className="c-blue"><span className="font14 fw600 p5" style={{ color: '#fff', background: '#0D6CCA' }}><i className="fa fa-star" aria-hidden="true"></i> Recommended</span></div>  )
                } 
                {
                  v.TagRelation === 'Popular' && ( <div className="c-blue"><span className="font14 fw600 p5" style={{ color: '#fff', background: '#0D6CCA' }}><i className="fa fa-star" aria-hidden="true"></i> Popular</span></div>  )
                }  */}

                  <div className="card card-border">
                    <div className="col-md-12 row pb20">
                        <div className="col-md-3 pt20 pr20">  
                          <img className="mt20" src={logo(v.CarrierCode) } alt="" />
                        </div>
                        <div className="col-md-6">
                          <h1 className="font20 fw700 pt10 pb10 " style={{color: '#0D6CCA'}}>{ v.ProductName }</h1>
                          <div className="mt10"> 
                              <li className="mt5">
                                  <div className="categoryTab font12 fw500 color353"> No co-Payment</div>
                              </li>
                              <li className="mt5">
                                  <div className="categoryTab font12 fw500 color353"> No disease wise sub-limits</div>
                              </li>
                              <li className="mt5">
                                  <div className="categoryTab font12 fw500 color353"> Can take any room</div>
                              </li>
                              <li className="mt5">
                                  <div className="categoryTab font12 fw500 color353"> Pre Existing Disease : 3 Years</div>
                              </li> 
                            </div> 
                        </div> 
                        <div className="col-md-3"> 
                          <div className="mt30 mb20">
                            <p>Premium</p>
                            <h1 className="font18 fw700"><i className="fa fa-inr" aria-hidden="true"></i> { v.DuePremium && formatAmount(v.DuePremium)}/Yr </h1>
                            {/* <p className="mt10">Cover: <strong><i className="fa fa-inr" aria-hidden="true"></i> {( v.PolicyLobList && v.PolicyLobList[0] && v.PolicyLobList[0].PolicyRiskList[0] && v.PolicyLobList[0].PolicyRiskList[0].SIInLacs)}L</strong> </p> */}
                            <p className="mt10">Cover: <strong><i className="fa fa-inr" aria-hidden="true"></i> {form_data.desired_cover_amount && form_data.desired_cover_amount/100000} L</strong> </p>
                          </div>
                          <div className="mb10">  
                          {/*   <Link to={'../'+ props.view_details_link + '/'+2} className="btn btn-primary font14 fw600">View Details</Link>  */}
                            <Link to={'../'+ props.view_details_link + '/'+v.ProductName} className="btn btn-primary font14 fw600">View Details</Link> 
                          </div>                                   
                        </div> 
                      </div>
                    </div> 
                </div>
                
                  :<>  
                      <div className="card card-border">
                            <div className="col-md-12 row pb20">
                                <div className="col-md-3 pt20 pr20">  
                                  <img className="mt20" src={logo(v.CarrierCode) } alt="" />
                                </div> 
                                <div className="col-md-9 p20">
                                <span className="text-danger font12 text-center">{v.Message}</span> 
                                </div>
                            </div>
                    </div>
                  </>

                  

              ) 
          }
       </>
    );
}

export default ViewHealthQuotationlist;

 