import { React, Fragment, useState } from "react"; 
import InputMask from 'react-input-mask';

import { isValidDate } from "../../components/global";
import { ageCalculate } from "../../components/global";

export default function ProposerDetailsForm(props) {
   
const register = props.register;
const errors = props.errors; 
const setError = props.setError;
//eslint-disable-next-line
const [old, setOld] = useState({dob:''});
const [calAge, setCalAge] = useState(old.dob && old.dob);

const handleInputChange = (e) => {
const input_dob = e.target.value;

if(isValidDate(input_dob)){
        setError('dob', { message: false });  
    let age_ =  ageCalculate(input_dob); 
        setCalAge(age_);
    if(age_< 18 || age_> 60 ){
        setError('dob', { message: 'Age must be between 18 to 59', type: 'required'}); 
    }else{
        setError('dob', { message: false }); 
    }
}else{ 
    if(!input_dob.includes('_')){
        setError('dob', { message: 'Invalid Date', type: 'required'});  
    } 
    setCalAge("");
} 
};
     
const [permanent_address, setPermanentAddress] = useState();

const [isChecked, setIsChecked] = useState(false);

const sameAsAbove = () => { 
    if (isChecked) { 
        setIsChecked(false); // If the checkbox is already checked, uncheck it
      } else { 
        setIsChecked(true); // If the checkbox is unchecked, check it
        let add = document.getElementById('correspondence_address').value;
        setPermanentAddress(add)
      }
  };

const handleInputChangePermanentAddress = (e) => { 
    setPermanentAddress(isChecked ? permanent_address : e.target.value );
}

const handleInputChangeCorrespondenceAddress = (e) => { 
    setPermanentAddress(isChecked ? e.target.value : permanent_address);
}
  
    return (
        <Fragment>
 
                <div className="form-row mb10"> 
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Your Full Name <span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="full_name" name="full_name" placeholder="Enter Your Full Name"  
                        {...register("full_name", { required: 'Full Name field is required'} )}    />
                        <small className="form-text font11 text-danger">{errors.full_name && errors.full_name.message}</small>
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10">Spouse's Name <span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="spouse_name " name="spouse_name" placeholder="Enter Spouse's Name"  
                        {...register("spouse_name", { required: 'Spouse\'s Name field is required'   } )} defaultValue=""  />
                        <small className="form-text font11 text-danger">{errors.spouse_name && errors.spouse_name.message}</small>
                    </div>
                </div> 

                <div className="form-row mb10"> 
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Father's Name<span className="colorfa8">*</span></div> 
                        <input type="text" className="form-control" id="father_name" name="father_name" placeholder="Enter Father's name"  
                        {...register("father_name", { required: 'Father\'s Name field is required'} )}    />
                        
                        <small className="form-text font11 text-danger">{errors.father_name && errors.father_name.message}</small>
                    </div> 
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10">Mother's Name<span className="colorfa8">*</span></div> 
                        <input type="text" className="form-control" id="mother_name" name="mother_name" placeholder="Enter Mother's name"  
                        {...register("mother_name", { required: 'Father\'s Name field is required'} )}    />
                        
                        <small className="form-text font11 text-danger">{errors.mother_name && errors.mother_name.message}</small>
                    </div>  
                </div> 
                <div className="form-row mb10"> 
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Date Of Birth (dd/mm/yyyy)  <span className="colorfa8">*</span></div>  
                        <InputMask type="text" 
                                  {...register("dob", { required: 'Date of Birth is required' } )}
                                  className="form-control"  name="dob"
                                  placeholder="DD/MM/YYYY"
                                   maskPlaceholder="DD/MM/YYYY"
                                   mask="99/99/9999"
                                  defaultValue={old.dob ? old.dob : ""}
                                  onChange={handleInputChange}
                                  />
                                <small className="form-text font11 text-danger">{errors.dob && errors.dob.message}</small>
                                <small className="form-text font12 c-green"><b>{ Number.isInteger(calAge) && 'Age: ' + calAge + ' Years'}</b></small>  
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                    <div className="font14 color212 fw600 mb10">Gender <span className="colorfa8">*</span></div>
                        <select className="form-control" name="gender" {...register("gender", { required: 'Gender field is required'   } )} >
                            <option value="">-- Select --</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option> 
                        </select> 
                        <small className="form-text font11 text-danger">{errors.gender && errors.gender.message}</small>
                        
                    </div>
                </div> 

                <div className="form-row mb10">  
                    <div className="form-group col-md-6 pr20">                                
                        <div className="font14 color212 fw600 mb10">Email <span className="colorfa8">*</span></div>
                        <input type="text" className="form-control" id="email " name="email" placeholder="Enter Email"  
                        {...register("email", { required: 'Email field is required'   } )} defaultValue=""  />
                        <small className="form-text font11 text-danger">{errors.email && errors.email.message}</small>
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10 mr30">Mobile Number <span className="colorfa8">*</span></div>
                        <span  className="mobile-prifix-code"> +91 </span>
                        <input type="text" className="form-control pl45" id="mobile" name="mobile" placeholder="Enter Mobile Number"  
                        {...register("mobile", { required: 'Mobile Number field is required'} )}    /> 
                        <small className="form-text font11 text-danger">{errors.mobile && errors.mobile.message}</small>
                    </div> 
                </div> 

                <div className="form-row mb10"> 
                <div className="form-group col-md-6 pr20">                                
                    <div className="font14 color212 fw600 mb10">Nationality <span className="colorfa8">*</span></div>
                        <select className="form-control" name="nationality" {...register("nationality", { required: 'Nationality field is required'   } )} >
                            <option value="">-- Select --</option>
                            <option value="Indian">Indian</option> 
                        </select> 
                        <small className="form-text font11 text-danger">{errors.nationality && errors.nationality.message}</small>
                        
                    </div>
                    <div className="form-group col-md-6 pl20">                                
                        <div className="font14 color212 fw600 mb10">Resident Status <span className="colorfa8">*</span></div>
                        <select className="form-control" name="resident_status" {...register("resident_status", { required: 'Resident Status field is required'   } )} >
                            <option value="">-- Select --</option> 
                            <option value="Resident Indian">Resident Indian</option> 
                        </select> 
                        <small className="form-text font11 text-danger">{errors.resident_status && errors.resident_status.message}</small> 
                    </div>
                </div> 


                <div className="form_prnt mb10">
                    <div className="custome_form">
                        <div className="form-row">
                        <div className="col-md-12 pr20">
                            <div className="font14 color212 fw600">Select your life stage among these <span className="colorfa8">*</span></div>
                            <ul className="steps_tag font14 fw500 color182 mt15">   
                                <li><label><input type="radio"  name="life_stage" {...register("life_stage", { required: 'This field is required'   } )} /><span className="rdo_text_box">Single</span></label></li>    
                                <li><label><input type="radio" name="life_stage" {...register("life_stage", { required: 'This field is required'   } )} /><span className="rdo_text_box">Married without kids</span></label></li>   
                                <li><label><input type="radio" name="life_stage" {...register("life_stage", { required: 'This field is required'   } )} /><span className="rdo_text_box">Married with kids </span></label></li>   
                                <li><label><input type="radio" name="life_stage" {...register("life_stage", { required: 'This field is required'   } )} /><span className="rdo_text_box">Single parent with kids </span></label></li>     
                            </ul> 
                            <small className="form-text font11 text-danger">{errors.life_stage && errors.life_stage.message}</small>
                            </div>
                        </div> 
                    </div> 
                </div>

                <div className="form-row mb10"> 
                    <div className="form-group col-md-12">                                
                        <div className="font14 color212 fw600 mb10 mr30">Correspondence Address <span className="colorfa8">*</span><span className="ml10 text-type-3">include city, state, PIN code</span></div> 
                        <input type="text" className="form-control" id="correspondence_address" name="correspondence_address" placeholder="Enter Correspondence Address"  
                        {...register("correspondence_address", { required: 'Correspondence Address field is required'} )} onChange={handleInputChangeCorrespondenceAddress} /> 
                        <small className="form-text font11 text-danger">{errors.correspondence_address && errors.correspondence_address.message}</small>
                    </div>   
                </div> 

                <div className="form-row mb10"> 
                    <div className="form-group col-md-12">                                
                        <div className="font14 color212 fw600 mb10 mr30">Permanent Address <span className="colorfa8">*</span>
                        <label  className="ml10"><input type="checkbox"  checked={isChecked} id="same_as_above" onClick={sameAsAbove} /> <span className="text-type-3">Same as above</span> </label> 
                        </div> 
                        <input type="text" className="form-control" id="permanent_address" name="permanent_address" placeholder="Enter Permanent Address"  
                        {...register("permanent_address", { required: 'Permanent Address field is required'} )} value={permanent_address} onChange={handleInputChangePermanentAddress} /> 
                        <small className="form-text font11 text-danger">{errors.permanent_address && errors.permanent_address.message}</small>
                    </div>   
                </div>  
        </Fragment>
    )
}