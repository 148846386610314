import SweetAlert from "sweetalert2";
import { useState } from "react";

function Documents(props) {
  // eslint-disable-next-line
  const [documents, SetDocuments] = useState({
    pan: "",
    passport: "",
    medical_examination_papers: "",
    signature: "",
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

 

  async function make_base64(get_id, set_id) {
    var size = document.querySelector("#file_passport").files[0].size;
    var size_kb = size / 1000;
    var size_mb = size_kb / 1000;
    if (size_mb > 5) {
      SweetAlert.fire(
        "Error!",
        "Must be file size less than 5 MB, You are trying to upload file which size is " +
          size_mb +
          " MB",
        "error"
      );
    }
    //   alert("MB" + size_mb);

    const file = document.querySelector("#" + get_id).files[0];
    let base_64_file_data = await toBase64(file);
    document.getElementById(set_id).value = base_64_file_data;
  }


  

  return (
    <>
      <div className="documents form-section">
         
            <div className="form-row mb10">
              <div className="col-md-8">
              <div className="font14 color212 fw600 mb10">Passport (Front and back both)</div>
                <input
                  type="file"
                  id="file_passport"
                  name="file_passport"
                  className="form-control"
                  accept="image/*,application/pdf"
                  size="20"
                  onChange={() => {
                    make_base64("file_passport", "passport");
                  }}
                />
                <small className="text-type-3">Less than 5 MB</small>
                {documents.passport && ( <small className="ml-20">  <a target="_blank" href={documents.passport} rel="noreferrer"> View  </a>  </small>  )}
              </div>
              <div className="col-md-2 mt-2">
                <div className="camera-box">
                  <i className="fa fa-camera fa-2x" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div className="form-row mb10 mt20">
              <div className="col-md-8">
                <div className="font14 color212 fw600 mb10">PAN Card</div>
                <input
                  type="file"
                  id="file_pan"
                  name="file_pan"
                  className="form-control"
                  accept="image/*,application/pdf"
                  onChange={() => {
                    make_base64("file_pan", "pan");
                  }}
                />
                <small className="text-type-3">Less than 5 MB</small>
                {documents.pan && (  <small className="ml-20 text-type-3">  <a target="_blank" href={documents.pan} rel="noreferrer">  </a> </small>   )}
              </div>
              <div className="col-md-2 mt-2">
                <div className="camera-box">
                  <i className="fa fa-camera fa-2x" aria-hidden="true"></i>
                </div>
              </div>
            </div>

            <div className="form-row mb10 mt20">
              <div className="col-md-8">
                <div className="font14 color212 fw600 mb10">Medical Examination Papers</div>
                <input
                  type="file"
                  id="file_medical_examination"
                  name="file_medical_examination"
                  className="form-control"
                  accept="image/*,application/pdf"
                  onChange={() => {
                    make_base64(
                      "file_medical_examination",
                      "medical_examination_papers"
                    );
                  }}
                />
                <small className="text-type-3">Less than 5 MB</small>
                {documents.medical_examination_papers && ( <small className="ml-20 text-type-3">  <a target="_blank"  rel="noreferrer" href={documents.medical_examination_papers} >   View   </a> </small>  )}
              </div>
              <div className="col-md-2 mt-2">
                <div className="camera-box">
                  <i className="fa fa-camera fa-2x" aria-hidden="true"></i>
                </div>
              </div>
            </div> 
      </div>
    </>
  );
}

export default Documents;
