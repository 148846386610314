//https://stackoverflow.com/questions/63453491/how-to-export-axios-create-in-typescript
import axios from 'axios';

//import SweetAlert from 'sweetalert2'

const access_token = (localStorage.getItem("access_token") ? localStorage.getItem("access_token") : '');

const headers_1 = {
  "Content-type": "application/json", 
  "Accept": "application/json",
  "x-ebao-tenant-code":"finnovate", 
  "x-mo-tenant-id":"finnovate" 
}

const headers_2 = {
  "Content-type": "application/json",
  "Authorization": "Bearer " + access_token,
  "Accept": "application/json",
  "x-ebao-tenant-code":"finnovate",
  "x-ebao-country-code":"IND",
  "x-mo-tenant-id":"finnovate" 
}

//const url = 'http://127.0.0.1:8000/api/v1'
const url = window.location.hostname === "finnsureuat.finnovate.in" || window.location.hostname === "localhost" ? 'https://finnsureuat.finnovate.in/admin/api/v1' : 'https://finnsure.finnovate.in/admin/api/v1'


const http = axios.create({ 
  baseURL: url, 
  headers: access_token === "" ? headers_1 : headers_2,
});


// Override console.log
if (window.location.hostname === 'finnsure.finnovate.in') {
  //  console.log = () => {};
}
 


  
export default http;
