import React, { useEffect, useState } from 'react';
import { inWords } from '../../components/global';
import formatAmount from 'indian-currency-formatter';
import './PlusMinusInput.css'

function PlusMinusInput(props) {
    const amount = props.amount;
    //const setAmount = props.setAmount;


    const[formated_amount, set_formated_amount] = useState(formatAmount(amount)); 
    
    const ChangeAnnualIncomeHandle = (e) =>{  
        const plan_amount = (e.target.value).replace(/,/g, '') ;
        props.setAmount(parseInt(plan_amount));
        props.setDisableQuote(true);
        set_formated_amount( formatAmount( plan_amount ) ); 
    }

    const plusAmount = () =>{
        props.setAmount( parseInt(amount) + 2500000);
        props.setDisableQuote(true);
    }

    const minusAmount = () => {
        props.setAmount( parseInt(amount) - 2500000);
        props.setDisableQuote(true);
    }

    useEffect(()=>{
        set_formated_amount( formatAmount( amount ) );  
    },[amount]);
     
    return (
        <div className={ props.className ? " plus-minus-component " + props.className : " plus-minus-component " }>
            <div className='row'>
                <div className="targetbar_outer">
                    <div className="targetbar_title">
                        <div className="font14 fw500 color182">Cover Required Values</div>
                        <div> 
                            <div className="tragetvalue font13 fw700 colorf47"> ₹ { inWords(amount) } </div>
                        </div>
                    </div> 
                   {/*  <span className="text-type-1">You can choose a number you are comfortable with. </span>
                    <span className="text-type-1">Howwever, we wouldn't recommend going bellow ₹ 80 L . </span>  */}
                </div>  
            </div>  
            <div className='col-md-12 mt20'>
                <div className="plus-minus-wpr">                           
                    <button type="button" onClick={minusAmount} className='font13 fw600'> <i className="fa-solid fa-minus mr5"></i> ₹25 L </button>
                    <span className='rupee-icon'>₹</span> <input type="text" className="font14 fw500 ml15 mr15" value={formated_amount}  onChange={ChangeAnnualIncomeHandle} />
                    <button type="button" className="greenBtn font13 fw600" onClick={plusAmount}> <i className="fa-solid fa-plus mr5"></i>  ₹25 L</button> 
                </div> 
            </div>
        </div>
    );
}

export default PlusMinusInput;