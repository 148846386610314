/**
 * 
 */
import React from 'react';
import { css } from '@emotion/react';
import Global from '../Global';
//import { ScaleLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="sweet-loading" style={{
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'position': 'fixed',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'backgroundColor': '#fff'
    }}>
      <img
        className={override}
        src={Global.base_url + "/images/icons/finloader.gif"}
        alt=""
        loading={true}
        style={{
          'width': '90px',
          'height': '90px',
          'opacity': '0.7'  // Set opacity to 0.8 (80%)
        }}
      />
    </div>

  );
};

export default Loader;
