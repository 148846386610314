import React from 'react'
import '../../css/header.css'
import '../../css/style.css'
import { logOut } from '../global'
import Global from '../../Global'

export default function Header({steps}){


  
  
    const loggedInUser = sessionStorage.getItem("token");
    if (loggedInUser === null) {
    //  navigate("/sign-in");
    }

    const backtoAdmin = () => {
     //   navigate("/admin-dashboard");
    }
    
    return(
        <>
            <div className="headerWpr mainwrapper">
                <div className="innercontainer">
                    <div className="hdrCol pt12 pb12">
                        <div className="hdrleft font13 fw500 color182"><span className="finnvoate_logo mr12"><a href="/"><img src={Global.base_url +  '/images/finnoNewLogo.jpg'} alt='logo' /></a></span> <span className="dn">Welcome to Finnovate,</span></div>
                        <div className="hdrmiddle d-none d-md-block">
                            {/* <ul className="infosteps font12 fw500">
                                <li className={steps === 1 ? "active" : steps > 1 ? "active_done" : ''}>
                                    <div className="stepsiconbox">
                                        <span className="stepIcon basicIcon_white" /> 
                                        <span className="stepstext">Basic Details</span>
                                    </div>
                                </li>
                                <li className={steps === 2 ? "active" : steps > 2 ? "active_done" : ((steps + 1) === 2 ? "active_next" : '')}>
                                    <div className="stepsiconbox">
                                        <span className="stepIcon protection_clr" /> 
                                        <span className="stepstext">Protection</span>
                                    </div>
                                </li>
                                <li className={steps === 3 ? "active" : steps > 3 ? "active_done" : ((steps + 1) === 3 ? "active_next" : '')}>
                                    <div className="stepsiconbox">
                                        <span className="stepIcon goal_clr" />
                                        <span className="stepstext">Goals</span>
                                    </div>
                                </li>
                                <li className={steps === 4 ? "active" : steps > 4 ? "active_done" : ((steps + 1) === 4 ? "active_next" : '')}>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon cashflow_clr" />
                                        <span className="stepstext">Cashflow</span>
                                    </div>
                                </li>
                                <li className={steps === 5 ? "active" : steps > 5 ? "active_done" : ((steps + 1) === 5 ? "active_next" : '')}>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon risk" />
                                        <span className="stepstext">Investments</span>
                                    </div>
                                </li>
                                <li className={steps === 6 ? "active" : steps > 6 ? "active_done" : ((steps + 1) === 6 ? "active_next" : '')}>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon lock" />
                                        <span className="stepstext">Done</span>
                                    </div>
                                </li>
                                <li className={steps === 7 ? "active" : steps > 7 ? "active_done" : ''}>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon rupee_clr" />
                                        <span className="stepstext"></span>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                        <div className="hdrright d-none d-md-block">
                            
                             
                            {/* <div className="notification_icon mr20" /> */}

                            <span className="profileimg mr10"><img src={Global.base_url + '/images/user.png'} alt='User Profile'/></span>

                            <span className="hdr_downarrow">
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 3.68852L8.2821 0.303855C8.66657 -0.0926305 9.29966 -0.10237 9.69615 0.2821C10.0926 0.666571 10.1024 1.29966 9.7179 1.69615L5.7179 5.82115C5.32504 6.22629 4.67496 6.22629 4.2821 5.82115L0.2821 1.69615C-0.10237 1.29966 -0.0926305 0.666571 0.303855 0.2821C0.70034 -0.10237 1.33343 -0.0926305 1.7179 0.303855L5 3.68852Z" fill="#81878C" />
                                </svg>
                                <span className="profilelist">
                                    <ul className="font12 color111">
                                        <li onClick={logOut}>Logout</li>
                                        {
                                            sessionStorage.getItem('admin_id') !== null ? <li onClick={backtoAdmin}>Back to Admin</li> : ''
                                        }
                                    </ul>
                                </span>
                            </span>
                        </div>

                        
                        <div className="hdrright d-block d-sm-none d-md-none d-lg-none">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/#">Basic Details <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#">Protection</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#">Cashflow</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#">Goal</a>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link" onClick={logOut}>Logout</span>
                                    </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}