import { React, Fragment, useState } from "react";

import Title from "../../Title";
import DashboardHeader from "../../components/layout/DashboardHeader";
import { Link } from "react-router-dom";
import "./../LifeInsuranceDetails.css";
import Slider from "rc-slider";


export default function LifeInsuranceDetails() {
  // eslint-disable-next-line
  const [pageTitle, setPageTitle] = useState("Term Plan: Quote");

  const [active, setActive] = useState('accordion_1');

  // eslint-disable-next-line
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  }

  const [SIPInvestmentAmount, setSIPInvestmentAmount] = useState(350);

  return (
    <Fragment>
      <Title title={pageTitle} />
      <DashboardHeader />
      <div className="innercontainer mb40">
        <div className="mainwrapper">
          <div className="beadcrumbs font16 color626">
            <Link to={"#"}>Advisory</Link> >
            <Link to={"#"}>Insurance Advise</Link> >
            <Link to={"#"}>Term Insurance</Link> >
            <span>ICICI Prudential Life Insurance</span>
          </div>

          <div className="row mt10 life-insurance-details">
            <div className="col-md-8">
              <div className="card p20">
                <div className="row">
                  <div className="col-md-12 life-lnsurance-logo-section">
                    <h2 className="heading-1"> CICI Prudential Life Insurance </h2>
                  </div>
                </div>
                <div className="card-body-text life-insourance-buy  ">


                  <div className="radio-buttons mt20">
                    <h3 className="heading-3 mb20">Have You Smoked Or Chewed Tobacco In Last 12 Months ?</h3>
                    <label>Yes <input className="ml20" type="radio" name="question_1" /></label>
                    <label>No <input className="ml20" type="radio" name="question_1" /></label>
                  </div>
                </div>
              </div>


              <div className="mb50"></div>

            </div> {/** end of left section col-md-8 */}


            <div className="col-md-4">{/** start of right section col-md-4 */}
              <div className="card p20 ">
                <div className="row">
                  <div className="col-md-12">
                    <table style={{ 'width': '100%' }}>
                      <tr>
                        <td style={{ 'width': '60%' }}>
                          <span className="text-type-1">Cover</span>
                          <br />
                          <span className="heading-3">₹ 50 Lakh till 50 year</span>
                        </td>
                        <td>
                          <span className="text-type-1">Monthly pay</span>
                          <br />
                          <span className="heading-3  color-primary">₹ 1,503 /month</span>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div className="col-md-12">
                    <hr className="border-hr" />
                  </div>
                  <div className="col-md-12">
                    <h5 className="heading-3 mb10">Terms and add ons</h5>
                    <div className="">
                      <label className="text-type-2"><input type="checkbox" name="" /> SIP investment amount </label>
                      <span className="btn-display-amount"><i className="fa fa-inr" aria-hidden="true"></i> 350</span>
                      <Slider step={1} name="cover_amount" className='mt15' min={5} max={500} onChange={e => { setSIPInvestmentAmount(e) }} pushable={true} value={SIPInvestmentAmount} />
                      <span className="text-type-3">Cover amount ₹ 25 Lakhs</span>
                    </div>

                    <div className="col-md-12 row mt30">
                      <label className="text-type-2"><input type="checkbox" name="" /> Accidental Death Benefit </label>
                    </div>
                    <hr className="border-hr" />
                    <div className="row">
                      <div className="col-md-7 "><label className="text-type-4"> Base Amount </label> </div>
                      <div className="col-md-5 text-right"> <span> ₹ 1,392.00</span>  </div>
                    </div>
                    <div className="row">
                      <div className="col-md-7"><label className="text-type-4"> GST (18%)</label> </div>
                      <div className="col-md-5 text-right"> <span>₹ 139.50 </span> </div>
                    </div>
                    <hr className="border-hr" />

                    <div className="total-to-pay-area">
                      <span>Total to pay (incl. of taxes) </span> <span className="ml50 bold">₹ 1,503</span>
                    </div>
                    <div className="text-center  mt5 mb20">
                      <span className="text-tax-saving make-circle">%</span>
                      <span className="text-tax-saving ml5">80c tax saving ₹2,728.00 per year</span></div>

                  </div>
                </div>
              </div>

              <div className="mt30">
                <div className="avatar-display text-center">
                  <img src="images/avatar-1.png" alt="" />
                  <img src="images/avatar-2.png" alt="" />
                  <img src="images/avatar-3.png" alt="" />
                  <img src="images/avatar-1.png" alt="" />
                  <img src="images/avatar-2.png" alt="" />
                </div>
                <h3 className="heading-3  text-center mt10 mb10">Need Assistant?</h3>
                <p className="text-type-1">We are here to help you. Visit the support section to get quick answers <Link to={"#"}>Get Support</Link> </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
