import React from 'react';

const RiderDetails = [
    { "CarrierRiderDesc": "Accidental Disability Rider", "Rider": "INAD" },
    { "CarrierRiderDesc": "Personal Accident Cover rider", "Rider": "PPR_PAC" },
    { "CarrierRiderDesc": "Cancer Care rider", "Rider": "PPR_CC" },
    { "CarrierRiderDesc": "Critical Illness Plus Rider", "Rider": "C4CI" },
    { "CarrierRiderDesc": "Accelerated Critical Illness (ACI) Benefit", "Rider": "CIBF" },
    { "CarrierRiderDesc": "Accidental Death Benefit", "Rider": "ADBU" },
    { "CarrierRiderDesc": "Cancer Care rider", "Rider": "HPR_CC" },
    { "CarrierRiderDesc": "Accidental death cover", "Rider": "PPR_ADC" },
    { "CarrierRiderDesc": "Critical Illness Plus Rider", "Rider": "HPR_CCI" }
];

const GetRiderDescription = ({ riderCode }) => {
    // Find the rider in the RiderDetails array
    const rider = RiderDetails.find(detail => detail.Rider === riderCode);

    // If the rider is found, return its description; otherwise, return an error message
    return (
        <span className={"font12"}>
            {rider ? rider.CarrierRiderDesc : "Rider description not found."} 
        </span>
    );
}

export default GetRiderDescription;

// Usage of this component should be something like:
// <GetRiderDescription riderCode="C4CI" />

// eslint-disable-next-line
const initialCheckboxesHDFC = {
    INAD: true,
    PPR_PAC: true,

    HPR_CC: false,
    PPR_ADC: false,
    HPR_CCI: false,
  };