import formatAmount from "indian-currency-formatter";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import { ageCalculate } from "../../components/global";

const HealthInsuranceAddonScreen = ({ api_response, setEditAddOnsFlag, checkedAddon, uncheckedAddon, form_data }) => {
  const data = api_response;

  console.log("checkedAddon, uncheckedAddon", checkedAddon, uncheckedAddon);

  const [mandatoryAddons, setMandatoryAddons] = useState(['C0001408', 'C0001499', 'C0001772']);

  useEffect(() => {
    if (form_data.self_age >= 45 && form_data.self_age <= 75) {
      setMandatoryAddons(['C0001408', 'C0001499']);
    }
  }, [form_data])


  const ContinueToBuy = () => {
    window.location = '/thank-you';
    sessionStorage.setItem('planData', JSON.stringify(api_response));
  }

  const [DuePremium, setDuePremium] = useState(parseFloat(data.DuePremium));
  const [base_amount, setBaseAmount] = useState();
  const [AddonDetailsList, setAddonDetailsList] = useState([]);
  // eslint-disable-next-line
  const [addonAmount, setAddonAmount] = useState(0);


  useEffect(() => {
    setDuePremium(parseFloat(data.DuePremium));
    setBaseAmount(parseFloat(data.DuePremium));

    if (data.PolicyLobList[0].RiderDetailsList) {
      const filteredData = data.PolicyLobList[0].RiderDetailsList.filter(item => item.ProductElementCode != null && item.ProductElementCode !== "");
      console.log(filteredData);
      const exludeUncheckedAddons = filteredData.filter(detail => !uncheckedAddon.includes(detail.ProductElementCode));
      const AddonSum = exludeUncheckedAddons.reduce((acc, current) => {
        if (current.ProductElementCode) {

          return acc + parseInt(current.GrossPremium ? (Math.round(current.GrossPremium * 1.18)) : (Math.round(current.CarrierBasePremium * 1.18)));
        } else {
          return acc;
        }
      }, 0);
      setAddonAmount(AddonSum);
    }


    if (data.PolicyLobList[0].AddonDetailsList) {
      const filteredData2 = data.PolicyLobList[0].AddonDetailsList.filter(item => item.ProductElementCode != null && item.ProductElementCode !== "");
      const exludeUncheckedAddons2 = filteredData2.filter(detail => !uncheckedAddon.includes(detail.ProductElementCode));
      const AddonSum2 = exludeUncheckedAddons2.reduce((acc, current) => {
        if (current.ProductElementCode) {
          return acc + parseInt(current.GrossPremium ? (Math.round(current.GrossPremium * 1.18)) : (Math.round(current.CarrierBasePremium * 1.18)));
        } else {
          return acc;
        }
      }, 0);
      setAddonAmount(addonAmount + AddonSum2);
    }


    // eslint-disable-next-line
  }, [data, uncheckedAddon])


  useEffect(() => {
    if (data.CarrierCode === 'CIGNA') {
      setAddonAmount(data.CarrierBasePremium * 1.18);
    }
  }, [data])


  // eslint-disable-next-line
  const handleBaseAmount = (e) => {
    const select_amount = parseFloat(e.target.value);
    const index_arr = e.target.name.split("_");
    const selected_addons_index = parseInt(index_arr[1]);


    if (e.target.checked) {
      setBaseAmount(base_amount + select_amount);
      setAddonDetailsList([...AddonDetailsList, selected_addons_index]);
    } else {
      setBaseAmount(base_amount - select_amount);
      const newArray = AddonDetailsList.filter(item => item !== selected_addons_index);
      setAddonDetailsList(newArray);
    }
  }

  console.log('1199999999', data.CarrierCode, "checkedAddon", checkedAddon);

  return (
    <Fragment>
      <div className={"card p20 mt20"}>
        <div className="row">
          <div className="col-md-12">

            <table style={{ 'width': '100%' }}>
              <tr>
                <td className="height40">
                  <span className="heading-3">Premium Amount</span>
                </td>
                <td className="text-right ">
                  <span className="btn-display-amount-2"> ₹  {data.CarrierCode === 'CIGNA' ? formatAmount(Math.round(data.CarrierBasePremium * 1.18)) : (DuePremium && formatAmount(Math.round(DuePremium) - Math.round(addonAmount)))}</span>
                </td>
              </tr>
            </table>
            <hr className="border-hr" />
            <table style={{ 'width': '100%' }}>

              { // in case CIGNA - ManipalCigna ProHealth Prime Active
                data.PolicyLobList &&
                (data.PolicyLobList[0].AddonDetailsList && data.ProductName === 'ManipalCigna ProHealth Prime Active') &&
                <>
                  {
                    (data.PolicyLobList[0].AddonDetailsList.some(item => item.hasOwnProperty(data.CarrierCode === "CIGNA" ? 'BenefitCode' : 'BenefitCode'))) &&
                    <>
                      <tr>
                        <td style={{ width: '65%' }}>
                          <span className="heading-3">Add ons</span>
                        </td>
                        <td className="text-right" style={{ width: '40%' }}>
                          <div className="title_viewmore">
                            <Link title="Edit My Inputs Details" to="#" onClick={() => { setEditAddOnsFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                          </div>
                        </td>
                      </tr>
                    </>
                  }
                </>
              }

              { // in case  !ManipalCigna ProHealth Prime Active
                data.PolicyLobList &&
                (data.PolicyLobList[0].RiderDetailsList && data.ProductName !== 'ManipalCigna ProHealth Prime Active') &&
                <>
                  {
                    (data.PolicyLobList[0].RiderDetailsList.some(item => item.hasOwnProperty(data.CarrierCode === "CIGNA" ? 'Rider' : 'BenefitCode')) || data.PolicyLobList[0].AddonDetailsList.some(item => item.hasOwnProperty(data.CarrierCode === "CIGNA" ? 'BenefitCode' : 'BenefitCode'))) &&
                    <>
                      <tr>
                        <td style={{ width: '65%' }}>
                          <span className="heading-3">Add ons</span>
                        </td>
                        <td className="text-right" style={{ width: '40%' }}>
                          <div className="title_viewmore">
                            {
                              (data.ProductName === 'ManipalCigna ProHealth Prime Advantage' || data.ProductName === 'ManipalCigna ProHealth Prime Protect') && (form_data.self_age >= 60 || (form_data.self_age >=60 && form_data.spouse_age >= 60)) ? '' :  <Link title="Edit My Inputs Details" to="#" onClick={() => { setEditAddOnsFlag(true) }}><span className="font12 fw600 color0d6 pointer"><div className="goaledit_icon ml4"></div>  </span> </Link>
                            }
                          </div>
                        </td>
                      </tr>
                    </>
                  }

                </>
              }

              {

                (data.CarrierCode !== "CIGNA" && data.PolicyLobList) &&
                data.PolicyLobList[0].RiderDetailsList &&
                data.PolicyLobList[0].RiderDetailsList.map((value, index) => {
                  return (<>
                    {
                      (value.BenefitCode && value.hasOwnProperty('CarrierBasePremium')) && <>
                        <tr>
                          <td className="height50">
                            <label className="text-type-2"><input type="checkbox" name={"index_" + index} defaultValue={value.CarrierBasePremium ? value.CarrierBasePremium : value.GrossPremium} checked={checkedAddon.includes(value.BenefitCode) ? true : false} readOnly={mandatoryAddons.includes(value.BenefitCode) ? true : false} disabled={mandatoryAddons.includes(value.BenefitCode) ? true : false} /> {(value.Description || value.BenefitDescription || value.CarrierAddonProductId)
                              ? (value.Description || value.BenefitDescription || value.CarrierAddonProductId).slice(0, 20) + '...'
                              : ''} {/* value.BenefitCode */}</label>
                            <div className="text-type-3 ml20 p"></div>
                          </td>
                          <td className="text-right">
                            <span className="btn-display-amount-2"> ₹ {value.CarrierBasePremium ? formatAmount(Math.round(value.CarrierBasePremium * 1.18)) : formatAmount(Math.round(value.GrossPremium * 1.18))}
                            </span>
                          </td>
                        </tr></>
                    }

                  </>
                  )

                })
              }


              {

                (data.CarrierCode === "CIGNA" && data.PolicyLobList) &&
                data.PolicyLobList[0].RiderDetailsList &&
                data.PolicyLobList[0].RiderDetailsList.map((value, index) => {

                  return (<>
                    {
                      (value.Rider && value.hasOwnProperty('CarrierBasePremium')) && <>

                        <tr>
                          <td className="height50">
                            <label className="text-type-2"><input type="checkbox" name={"index_" + index} defaultValue={value.CarrierBasePremium ? value.CarrierBasePremium : value.GrossPremium} checked={true} readOnly={false} disabled={false} /> {value.BenefitDescription} {/*  value.Rider */}</label>
                            <div className="text-type-3 ml20 p"></div>
                          </td>
                          <td className="text-right">

                            <span className="btn-display-amount-2"> ₹ {value.CarrierBasePremium ? formatAmount(Math.round(value.CarrierBasePremium * 1.18)) : formatAmount(Math.round(value.GrossPremium * 1.18))}
                            </span>
                          </td>
                        </tr></>
                    }
                  </>
                  )

                })
              }

              {

                (data.CarrierCode === "CIGNA" && data.PolicyLobList) &&
                data.PolicyLobList[0].AddonDetailsList &&
                data.PolicyLobList[0].AddonDetailsList.map((value, index) => {

                  return (<>
                    {
                      (value.Addon && value.hasOwnProperty('CarrierBasePremium')) && <>
                        <tr>
                          <td className="height50">
                            <label className="text-type-2"><input type="checkbox" name={"index_" + index} defaultValue={value.CarrierBasePremium ? value.CarrierBasePremium : value.GrossPremium} checked={true} readOnly={true} /*  disabled={mandatoryAddons.includes(value.Addon) ? true : false} */ /> {value.BenefitDescription} { /* value.BenefitCode  */} </label>
                            <div className="text-type-3 ml20 p"></div>
                          </td>
                          <td className="text-right">
                            <span className="btn-display-amount-2"> ₹ {
                              value.CarrierBasePremium === 0 ? '0' :
                                value.CarrierBasePremium ? formatAmount(Math.round(value.CarrierBasePremium * 1.18)) : formatAmount(Math.round(value.GrossPremium * 1.18))

                            }
                            </span>
                          </td>
                        </tr></>
                    }

                  </>
                  )

                })
              }

            </table>
          </div>

          <div className="col-md-12">
            {data.PolicyLobList && data.PolicyLobList[0].RiderDetailsList && (data.PolicyLobList[0].RiderDetailsList.some(item => item.hasOwnProperty('ProductElementCode')) && <hr className="border-hr" />)}

            <div className="total-to-pay-area" style={{ 'lineHeight': '1.5' }}>
              <span>Total Premium (incl. of taxes) </span> <span className="ml20 bold">₹ {base_amount && formatAmount(Math.round(base_amount))}</span>
            </div>

            <div className="text-center  mt5 mb20">

            </div>
            <input type="button" className="btn btn-primary continue-to-buy-btn " value="Continue to buy" onClick={() => { (data.AbacusId || data.DuePremium) && ContinueToBuy() }} />

          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default HealthInsuranceAddonScreen;