// eslint-disable-next-line
import { useEffect } from "react";
import Global from "../../Global";
import { inWords } from "../../components/global";
import formatAmount from 'indian-currency-formatter';
import { Link } from "react-router-dom";



const ViewQuotationlist = (props) => {
  const data = props.data;
  
  const calculateBasePremium = (data) => {

    const sumInsuredSum = data.PolicyLobList[0].RiderDetails.reduce((acc, current) => acc + current.Premium + current.TotalTax, 0);
    const basePremium = (parseInt(data.TotalPremium) - parseInt(sumInsuredSum));

    return formatAmount(basePremium);

  }
  // key is CarrierCode     
  const logo = (CarrierCode) => {
    switch (CarrierCode) {
      case 'HDFC':
        return 'images/logo/hdfc-life.png';
      case 'ICICI':
        return 'images/logo/iprotect-smart.png';
      default:
        return '#';
    }
  }

  const replace_error_message = (msg) => {
    let newMsg = "Plan Option Not Available";

    console.log('msg error message', msg);
    switch (msg) {
      case "Mandatory field :: ppt":
        newMsg = "Not Available";
        break;
      case "Could not obtain a connection to the destination or Exception was happened on the connection. Failed to process the request. Connection refused":
        newMsg = "Please try after sometime.";
        break;

      default:
        newMsg = "Plan Option Not Available";
        break;
    }

    console.log('newMsg : '+newMsg);

    return newMsg;
  }


  return (
    <>
      {data.map((v, i) =>
        v.Premium && ((props.frequency !== "3" && v.CarrierCode === "ICICI") || v.CarrierCode === "HDFC") ?
          <div className="mt20" key={v.id}>
            {/*
                  v.TagRelation === 'Recommended' && ( <div className="c-blue"><span className="font14 fw600 p5" style={{ color: '#fff', background: '#0D6CCA' }}><i className="fa fa-star" aria-hidden="true"></i> Recommended</span></div>  )
                } 
                {
                  v.TagRelation === 'Popular' && ( <div className="c-blue"><span className="font14 fw600 p5" style={{ color: '#fff', background: '#0D6CCA' }}><i className="fa fa-star" aria-hidden="true"></i> Popular</span></div>  )
                */}

            <div className="card card-border">
              <div className="col-md-12 row pb20">
                <div className="col-md-3 pt20 pr20">
                  <img className="mt20" src={Global.base_url + '/' + logo(v.CarrierCode)} alt="" />
                </div>
                <div className="col-md-6">
                  <h1 className="font20 fw700 pt10 pb10 " style={{ color: '#0D6CCA' }}>{v.CarrierProductName}</h1>
                  <div className="mt10">

                    {
                      v.PaidBenefits && v.PaidBenefits.map((v1, i1) => {
                        return (<li className="mt5" key={i1}>
                          <div className="categoryTab font12 fw500 color353"> {v1.BenefitDescription}</div>
                        </li>)
                      })
                    }
                    {
                      v.FreeBenefits && v.FreeBenefits.map((v2, i2) => {
                        return (<li className="mt5" key={i2}>
                          <div className="categoryTab font12 fw500 color353"> {v2.BenefitDescription}</div>
                        </li>)
                      })
                    }
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mt30 mb20">
                    <p>Premium</p>
                    <h1 className="font20 fw700"><i className="fa fa-inr" aria-hidden="true"></i> {calculateBasePremium(v)} /Yr </h1>
                    <p className="mt10">Cover: <strong><i className="fa fa-inr" aria-hidden="true"></i> {inWords(v.SumInsured)}</strong> </p>
                  </div>
                  <div className="mb10">
                    {/*   <Link to={'../'+ props.view_details_link + '/'+2} className="btn btn-primary font14 fw600">View Details</Link>  */}
                    <Link to={'../' + props.view_details_link + '/' + v.CarrierCode} className="btn btn-primary font14 fw600">View Details</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <>

            <div className="mt20" key={i}>
              <div className="card card-border">
                <div className="col-md-12 row pb20">
                  <div className="col-md-3 pt20 pr20">
                    <img className="mt20" src={Global.base_url + '/' + logo(v.CarrierCode)} alt="" />
                  </div>
                  <div className="col-md-9">
                    {/* <h1 className="font20 fw700 pt10 pb10 " style={{ color: '#0D6CCA' }}>{v.CarrierCode}</h1> */}
                    <div className="mt10">
                      {v.error_details && (
                        <span className="text-danger font12 text-center"> {replace_error_message(v.error_details.error_message)}</span>
                      )}
                      {v.Error && (
                        <span className="text-danger font12 text-center"> {replace_error_message(v.Error.ErrorMessage)}</span>
                      )}
                      {
                        !v.error_details && !v.Error && (<span className="text-danger font12 text-center"> {replace_error_message()}</span>)
                      }
                    </div>
                  </div>
                  {/*  <div className="col-md-3"> 
                          <div className="mt30 mb20">
                            <p>Premium</p>
                            <h1 className="font20 fw700"><i className="fa fa-inr" aria-hidden="true"></i> NA /Yr </h1>
                            <p className="mt10">Cover: <strong><i className="fa fa-inr" aria-hidden="true"></i> NA </strong> </p>
                          </div>
                          <div className="mb10">   
                            <Link to={'../'+ props.view_details_link + '/'+v.CarrierCode} className="btn btn-primary font14 fw600">View Details</Link> 
                          </div>                                   
                        </div>  */}
                </div>
              </div>
            </div>
          </>
      )
      }
    </>
  );
}

export default ViewQuotationlist;

/**
 * 
[
   {
      id:2,
      name: 'HDFC Life Insurance',     
      premium: 20000, 
      cover: 7000000,  
      logo: 'images/logo/hdfc-life.png',
      badge: 'Recommended',
      options:[
          "Terminal Illness",
          "Waiver of Premium on Disability",
          "Multiple payout options",
          "Add Ons Available"
        ]
    }
  ]
 */